import { createStore } from 'vuex';

import rootMutations from './mutations.js';
import rootActions from './actions.js';
import rootGetters from './getters.js';

import adminModule from './modules/admin';
import dashboardModule from './modules/dashboard';
import projectModule from './modules/project';
import ingredientModule from './modules/ingredient';
import rulesModule from './modules/rules';
import templatesModule from './modules/templates';
import accountModule from './modules/account';

export default createStore({
  modules: {
    admin: adminModule,
    dashboard: dashboardModule,
    project: projectModule,
    ingredient: ingredientModule,
    rules: rulesModule,
    templates: templatesModule,
    account: accountModule,
  },
  state: () => {
    return {
      alert: {
        show: false,
        type: 'info',
        header: 'Info',
        message: '',
        timeoutID: -1,
      },
      organization: null,
      user: null,

      showNavbar: true,
      windowWidth: window.innerWidth,
    };
  },
  mutations: rootMutations,
  getters: rootGetters,
  actions: rootActions,
});
