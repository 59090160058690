import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: () => {
    return {
      selectedRegion: 'ca',
      regionItems: null,
      mealReplacement: null,
      openModal: '',

      activeNutrient: null,
      activeMealReplacementItem: null,

      isLoadingRegion: false,
    };
  },
  mutations,
  getters,
  actions,
};
