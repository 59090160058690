<template>
  <BaseModal
    :show="openModal == 'addQtyRule'"
    @deny="isLoading ? '' : closeModal()"
  >
    <div class="ui vertical segment title">
      <h3>Add Quantity Rounding Rule</h3>
    </div>

    <div class="ui vertical segment">
      <div class="content">
        <div class="ui form">
          <div class="two fields">
            <div class="required field" :class="{ error: !validMin }">
              <label>Min (inclusive)</label>
              <input
                v-model="min"
                type="text"
                autocomplete="off"
                placeholder="0"
                @keydown="validMin = true"
              />
            </div>
            <div class="required field" :class="{ error: !validMax }">
              <label>Max (exclusive)</label>
              <input
                v-model="max"
                type="text"
                autocomplete="off"
                placeholder="25"
                @keyup.enter="addRule"
                @keydown="validMax = true"
              />
            </div>
          </div>

          <div class="two fields">
            <div class="field">
              <div class="ui form">
                <div
                  class="grouped required fields"
                  :class="{ error: !validOperation }"
                >
                  <label>Opration</label>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input
                        v-model="operation"
                        type="radio"
                        value="roundNearest"
                        checked
                        @keyup.enter="addRule"
                      />
                      <label>Round To Nearest</label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input v-model="operation" type="radio" value="roundTo" />
                      <label>Round To</label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input
                        v-model="operation"
                        type="radio"
                        value="showText"
                        @keyup.enter="addRule"
                      />
                      <label>Show Text</label>
                    </div>
                  </div>
                </div>
              </div>
              <small
                >If show text rule is applied then DV will round to 0%</small
              >
            </div>

            <div class="required field" :class="{ error: !validOperationData }">
              <label>{{
                operation == 'showText'
                  ? 'Text to show'
                  : operation == 'roundTo'
                  ? 'Round to'
                  : 'Roundin to nearest'
              }}</label>
              <input
                v-model.trim="operationData"
                type="text"
                autocomplete="off"
                @keyup.enter="addRule"
                @keydown="validOperationData = true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui vertical segment actions">
      <div class="ui cancel button" @click="closeModal">Cancel</div>
      <div
        class="ui green button add-rule"
        :class="{ 'loading disabled': isLoading }"
        @click="addRule"
      >
        OK
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      min: '',
      max: '',
      operation: 'roundNearest',
      operationData: '',

      validMin: true,
      validMax: true,
      validOperation: true,
      validOperationData: true,

      isLoading: false,
    };
  },
  computed: {
    ...mapState('rules', ['activeNutrient', 'openModal']),
  },
  methods: {
    async addRule() {
      // Check min
      this.validMin = this.isValidNumber(this.min);

      // Check max
      this.validMax =
        this.isValidNumber(this.max) &&
        (!this.validMin || parseFloat(this.max) > parseFloat(this.min));

      // Check operation
      this.validOperation = this.operation.length > 0;

      // Check valid operation amount
      if (this.operation == 'roundNearest' || this.operation == 'roundTo')
        this.validOperationData = this.isValidNumber(this.operationData);
      else
        this.validOperationData =
          this.operationData && this.operationData.length > 0;

      if (
        this.validMin &&
        this.validMax &&
        this.validOperation &&
        this.validOperationData
      ) {
        this.isLoading = true;
        await this.$store.dispatch('rules/addRule', {
          min: this.min,
          max: this.max,
          operation: this.operation,
          operationData: this.operationData,
          type: 'qty',
        });
        this.closeModal();
        this.isLoading = false;
      }
    },
    isValidNumber(num) {
      return (
        num &&
        !isNaN(parseFloat(num)) &&
        parseFloat(num) >= 0 &&
        (num.split('.').length == 1 || num.split('.')[1].length <= 4)
      );
    },
    closeModal() {
      this.min = '';
      this.max = '';
      this.operation = 'roundNearest';
      this.operationData = '';
      this.$store.commit('rules/closeModal');
    },
  },
};
</script>

<style>
.actions {
  text-align: right;
}
</style>
