<template>
  <div>
    <TopHeader header-title="Templates" show-user-info />
    <CenterContainer>
      <h2>Guide</h2>
      <div class="ui stackable cards">
        <GuideCard
          header="Understanding Templates"
          description="Variables, styling variables, conditional variables and how they work."
          @open="openModal = 'guide1'"
        />
        <GuideCard
          header="Making Changes to Templates"
          description="Typical mistakes and what to avoid."
          @open="openModal = 'guide2'"
        />
        <GuideCard
          header="All Variables"
          description="All available variables that can be used."
          @open="openModal = 'guide3'"
        />
      </div>

      <hr class="ui vertical segment" />

      <h2>Canada</h2>
      <div class="ui stackable raised cards">
        <TemplateCard
          icon="file word"
          header="Final Spec"
          subheader=".DOCX"
          country="ca"
          name="final"
        />
        <TemplateCard
          icon="file word"
          header="Preliminary Spec"
          subheader=".DOCX"
          country="ca"
          name="preliminary"
        />
      </div>

      <h2>Europe</h2>
      <div class="ui stackable raised cards">
        <TemplateCard
          icon="file word"
          header="Standard Spec"
          subheader=".DOCX"
          country="eu"
          name="standard"
        />
      </div>

      <h2>USA</h2>
      <div class="ui stackable raised cards">
        <TemplateCard
          icon="file word"
          header="Final Spec"
          subheader=".DOCX"
          country="us"
          name="final"
        />
        <TemplateCard
          icon="file word"
          header="Preliminary Spec"
          subheader=".DOCX"
          country="us"
          name="preliminary"
        />
      </div>

      <BaseModal :show="openModal == 'guide1'" @deny="openModal = null">
        <Guide1 />
      </BaseModal>
      <BaseModal :show="openModal == 'guide2'" @deny="openModal = null">
        <Guide2 />
      </BaseModal>
      <BaseModal :show="openModal == 'guide3'" @deny="openModal = null">
        <Guide3 />
      </BaseModal>
    </CenterContainer>
  </div>
</template>

<script>
import TemplateCard from '@/components/templates/TemplateCard';
import GuideCard from '@/components/templates/GuideCard';
import Guide1 from '@/components/templates/Guide1';
import Guide2 from '@/components/templates/Guide2';
import Guide3 from '@/components/templates/Guide3';

export default {
  components: {
    TemplateCard,
    GuideCard,
    Guide1,
    Guide2,
    Guide3,
  },
  data() {
    return {
      openModal: '',
    };
  },
};
</script>
