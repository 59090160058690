<template>
  <div class="ui segment">
    <button
      class="ui secondary button mb-2"
      @click="openIngredientModal({ editMode: false })"
    >
      Add ingredient
    </button>
    <div class="ui stackable grid">
      <div class="eight wide column">
        <h4 class="ui dividing header">Search by code</h4>
        <div class="ui action fluid input">
          <input
            v-model.trim="code"
            type="text"
            placeholder="Enter code..."
            autocomplete="off"
            @keyup.enter="code.length >= 3 ? search({ code }) : ''"
          />
          <button
            class="ui button"
            :class="{ disabled: code.length < 3 }"
            @click="search({ code })"
          >
            Search
          </button>
        </div>
      </div>
      <div class="eight wide column">
        <h4 class="ui dividing header">Search by component name</h4>
        <div class="ui action fluid input">
          <input
            v-model.trim="component"
            type="text"
            placeholder="Ex: natural flavors"
            autocomplete="off"
            @keyup.enter="
              component.length > 0 ? search({ componentName: component }) : ''
            "
          />
          <button
            class="ui button"
            :class="{ disabled: component.length == 0 }"
            @click="search({ componentName: component })"
          >
            Search
          </button>
        </div>
        <small><i>Exact search</i></small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
  data() {
    return {
      code: '',
      component: '',
    };
  },
  methods: {
    ...mapMutations('ingredient', ['openIngredientModal']),
    ...mapActions('ingredient', ['search']),
  },
};
</script>
