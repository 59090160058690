<template>
  <h4>Specific status</h4>
  <div class="grouped fields">
    <div
      v-for="status in regionSettings.specificStatus"
      :key="status"
      class="field"
    >
      <div class="ui checkbox">
        <input
          type="checkbox"
          :disabled="isViewOnly"
          :checked="specificStatus.includes(status)"
          @change="toggleSpecificStatus({ status })"
        />
        <label>{{ status }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  computed: {
    ...mapGetters('project', ['isViewOnly']),
    ...mapState('project', ['regionSettings', 'specificStatus']),
  },
  methods: {
    ...mapMutations('project', ['toggleSpecificStatus']),
  },
};
</script>
