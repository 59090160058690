<template>
  <transition name="fade" mode="out-in">
    <ProjectBuilder v-if="projectReady" />
    <CenterContainer v-else>
      <SpinLoader :header="'Opening Project'" />
    </CenterContainer>
  </transition>
</template>

<script>
import ProjectBuilder from '@/components/project/ProjectBuilder';
import { mapGetters } from 'vuex';
export default {
  components: {
    ProjectBuilder,
  },
  computed: {
    ...mapGetters('project', ['projectReady']),
  },
  created() {
    if (
      !this.projectReady ||
      this.$route.params.id != this.$store.state.project.id
    ) {
      const { id } = this.$route.params;
      this.$store.dispatch('project/openProject', {
        id,
      });
    }
  },
};
</script>
