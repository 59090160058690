import router from '../../../router';
import request from '../../../request';
import { port } from '../../../../../config';

/**
 * ID generator function
 * @yields {number}
 */
function* idMaker() {
  let i = 1000;
  for (;;) yield ++i;
}

/**
 * Component id (cid) generator
 */
const cidGenerator = idMaker();

/**
 * Get all ingredients from foodlist
 * @returns {[Promise]} Array of ingredients
 */
async function getAllIngredients(context) {
  return await Promise.allSettled(
    context.state.foodlist.map(async item => {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: `/api/ingredients/${item.code}`,
      });
      response.data.code = item.code;
      if (response.data.result) response.data.result.qty = item.qty;
      return response.data;
    }),
  );
}

/**
 * Get list of allergens present in foodlist
 * @param {object} context
 * @param {Array} [ingredients=null] List of all the ingredients to check
 * @returns {Array} List of allergens
 */
async function getPresentAllergens(context, ingredients = null) {
  // If no ingredients -> get ingredients
  if (!ingredients)
    ingredients = (await getAllIngredients(context)).map(e => e.value.result);

  const presentAllergens = []; // [{ name: String, source: [String] }]

  // Create list
  ingredients.forEach(ingredient => {
    if (ingredient)
      ingredient.allergens.forEach(allergen => {
        const found = presentAllergens.find(e => e.name == allergen);
        if (!found)
          presentAllergens.push({ name: allergen, source: [ingredient.code] });
        else found.source.push(ingredient.code);
      });
  });

  // Add contains allergens
  if (context.state.country == 'ca' || context.state.country == 'us') {
    context.state.allergensContains = [];
    context.state.regionSettings.allergensContains.forEach(item => {
      const present = presentAllergens.find(e => e.name == item.split('/')[0]);
      if (present) context.state.allergensContains.push(item);
    });
  }

  return presentAllergens;
}

export default {
  /**
   * - Parse file
   * - Get region settings
   * - Mark required nutrients
   */
  createProject: async (context, payload) => {
    // Close previous project
    context.commit('closeProject', { skipRedirect: true });

    const formData = new FormData();
    formData.append('recipeFile', payload.recipeFile);
    formData.append('multicolumnFile', payload.multicolumnFile);
    formData.append('country', payload.country);

    try {
      const response = await request.sendRequest(context, {
        method: 'POST',
        url: '/api/projects/new',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Move to state
      context.state.name = response.data.name;
      context.state.country = payload.country;
      context.state.files = response.data.files;
      context.state.recipeInfo = response.data.recipeInfo;
      context.state.foodlist = response.data.foodlist;
      context.state.vitaminBlend = response.data.vitaminBlend;
      context.state.nutrients = response.data.nutrients;
      context.state.servingSize = response.data.recipeInfo.gramWeight;
      context.state.author = {
        id: context.rootGetters['activeUser'].id,
        username: context.rootGetters['activeUser'].username,
        fullName: context.rootGetters['activeUser'].fullName,
      };

      // Set region settings / required nutrients
      await context.dispatch('setRegionSettings', payload);

      context.state.nutrients.forEach(nutrient => {
        // Mark country nutrients
        if (
          context.state.regionSettings.nutrients.some(
            nut => nut.name == nutrient.name,
          )
        ) {
          nutrient.required = context.state.regionSettings.nutrients.find(
            nut => nut.name == nutrient.name,
          ).required;
          nutrient.show = nutrient.required;
          nutrient.use = true;
        } else nutrient.use = false;
      });

      // Sort by name
      context.state.nutrients.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      // Set meal replacement sources
      if (context.state.country == 'ca')
        context.state.regionSettings.mealReplacement.forEach((e, i) => {
          context.state.mealReplacementSources.push({
            name: e.name,
            source:
              context.state.vitaminBlend && i >= 6 ? 'vitaminBlend' : 'recipe',
          });
        });

      // Get components
      context.dispatch('lookupComponents');
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Open Project
   */
  openProject: async (context, payload) => {
    // Close previous project
    context.commit('closeProject', { skipRedirect: true });

    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: `/api/projects/open/${payload.id}`,
      });

      // If project doesn't exist
      if (!response.data) {
        await router.replace({ name: 'New Project' });
        return context.commit(
          'showAlert',
          {
            type: 'warning',
            header: 'Project Not Found',
            message: "Project doesn't exist or was deleted",
          },
          { root: true },
        );
      }

      // Copy to state
      for (const key in response.data)
        if (Object.hasOwnProperty.call(response.data, key))
          context.state[key] = response.data[key];

      // Save project ID
      context.state.id = payload.id;

      // Set page title
      document.title = 'Nutrispec | ' + context.state.name;

      // Set region settings / required nutrients
      await context.dispatch('setRegionSettings', {
        country: response.data.country,
      });

      context.state.nutrients.forEach(nutrient => {
        // Mark country nutrients
        if (
          context.state.regionSettings.nutrients.some(
            nut => nut.name == nutrient.name,
          )
        ) {
          nutrient.required = context.state.regionSettings.nutrients.find(
            nut => nut.name == nutrient.name,
          ).required;
          if (nutrient.required) nutrient.show = true;
          nutrient.use = true;
        }
      });

      // Set meal replacement sources if don't exist
      if (
        context.state.country == 'ca' &&
        context.state.mealReplacementSources.length == 0
      ) {
        context.state.regionSettings.mealReplacement.forEach((e, i) => {
          context.state.mealReplacementSources.push({
            name: e.name,
            source:
              context.state.vitaminBlend && i >= 6 ? 'vitaminBlend' : 'recipe',
          });
        });
      }

      // Check present allergens
      context.state.presentAllergens = await getPresentAllergens(context);
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Set region settings
   */
  setRegionSettings: async (context, payload) => {
    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: `/api/rules/${payload.country}`,
      });
      const {
        templates,
        nutrients,
        allergensContains,
        allergensMayContain,
        foodCategories,
        specificStatus,
        claims,
        mealReplacement,
      } = response.data;
      context.state.regionSettings = {
        templates,
        nutrients,
        allergensContains,
        allergensMayContain,
        foodCategories,
        specificStatus,
        claims,
        mealReplacement,
      };
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Lookup Components
   */
  lookupComponents: async context => {
    // Check if foodlist empty
    if (!context.state.foodlist) {
      context.commit(
        'showAlert',
        {
          type: 'error',
          header: 'No ingredients found',
          message:
            'Could not load components since nothing was found in the foodlist',
        },
        { root: true },
      );
      context.commit('closeProject');
    }

    context.state.components = [];

    let ungroupedComponents = [],
      groupedComponents = [];

    const ingredients = await getAllIngredients(context);
    ingredients.forEach(resolve => {
      // If ingredient not found
      if (!resolve.value.result)
        return ungroupedComponents.push({
          code: resolve.value.code,
          name: null,
          qty: null,
          cid: cidGenerator.next().value,
        });

      const ingredient = resolve.value.result;
      ingredient.components.forEach(component => {
        ungroupedComponents.push({
          code: ingredient.code,
          name: component[context.getters['componentLanguages'][0]],
          nameFr: context.getters['componentLanguages'][1]
            ? component[context.getters['componentLanguages'][1]]
            : null,
          qty:
            Math.round(
              parseFloat(component.qty) * parseFloat(ingredient.qty) * 10000000,
            ) / 10000000,
          cid: cidGenerator.next().value,
        });
      });
    });

    // Group components
    ungroupedComponents.forEach(component => {
      const index = groupedComponents.findIndex(
        e => e.name == component.name && e.nameFr == e.nameFr,
      );

      if (index < 0)
        groupedComponents.push({
          code: [component.code],
          name: component.name,
          nameFr: component.nameFr,
          qty: component.qty,
          cid: component.cid,
        });
      else {
        groupedComponents[index].qty += component.qty;
        groupedComponents[index].qty =
          Math.round(groupedComponents[index].qty * 10000000) / 10000000;
        groupedComponents[index].code.push(component.code);
      }
    });

    context.state.components = groupedComponents;
    context.state.presentAllergens = await getPresentAllergens(
      context,
      ingredients.map(e => e.value.result),
    );

    // If missing components alert user
    if (context.getters['hasMissingComponents'])
      context.state.openModal = 'componentMissingModal';
  },

  /**
   * Save Project
   */
  saveProject: async context => {
    try {
      const response = await request.sendRequest(context, {
        method: 'POST',
        url: '/api/projects/save',
        data: context.state,
      });
      if (response.status == 201)
        context.commit(
          'showAlert',
          {
            type: 'success',
            header: 'Saved as new project',
            timeout: 4000,
          },
          { root: true },
        );
      else if (response.status == 200)
        context.commit(
          'showAlert',
          {
            type: 'success',
            header: 'Saved as existing project',
            timeout: 2000,
          },
          { root: true },
        );
      else alert('Unknown save state - Please retry');
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Export Project
   */
  exportProject: async (context, payload) => {
    /**
     * Check if nutrient is shown on NFP
     * @param {string} nutrient Nutrient name
     * @returns {boolean}
     */
    const has = nutrient => {
      return (
        context.getters.getNutrient(nutrient) &&
        context.getters.getNutrient(nutrient).show
      );
    };

    /**
     * Get nutrient qty
     * @param {string} nutrient Nutrient name
     * @returns {number}
     */
    const qty = nutrient => {
      if (
        context.getters.getNutrient(nutrient) &&
        context.getters.getNutrient(nutrient).roundedQty !== undefined &&
        context.getters.getNutrient(nutrient).roundedQty !== null
      )
        return context.getters.getNutrient(nutrient).roundedQty;
      else return '';
    };

    /**
     * Get nutrient qty for 100g
     * @param {string} nutrient Nutrient name
     * @returns {number}
     */
    const qty100g = nutrient => {
      if (
        context.getters.getNutrient(nutrient, 100) &&
        context.getters.getNutrient(nutrient, 100).roundedQty !== undefined &&
        context.getters.getNutrient(nutrient, 100).roundedQty !== null
      )
        return context.getters.getNutrient(nutrient, 100).roundedQty;
      else return '';
    };

    /**
     * Get nutrient dv
     * @param {string} nutrient Nutrient name
     * @returns {number}
     */
    const dv = nutrient => {
      if (
        context.getters.getNutrient(nutrient) &&
        context.getters.getNutrient(nutrient).roundedPercentDv !== undefined &&
        context.getters.getNutrient(nutrient).roundedPercentDv !== null
      )
        return context.getters.getNutrient(nutrient).roundedPercentDv;
      else return '';
    };

    /**
     * Include Nutrient in Payload
     * @param {string} nutName Nutrient Genesis Name
     * @param {string} varName Document Variable Name
     */
    const includeNutrient = (nutName, varName) => {
      content[
        'has' + varName.charAt(0).toUpperCase() + varName.substring(1)
      ] = has(nutName);
      content[varName] = qty(nutName);
      content[varName + '100g'] = qty100g(nutName);
      content[varName + 'Dv'] = dv(nutName);
    };

    const content = {
      // Basics
      name: context.state.recipeInfo.originalName,
      authorName: context.state.author.fullName,
      date:
        new Date().getDate() +
        '/' +
        (new Date().getMonth() + 1) +
        '/' +
        new Date().getFullYear(),

      // Details
      servSize: context.state.servingSizeInfo
        ? `${context.state.servingSizeInfo} (${context.state.servingSize}g)`
        : context.state.servingSize + 'g',
      hasServStmt: !!context.state.servingIdentifier,
      numServs: context.state.numServings,
      servId: context.state.servingIdentifier,
      servIdFr: context.state.servingIdentifierFr,
      hasServsPerCont: !!context.state.servingsPerContainer,
      servsPerCont: context.state.servingsPerContainer,
      statementOfIdentityHeader: context.state.soiHeader,
      statementOfIdentityHeaderFr: context.state.soiHeaderFr,
      statementOfIdentityContent: context.state.soiContent,
      statementOfIdentityContentFr: context.state.soiContentFr,
      instructions: context.state.instructions,
      instructionsFr: context.state.instructionsFr,
      shelfLife: context.state.shelfLife,
      packaging: context.state.packaging,
      hsCode: context.state.hsCode,
      scoreEurope: context.state.scoreEurope,
      sampleCode: context.state.sampleCode,
      productionOrigin: context.state.productionOrigin,
      productionOriginFr: context.state.productionOriginFr,
      foodCategory: context.state.foodCategory.en,
      foodCategoryFr: context.state.foodCategory.fr,
      foodCategoryNumber: context.state.foodCategory.categoryNumber
        ? context.state.foodCategory.categoryNumber + ' : '
        : '',
      additionalAllergenStatement: context.state.additionalAllergenStatement,
      additionalAllergenStatementFr:
        context.state.additionalAllergenStatementFr,

      // Weight
      totalWeightGrams:
        Math.round(
          context.state.servingSize * context.state.numServings * 100,
        ) / 100,
      totalWeightGramsCa:
        Math.round(
          context.state.servingSize * context.state.servingsPerContainer * 100,
        ) / 100,
      totalWeightOunces:
        Math.round(
          context.state.servingSize *
            context.state.numServings *
            0.035274 *
            100,
        ) / 100,
    };

    // Include Nutrients
    includeNutrient('Calories', 'cals');
    includeNutrient('Fat', 'fat');
    includeNutrient('Saturated Fat', 'satFat');
    includeNutrient('Trans Fatty Acid', 'transFat');
    includeNutrient('Omega 3 Fatty Acid', 'omeg3');
    includeNutrient('Omega 6 Fatty Acid', 'omeg6');
    includeNutrient('Cholesterol', 'chol');
    includeNutrient('Sodium', 'sodium');
    includeNutrient('Fluoride', 'floride');
    includeNutrient('Carbohydrates', 'carbs');
    includeNutrient('Dietary Fiber (2016)', 'fiber');
    includeNutrient('Total Dietary Fiber', 'fiberCa');
    includeNutrient('Total Sugars', 'sugars');
    includeNutrient('Starch', 'starch');
    includeNutrient('Added Sugar', 'addSug');
    includeNutrient('Sugar Alcohol', 'sugAlc');
    includeNutrient('Sugar alcohol (CA)', 'sugAlcCa');
    includeNutrient('Protein', 'prot');
    includeNutrient('Vitamin D - mcg', 'vitD');
    includeNutrient('Calcium', 'calc');
    includeNutrient('Iron', 'iron');
    includeNutrient('Potassium', 'pot');
    includeNutrient('Vitamin A - RAE', 'vitA');
    includeNutrient('Vitamin C', 'vitC');
    includeNutrient('Vitamin E - mg', 'vitE');
    includeNutrient('Vitamin K', 'vitK');
    includeNutrient('Vitamin B1 - Thiamin', 'vitB1');
    includeNutrient('Vitamin B2 - Riboflavin', 'vitB2');
    includeNutrient('Vitamin B3 - Niacin Equiv', 'vitB3');
    includeNutrient('Vitamin B6', 'vitB6');
    includeNutrient('Folate, DFE', 'folate');
    includeNutrient('Vitamin B12', 'vitB12');
    includeNutrient('Biotin', 'biotin');
    includeNutrient('Pantothenic Acid', 'pAcid');
    includeNutrient('Phosphorus', 'phos');
    includeNutrient('Iodine', 'iodine');
    includeNutrient('Magnesium', 'magnes');
    includeNutrient('Zinc', 'zinc');
    includeNutrient('Selenium', 'selen');
    includeNutrient('Copper', 'copper');
    includeNutrient('Manganese', 'mangan');
    includeNutrient('Chromium', 'chrom');
    includeNutrient('Molybdenum', 'molyb');
    content['kJ100g'] = context.getters.getNutrient(
      'Calories',
      100,
      4.18,
    ).roundedQty;
    content['kJ'] = context.getters.getNutrient(
      'Calories',
      undefined,
      4.18,
    ).roundedQty;

    // Country specific
    switch (context.state.country) {
      case 'ca':
        content['ingredientStatement'] =
          context.state.ingredientStatement
            .map(e => e.name.charAt(0).toUpperCase() + e.name.substring(1))
            .join(', ') + '.';

        content['ingredientStatementFr'] =
          context.state.ingredientStatement
            .map(e => e.nameFr.charAt(0).toUpperCase() + e.nameFr.substring(1))
            .join(', ') + '.';

        // Allergens
        content[
          'allergenStatementContains'
        ] = context.state.allergensContains
          .map(e => e.split('/')[0])
          .join(', ');
        content[
          'allergenStatementMayContain'
        ] = context.state.allergensMayContain
          .map(e => e.split('/')[0])
          .join(', ');
        content[
          'allergenStatementContainsFr'
        ] = context.state.allergensContains
          .map(e => e.split('/')[1])
          .join(', ');
        content[
          'allergenStatementMayContainFr'
        ] = context.state.allergensMayContain
          .map(e => e.split('/')[1])
          .join(', ');

        // Sat + Trans Fat DV
        if (
          typeof context.getters.getNutrient('Saturated Fat').percentDv ==
            'number' &&
          typeof context.getters.getNutrient('Trans Fatty Acid').percentDv ==
            'number'
        )
          content['satAndTransFatDv'] =
            context.getters.getNutrient('Saturated Fat').percentDv +
            context.getters.getNutrient('Trans Fatty Acid').percentDv +
            '%';
        else content['satAndTransFatDv'] = '';
        break;

      case 'eu': {
        // Allergens
        content[
          'allergenStatementContains'
        ] = context.state.allergensContains
          .map(e => e.split('/')[0])
          .join(', ');
        content[
          'allergenStatementMayContain'
        ] = context.state.allergensMayContain
          .map(e => e.split('/')[0])
          .join(', ');
        content[
          'allergenStatementContainsFr'
        ] = context.state.allergensContains
          .map(e => e.split('/')[1])
          .join(', ');
        content[
          'allergenStatementMayContainFr'
        ] = context.state.allergensMayContain
          .map(e => e.split('/')[1])
          .join(', ');

        content['ingredientStatement'] =
          context.state.ingredientStatement.map(e => e.name).join(', ') + '.';
        content['ingredientStatementFr'] =
          context.state.ingredientStatement.map(e => e.nameFr).join(', ') + '.';

        // Claims
        content['claims'] =
          context.state.claims.map(e => e.en.toUpperCase()).join(', ') + '.';
        content['claimsFr'] =
          context.state.claims.map(e => e.fr.toUpperCase()).join(', ') + '.';
        content['additionalClaims'] = context.state.additionalClaims;

        // Microbiological Criteria
        for (const key in context.state.microbiologicalCriteria)
          if (
            Object.hasOwnProperty.call(
              context.state.microbiologicalCriteria,
              key,
            )
          )
            content[key] = context.state.microbiologicalCriteria[key];

        // Aminogram
        for (const key in context.state.aminogram)
          if (Object.hasOwnProperty.call(context.state.aminogram, key))
            content[key] = context.state.aminogram[key];

        // Specific Status
        const { specificStatus } = context.state;
        content['kosher'] = specificStatus.includes('Kosher on request')
          ? 'YES'
          : 'NO';
        content['organic'] = specificStatus.includes('Organic') ? 'YES' : 'NO';
        content['glutenFree'] = specificStatus.includes('Gluten free')
          ? 'YES'
          : 'NO';
        content['lactoseFree'] = specificStatus.includes('Lactose free')
          ? 'YES'
          : 'NO';
        content['vegetarian'] = specificStatus.includes('Vegetarian')
          ? 'YES'
          : 'NO';
        content['vegan'] = specificStatus.includes('Vegan') ? 'YES' : 'NO';

        // Nutrients
        content['sodiumToG'] = context.getters.getNutrient('Sodium')
          ? context.getters.getNutrient('Sodium', undefined, 0.001).roundedQty
          : '-';
        content['sodium100gToG'] = context.getters.getNutrient('Sodium')
          ? context.getters.getNutrient('Sodium', 100, 0.001).roundedQty
          : '-';

        content['packagedInProtectiveAtmosphere'] =
          context.state.packagedInProtectiveAtmosphere;

        break;
      }

      case 'us':
        content['ingredientStatement'] = context.state.ingredientStatement
          .map(e => e.name)
          .join(', ');
        content['ingredientStatement'] =
          content['ingredientStatement'].charAt(0).toUpperCase() +
          content['ingredientStatement'].substring(1) +
          '.';

        content[
          'allergenStatementContains'
        ] = context.state.allergensContains.join(', ');
        content[
          'allergenStatementMayContain'
        ] = context.state.allergensMayContain.join(', ');
        break;
    }

    try {
      const response = await request.sendRequest(context, {
        method: 'POST',
        url: `/api/template/generate/${context.state.country}/${payload.template}`,
        data: { content },
      });

      if (process.env.NODE_ENV === 'development')
        window.location = `http://localhost:${port}/api/template/generated/${response.data.filename}`;
      else
        window.location.pathname =
          '/api/template/generated/' + response.data.filename;
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Translate request
   */
  translate: async (context, payload) => {
    try {
      const response = await request.sendRequest(context, {
        method: 'POST',
        url: '/api/projects/translate',
        data: payload,
      });
      return response.data.translation;
    } catch (err) {
      console.error(err);
    }
  },
};
