<template>
  <table class="ui celled striped definition table">
    <thead>
      <tr>
        <th></th>
        <th>Min</th>
        <th>Max</th>
        <th>Calculation (Recipe)</th>
        <th>Calculation (Vitamin Blend)</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in mealReplacement" :key="item.name">
        <td>{{ item.name }}</td>
        <td>{{ item.min ? `${item.min} ${item.unit}` : '-' }}</td>
        <td>{{ item.max ? `${item.max} ${item.unit}` : '-' }}</td>
        <td>
          <a
            class="ui label"
            @click="
              openModal({
                name: 'editMealReplacementCalculation',
                activeMealReplacementItem: { item, type: 'recipe' },
              })
            "
          >
            = {{ item.calculation.recipe.join(' ') }}
          </a>
        </td>
        <td>
          <a v-if="item.calculation.vitaminBlend.length > 0"
            class="ui label"
            @click="
              openModal({
                name: 'editMealReplacementCalculation',
                activeMealReplacementItem: { item, type: 'vitaminBlend' },
              })
            "
          >
            = {{ item.calculation.vitaminBlend.join(' ') }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <EditMealReplacementCalculation />
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import EditMealReplacementCalculation from '@/components/rules/EditMealReplacementCalculation';
export default {
  components: {
    EditMealReplacementCalculation,
  },
  computed: {
    ...mapState('rules', ['mealReplacement']),
  },
  methods: {
    ...mapMutations('rules', ['openModal']),
  },
};
</script>

<style scoped>
table {
  margin-bottom: 1rem !important;
}
</style>
