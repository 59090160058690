import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: () => {
    return {
      allUsers: [],
      activeSessions: [],
      sortingCol: {
        col: null,
        ascending: true,
      },
      userToModify: null,
      openModal: null,
      inviteLink: null,
    };
  },
  mutations,
  getters,
  actions,
};
