export default {
  /* Open Modals */
  openEditUser: (state, payload) => {
    state.userToModify = payload.user;
    state.openModal = 'editUser';
  },
  openDeleteUser: (state, payload) => {
    state.userToModify = payload.user;
    state.openModal = 'deleteUser';
  },
  openResetPassword: (state, payload) => {
    state.userToModify = payload.user;
    state.openModal = 'resetPassword';
  },
  openNewModal: (state, payload) => {
    state.openModal = payload.name;
  },

  /**
   * Close Modal
   */
  closeModal: state => {
    state.openModal = null;
    state.userToModify = null;
    state.inviteLink = null;
  },

  /**
   * Sort users list
   * @param {object} state
   * @param {string} col Column to sort
   */
  sortUsers: (state, col) => {
    if (state.sortingCol.col == col) {
      state.sortingCol.ascending = !state.sortingCol.ascending;
    } else {
      state.sortingCol.col = col;
      state.sortingCol.ascending = true;
    }
    state.allUsers.sort((e1, e2) => {
      if (e1[col] < e2[col]) return state.sortingCol.ascending ? -1 : 1;
      if (e1[col] > e2[col]) return state.sortingCol.ascending ? 1 : -1;
      return 0;
    });
  },

  /**
   * Reset state to defaults
   */
  reset(state) {
    state.allUsers = [];
    state.activeSessions = [];
    state.sortingCol = {
      col: null,
      ascending: true,
    };
    state.userToModify = null;
    state.openModal = null;
    state.inviteLink = null;
  },
};
