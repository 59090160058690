<template>
  <BaseModal
    :show="openModal == 'componentMissingModal'"
    @deny="closeProjectModal"
  >
    <div class="ui vertical segment">
      <h3 class="header">Missing Components</h3>
    </div>
    <div class="ui vertical segment">
      <h5>Nutrispec recommends adding these components before proceeding</h5>
      <div class="ui relaxed divided list">
        <div v-for="code in missingComponentCodes" :key="code" class="item">
          <svg
            transform="translate(0,1)"
            height="13"
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M437.2 403.5L320 215V64h8c13.3 0 24-10.7 24-24V24c0-13.3-10.7-24-24-24H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h8v151L10.8 403.5C-18.5 450.6 15.3 512 70.9 512h306.2c55.7 0 89.4-61.5 60.1-108.5zM137.9 320l48.2-77.6c3.7-5.2 5.8-11.6 5.8-18.4V64h64v160c0 6.9 2.2 13.2 5.8 18.4l48.2 77.6h-172z"
            ></path>
          </svg>
          {{ code }}
        </div>
      </div>
    </div>
    <div class="ui vertical segment actions">
      <div class="ui ok button" @click="closeProjectModal">Ok</div>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  computed: {
    ...mapState('project', ['openModal']),
    ...mapGetters('project', ['missingComponentCodes']),
  },
  methods: {
    ...mapMutations('project', ['closeProjectModal']),
  },
};
</script>
