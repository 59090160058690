<template>
  <h4>Microbiological Criteria</h4>
  <table class="ui small compact table">
    <thead>
      <tr>
        <th>Parameters</th>
        <th>Goal</th>
        <th>Tolerance</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <h5 class="ui header">
            <div class="content">
              Enterobactéries (ufc/g)
              <div class="sub header">AES 10/07-01/08</div>
            </div>
          </h5>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.ebG"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'ebG',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.ebT"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'ebT',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="ui header">
            <div class="content">
              Flore aérobie mésophile (ufc/g)
              <div class="sub header">NF EN ISO 4833-1</div>
            </div>
          </h5>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.famG"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'famG',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.famT"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'famT',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="ui header">
            <div class="content">
              Levures (ufc/g)
              <div class="sub header">NFV 08 059</div>
            </div>
          </h5>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.yeastG"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'yeastG',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.yeastT"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'yeastT',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="ui header">
            <div class="content">
              Moisissures (ufc/g)
              <div class="sub header">NFV 08 059</div>
            </div>
          </h5>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.moldG"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'moldG',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.moldT"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'moldT',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="ui header">
            <div class="content">
              Salmonella spp (/25 g)
              <div class="sub header">BRD 07/11-12/05</div>
            </div>
          </h5>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.salmonellaG"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'salmonellaG',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.salmonellaT"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'salmonellaT',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="ui header">
            <div class="content">
              E. Coli (ufc/g)
              <div class="sub header">AES 10/06-01/08</div>
            </div>
          </h5>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.eColiG"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'eColiG',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.eColiT"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'eColiT',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="ui header">
            <div class="content">
              Bacillus cereus (ufc/g)
              <div class="sub header">NF EN ISO 7932</div>
            </div>
          </h5>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.bcG"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'bcG',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.bcT"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'bcT',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="ui header">
            <div class="content">
              Staphylococcus à coagulase + (ufc/g)
              <div class="sub header">BRD 07/09-02/05</div>
            </div>
          </h5>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.sacG"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'sacG',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.sacT"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'sacT',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <h5 class="ui header">
            <div class="content">
              Clostridium perfringens (ufc/g)
              <div class="sub header">NF EN ISO 7937</div>
            </div>
          </h5>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.cpG"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'cpG',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
        <td>
          <div class="ui transparent input">
            <input
              :disabled="isViewOnly"
              :value="microbiologicalCriteria.cpT"
              type="text"
              placeholder="ex: < 100"
              @keypress="isAllowedTextChar($event)"
              @keyup="
                setMicrobiologicalCriteria({
                  field: 'cpT',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapState('project', ['microbiologicalCriteria']),
    ...mapGetters('project', ['isViewOnly']),
  },
  methods: {
    ...mapMutations('project', ['setMicrobiologicalCriteria']),
    isAllowedTextChar(event) {
      if (/^[a-zA-Z0-9À-ÖØ-öø-ÿ ().,-]+$/.test(event.key)) return true;
      else event.preventDefault();
    },
  },
};
</script>
