<template>
  <div class="overflow">
    <div class="ui vertical segment">
      <h3>All Variables</h3>
    </div>
    <div class="ui header">Data Variables</div>
    <table class="ui very structured celled table">
      <thead>
        <tr>
          <th rowspan="1">Name</th>
          <th rowspan="1">Description</th>
          <th>CA</th>
          <th>US</th>
          <th>EU</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><code>{name}</code></td>
          <td>Recipe name</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{authorName}</code></td>
          <td>Author full name</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{date}</code></td>
          <td>Date of export</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{servSize}</code></td>
          <td>
            <div class="ui mini image">
              <img
                src="@/assets/templates/serving-size-example.png"
                alt="servingSize"
              />
            </div>
          </td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{numServs}</code></td>
          <td>Number of servings</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{servId}</code></td>
          <td>Serving identifier</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{servIdFr}</code></td>
          <td>Serving identifier (french)</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{servsPerCont}</code></td>
          <td>Servings per container</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{statementOfIdentityHeader}</code></td>
          <td>Header of statement of identity</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{statementOfIdentityHeaderFr}</code></td>
          <td>Header of statement of identity (french)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{statementOfIdentityContent}</code></td>
          <td>Content of statement of identity</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{statementOfIdentityContentFr}</code></td>
          <td>Content of statement of identity (french)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{instructions}</code></td>
          <td>Instructions</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{instructionsFr}</code></td>
          <td>Instructions (french)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{shelfLife}</code></td>
          <td>Shelf life</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{packaging}</code></td>
          <td>Packaging</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{hsCode}</code></td>
          <td>HS Code</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{scoreEurope}</code></td>
          <td>Score Europe</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{sampleCode}</code></td>
          <td>Sample Code</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{productionOrigin}</code></td>
          <td>Production origin</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{productionOriginFr}</code></td>
          <td>Production origin (french)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{foodCategory}</code></td>
          <td>Food category</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{foodCategoryFr}</code></td>
          <td>Foo category (french)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{foodCategoryNumber}</code></td>
          <td>Food category number</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{additionalAllergenStatement}</code></td>
          <td>Additional allergen statement</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{additionalAllergenStatementFr}</code></td>
          <td>Additional allergen statement (french)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{totalWeightGrams}</code></td>
          <td>Serving size * number of servings</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{totalWeightGramsCa}</code></td>
          <td>Servings size * servings per container</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{totalWeightOunces}</code></td>
          <td>Serving size * number of servings * 0.035274</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{cals}</code></td>
          <td>Calories Amount</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{fat}</code></td>
          <td>Total Fat quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{satFat}</code></td>
          <td>Saturated Fat quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{transFat}</code></td>
          <td>Trans Fat quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{omeg6}</code></td>
          <td>Omega 6 quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{omeg3}</code></td>
          <td>Omega 3 quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{chol}</code></td>
          <td>Cholesterol quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{sodium}</code></td>
          <td>Sodium quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{fluoride}</code></td>
          <td>Fluoride quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{carbs}</code></td>
          <td>Total Carbohydrates quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{fiber}</code></td>
          <td>Dietary Fiber (2016) quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{fiberCa}</code></td>
          <td>Total Dietary Fiber quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{sugars}</code></td>
          <td>Total Sugars quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{starch}</code></td>
          <td>Starch quantity</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{addSug}</code></td>
          <td>Added Sugars quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{sugAlc}</code></td>
          <td>Sugar Alcohol quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{sugAlcCa}</code></td>
          <td>Sugar Alcohol (CA) quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{prot}</code></td>
          <td>Protein quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitD}</code></td>
          <td>Vitamin D quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{calc}</code></td>
          <td>Calcium quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{iron}</code></td>
          <td>Iron quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{pot}</code></td>
          <td>Potassium quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitA}</code></td>
          <td>Vitamin A quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitC}</code></td>
          <td>Vitamin C quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitE}</code></td>
          <td>Vitamin E quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitK}</code></td>
          <td>Vitamin K quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB1}</code></td>
          <td>Vitamin B1 - Thiamin quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB2}</code></td>
          <td>Vitamin B2 - Riboflavin quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB3}</code></td>
          <td>Vitamin B3 - Niacin Equiv quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB6}</code></td>
          <td>VitaminB6 quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{folate}</code></td>
          <td>Folate, DFE quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB12}</code></td>
          <td>Vitamin B12 quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{biotin}</code></td>
          <td>Biotin quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{pAcid}</code></td>
          <td>Pantothenic Acid quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{phos}</code></td>
          <td>Phosphorus quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{iodine}</code></td>
          <td>Iodine quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{magnes}</code></td>
          <td>magnesium quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{zinc}</code></td>
          <td>Zinc quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{selen}</code></td>
          <td>Selenium quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{copper}</code></td>
          <td>Copper quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{mangan}</code></td>
          <td>Manganese quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{chrom}</code></td>
          <td>Chromium</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{molyb}</code></td>
          <td>Molybdenum quantity</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>

        <tr>
          <td><code>{cals100g}</code></td>
          <td>Calories quantity for 100g</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{kJ}</code></td>
          <td>Calories * 4.18</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{kJ100g}</code></td>
          <td>Kilojoules for 100g</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>

        <tr>
          <td><code>{fatDv}</code></td>
          <td>Total Fat Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{satFatDv}</code></td>
          <td>Saturated Fat Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{satAndTransFatDv}</code></td>
          <td>Saturated Fat + Trans Fat Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{omeg6Dv}</code></td>
          <td>Omega 6 Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{omega3Dv}</code></td>
          <td>Omega 3 Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{cholDv}</code></td>
          <td>Cholesterol Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{sodiumDv}</code></td>
          <td>Sodium Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{carbsDv}</code></td>
          <td>Total Carbohydrate Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{fiberDv}</code></td>
          <td>Dietary Fiber (2016) Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{fiberCaDv}</code></td>
          <td>Total Dietary Fiber Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{addSugDv}</code></td>
          <td>Added Sugars Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{sugAlcDv}</code></td>
          <td>Sugar Alcohols Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{protDv}</code></td>
          <td>Protein Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitDDv}</code></td>
          <td>Vitamin D Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{calcDv}</code></td>
          <td>Calcium Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{ironDv}</code></td>
          <td>Iron Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{potDv}</code></td>
          <td>Potassium Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitADv}</code></td>
          <td>Vitamin A Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitCDv}</code></td>
          <td>Vitamin C Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitEDv}</code></td>
          <td>Vitamin E Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitKDv}</code></td>
          <td>Vitamin K Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB1Dv}</code></td>
          <td>Vitamin B1 - Thiamin Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB2Dv}</code></td>
          <td>Vitamin B2 - Riboflavin Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB3Dv}</code></td>
          <td>Vitamin B3 - Niacin Equiv Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB6Dv}</code></td>
          <td>Vitamin B6 Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{folateDv}</code></td>
          <td>Folate Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{vitB12Dv}</code></td>
          <td>Vitamin B12 Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{biotinDv}</code></td>
          <td>Biotin Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{pAcidDv}</code></td>
          <td>Pantothenic Acid Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{phosDv}</code></td>
          <td>Phosphorus Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{iodineDv}</code></td>
          <td>Iodine Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{magnesDv}</code></td>
          <td>Magnesium Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{zincDv}</code></td>
          <td>Zinc Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{selenDv}</code></td>
          <td>Selenium Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{copperDv}</code></td>
          <td>Copper Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{manganDv}</code></td>
          <td>Manganese Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{chromDv}</code></td>
          <td>Chromium Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>{molybDv}</code></td>
          <td>Molybdenum Daily Value</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>

        <tr>
          <td><code>{ingredientStatement}</code></td>
          <td>Ingredient Statement</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{ingredientStatementFr}</code></td>
          <td>Ingredient Statement (french)</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{allergenStatementMayContain}</code></td>
          <td>May contain allergens</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{allergenStatementMayContainFr}</code></td>
          <td>May contain allergens (french)</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{allergenStatementContainsFr}</code></td>
          <td>Contains allergens</td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{allergenStatementContains}</code></td>
          <td>Contains allergens (french)</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{claims}</code></td>
          <td>Claims</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{claimsFr}</code></td>
          <td>Claims (french)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{additionalClaims}</code></td>
          <td>Additional claims text</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{kosher}</code></td>
          <td>Kosher status</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{organic}</code></td>
          <td>Organic status</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{glutenFree}</code></td>
          <td>Gluten free status</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{vegetarian}</code></td>
          <td>Vegetarian status</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{vegan}</code></td>
          <td>Vegan status</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{sodiumToG}</code></td>
          <td>Sodium to grams</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{packagedInProtectiveAtmosphere}</code></td>
          <td>Packaged in protective atmosphere</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>

        <tr>
          <td><code>{ebG}</code></td>
          <td>Enterobactéries cible</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{ebT}</code></td>
          <td>Enterobactéries tolérance</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{famG}</code></td>
          <td>Flore aérobie mésophile cible</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{famT}</code></td>
          <td>Flore aérobie mésophile tolérance</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{yeastG}</code></td>
          <td>Levures cible</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{yeastT}</code></td>
          <td>Levures tolérance</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{moldG}</code></td>
          <td>Moisissures cible</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{moldT}</code></td>
          <td>Moisissures tolérance</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{salmonellaG}</code></td>
          <td>Salmonella spp cible</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{salmonellaT}</code></td>
          <td>Salmonella spp tolérance</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{eColiG}</code></td>
          <td>E. Coli cible</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{eColiT}</code></td>
          <td>E. Coli tolérance</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{bcG}</code></td>
          <td>Bacillus cereus cible</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{bcT}</code></td>
          <td>Bacillus cereus tolérance</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{sacG}</code></td>
          <td>Staphylococcus à coagulase cible</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{sacT}</code></td>
          <td>Staphylococcus à coagulase tolérance</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{cpG}</code></td>
          <td>Clostridium perfringens cible</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{cpT}</code></td>
          <td>Clostridium perfringens tolérance</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>

        <tr>
          <td><code>{lIsoleucine}</code></td>
          <td>L-isoleucine quantity (g/100g of protein)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{lLeucine}</code></td>
          <td>L-leucine quantity (g/100g of protein)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{lLysine}</code></td>
          <td>L-lysine quantity (g/100g of protein)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{lMethionineLCystine}</code></td>
          <td>L-méthionine + L-cystine quantity (g/100g of protein)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{lPhenylalanineLTyrosine}</code></td>
          <td>L-phénylalanine + L-tyrosine quantity (g/100g of protein)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{lThreonine}</code></td>
          <td>L-thréonine quantity (g/100g of protein)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{lTryptophane}</code></td>
          <td>L-tryptophane quantity (g/100g of protein)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{lValine}</code></td>
          <td>L-valine quantity (g/100g of protein)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>{histidine}</code></td>
          <td>Histidine quantity (g/100g of protein)</td>
          <td></td>
          <td></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
      </tbody>
    </table>

    <div class="ui header">Conditional Variables</div>
    <p>
      Remeber that a condition variable (condVar) is wrapped like this:
      <code>{#condVar}{/condVar}</code>
    </p>
    <table class="ui very structured celled table">
      <thead>
        <tr>
          <th rowspan="2">Name</th>
          <th rowspan="2">Description</th>
          <th colspan="3">Countries</th>
        </tr>
        <tr>
          <th>Canada</th>
          <th>USA</th>
          <th>Europe</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><code>hasServStmt</code></td>
          <td>If <code>servStmt</code> is valid and exists</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasServsPerCont</code></td>
          <td>If servings per container exists</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>

        <tr>
          <td><code>hasFat</code></td>
          <td><code>If Total Fat is on NFP</code></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>hasSatFat</code></td>
          <td>If Saturated Fat is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>hasTransFat</code></td>
          <td>If Trans Fat is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasOmeg6</code></td>
          <td>If Omega 6 is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasOmeg3</code></td>
          <td>If Omega 3 is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasChol</code></td>
          <td>If Cholesterol is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasSodium</code></td>
          <td>If Sodium is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasFluoride</code></td>
          <td>If Fluoride is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasCarbs</code></td>
          <td>If Total Carbohydrate is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>hasFiber</code></td>
          <td>If Dietary Fiber (2016) is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>hasFiberCa</code></td>
          <td>If Total Dietary Fiber is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasSugars</code></td>
          <td>If Total Sugars is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>hasAddSug</code></td>
          <td>If Added Sugars is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasSugAlc</code></td>
          <td>If Sugar Alcohol is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasSugAlcCa</code></td>
          <td>If Sugar Alcohol (CA) is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasProt</code></td>
          <td>If Protein is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
        </tr>
        <tr>
          <td><code>hasVitD</code></td>
          <td>If Vitamin D is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasCalc</code></td>
          <td>If Calcium is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasIron</code></td>
          <td>If Iron Fat is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasPot</code></td>
          <td>If Potassium is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasVitA</code></td>
          <td>If Vitamin A is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasVitC</code></td>
          <td>If Vitamin C is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasVitE</code></td>
          <td>If Vitamin E is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasVitK</code></td>
          <td>If Vitamin K is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasVitB1</code></td>
          <td>If Vitamin B1 - Thiamin is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasVitB2</code></td>
          <td>If Vitamin B2 - Riboflavin is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasVitB3</code></td>
          <td>If Vitamin B3 - Niacin Equiv is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasVitB6</code></td>
          <td>If Vitamin B6 is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasFolate</code></td>
          <td>If Folate, DFE is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasVitB12</code></td>
          <td>If Vitamin B12 is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasBiotin</code></td>
          <td>If Biotin is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasPAcid</code></td>
          <td>If Pantothenic Acid is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasPhos</code></td>
          <td>If Phosphorus is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasIodine</code></td>
          <td>If Iodine is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasMagnes</code></td>
          <td>If Magnesium is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasZinc</code></td>
          <td>If Zinc is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasSelen</code></td>
          <td>If Selenium is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasCopper</code></td>
          <td>If Copper is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasMangan</code></td>
          <td>If Manganese is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasChrom</code></td>
          <td>If Chromium is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
        <tr>
          <td><code>hasMolyb</code></td>
          <td>If Molybdenum is on NFP</td>
          <td><i class="large green checkmark icon"></i></td>
          <td><i class="large green checkmark icon"></i></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.overflow {
  overflow: auto;
  max-height: 75vh;
  scrollbar-width: thin;
}
</style>
