<template>
  <table class="ui small celled compact striped sortable components table">
    <thead>
      <tr v-if="!isViewOnly">
        <th colspan="4" class="actions">
          <button
            class="ui secondary mini basic button"
            :class="{ disabled: isRefreshingComponents }"
            @click="addSelectedComponents"
          >
            Add
          </button>
          <button
            class="ui secondary mini basic button"
            :class="{ disabled: isRefreshingComponents }"
            @click="addAllComponents"
          >
            Add All
          </button>
          <button
            class="ui secondary mini basic button"
            :class="{ disabled: isRefreshingComponents }"
            @click="clearSelectedComponents"
          >
            Unselect All
          </button>
          <button
            class="ui secondary mini basic button"
            :class="{
              'disabled loading':
                isRefreshingComponents || components.length == 0,
            }"
            @click="refreshComponents"
          >
            Refresh
          </button>
          <span>Selected: {{ selectedComponents.length }}</span>
        </th>
      </tr>
      <tr>
        <th :class="sortingClass('code')" @click="sortTable('code')">Code</th>
        <th :class="sortingClass('name')" @click="sortTable('name')">Name</th>
        <th :class="sortingClass('qty')" @click="sortTable('qty')">Qty</th>
        <th :class="sortingClass('qty')" @click="sortTable('qty')">%</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="components.length == 0">
        <td colspan="4">
          <div class="loader">
            <BarLoader />
          </div>
        </td>
      </tr>

      <tr
        v-for="component in sortedComponents"
        v-else
        :key="component.cid"
        :class="{
          warning: !component.name,
          selectable:
            component.name &&
            !isInIngredientStatement(component.cid) &&
            !isViewOnly,
          active: selectedComponents.includes(component),
          positive: isInIngredientStatement(component.cid),
        }"
        @click="toggleSelected(component)"
      >
        <td>{{ component.code.join(', ') }}</td>
        <td>
          {{ component.name ? component.name : 'Not Found' }}
        </td>
        <td>{{ component.qty ? component.qty + 'g' : 'Not Found' }}</td>
        <td>
          {{
            component.qty
              ? Math.round((component.qty / recipeInfo.gramWeight) * 1000000) /
                10000
              : 'Not Found'
          }}
        </td>
      </tr>
    </tbody>
  </table>

  <AddComponent />
  <AddComponentGroup />
  <EditComponent />
  <MissingComponentAlert />
</template>

<script>
import AddComponent from '@/components/project/builder/AddComponent';
import AddComponentGroup from '@/components/project/builder/AddComponentGroup';
import EditComponent from '@/components/project/builder/EditComponent';
import MissingComponentAlert from '@/components/project/builder/MissingComponentAlert';
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  components: {
    AddComponent,
    AddComponentGroup,
    EditComponent,
    MissingComponentAlert,
  },
  data() {
    return {
      isRefreshingComponents: false,
      sorting: {
        col: null,
        ascending: true,
      },
    };
  },
  computed: {
    ...mapState('project', [
      'components',
      'recipeInfo',
      'selectedComponents',
      'ingredientStatement',
    ]),
    ...mapGetters('project', ['isViewOnly']),
    sortedComponents() {
      var sortedComponents = this.components;

      // Sort list
      if (this.sorting.col) {
        sortedComponents.sort((e1, e2) => {
          const val1 =
            typeof e1[this.sorting.col] == 'string'
              ? e1[this.sorting.col].toLowerCase()
              : e1[this.sorting.col];
          const val2 =
            typeof e2[this.sorting.col] == 'string'
              ? e2[this.sorting.col].toLowerCase()
              : e2[this.sorting.col];

          if (val1 < val2) return this.sorting.ascending ? -1 : 1;
          if (val1 > val2) return this.sorting.ascending ? 1 : -1;
          return 0;
        });
      }
      return sortedComponents;
    },
  },
  methods: {
    ...mapMutations('project', ['clearSelectedComponents']),
    addSelectedComponents() {
      if (this.selectedComponents.length == 0)
        return this.$store.commit('showAlert', {
          type: 'info',
          header: 'No component selected',
          message: 'Please selected a component first',
          timeout: 5000,
        });

      if (this.selectedComponents.length == 1)
        this.$store.commit('project/openAddComponentModal');
      else this.$store.commit('project/openGroupComponentsModal');
    },
    addAllComponents() {
      this.components.forEach(component => {
        if (component.name && !this.isInIngredientStatement(component.cid))
          this.$store.commit('project/addToIngredientStatement', {
            code: component.code,
            name: component.name,
            nameFr: component.nameFr,
            qty: component.qty,
            cid: [component.cid],
          });
      });
      this.$store.commit('project/clearSelectedComponents');
    },
    toggleSelected(component) {
      if (this.isViewOnly) return;
      if (component.name && !this.isInIngredientStatement(component.cid))
        this.$store.commit('project/toggleSelectedComponent', { component });
    },
    isInIngredientStatement(cid) {
      const index = this.$store.state.project.ingredientStatement.findIndex(
        item => item.cid.includes(cid),
      );
      return index >= 0;
    },
    async refreshComponents() {
      this.isRefreshingComponents = true;
      this.$store.commit('project/clearIngredientStatement');
      this.clearSelectedComponents();
      await this.$store.dispatch('project/lookupComponents');
      this.isRefreshingComponents = false;
    },

    sortTable(col) {
      if (this.sorting.col == col) {
        this.sorting.ascending = !this.sorting.ascending;
      } else {
        this.sorting.col = col;
        this.sorting.ascending = true;
      }
    },

    // Return classes for table sorting
    sortingClass(col) {
      return {
        sorted: this.sorting.col == col,
        ascending: this.sorting.ascending,
        descending: !this.sorting.ascending,
      };
    },
  },
};
</script>

<style scoped>
thead tr:first-child {
  position: sticky;
  top: 0;
}

.actions:hover {
  background-color: #f9fafb !important;
  cursor: auto !important;
}

.loader {
  display: grid;
  justify-content: center;
  align-content: center;
}

thead tr th span {
  float: right;
  margin-right: 1rem;
  padding-top: 0.3rem;
}

.selectable:hover {
  background: #c0c0c0 !important;
}

.selectable:active {
  background: #ebebeb !important;
}
td {
  font-size: 0.8rem;
}

table {
  margin-bottom: 1rem !important;
}
</style>
