<template>
  <div class="top header">
    <h2>
      <svg
        id="burger"
        transform="translate(0,3)"
        height="20"
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        @click="openNavbar()"
      >
        <path
          fill="currentColor"
          d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
        ></path>
      </svg>
      {{ headerTitle }}
      <span
        v-if="showUserInfo && activeUser && windowWidth > 900"
        class="subheader"
      >
        {{ activeUser.fullName }}
        <br />
        {{ activeUser.email }}
      </span>
    </h2>
    <hr />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    showUserInfo: Boolean,
  },
  computed: {
    ...mapState(['windowWidth']),
    ...mapGetters(['activeUser']),
  },
  methods: {
    ...mapMutations(['openNavbar']),
  },
};
</script>

<style scoped>
#burger {
  display: none;
  cursor: pointer;
  margin-right: 0.75rem;
}

@media screen and (max-width: 900px) {
  #burger {
    display: inline !important;
  }
  .top.header h2 {
    padding-left: 0.15em;
  }
  .top.header {
    margin-left: 3em;
    margin-right: 3em;
    width: auto;
  }
}

@media screen and (min-width: 900px) {
  .top.header {
    margin-left: 15em;
    width: calc(100vw - 15rem);
    height: 4.2em;
  }
  .top.header h2 {
    padding: 0.85em 1em 0.13em 1.8em !important;
  }
}
.top.header h2 {
  padding-top: 0.85em;
}

.top.header .subheader {
  position: absolute;
  right: 3rem;
  padding-right: 0.5rem;
  color: #94949484;
  font-size: 1rem;
  line-height: 1rem;
  text-align: right;
}

.top.header .icon {
  padding: 1.9em 1em 0.4em 3.1em;
}

.top.header .icon a {
  color: #242d3c;
  padding-right: 0.8em;
}

.top.header .icon a:hover {
  color: #4eacf2;
}

.top.header .icon span {
  font-size: 20px;
}

.top.header hr {
  border: 0.3px solid #dcdcdc;
}
</style>
