/**
 * ID generator function
 * @yields {number}
 */
function* idMaker() {
  let i = 1000;
  for (;;) yield ++i;
}

/**
 * Component id (cid) generator
 */
const cidGenerator = idMaker();

export default {
  /* Ingredient Database Page */
  setLoadingSearchResults: (state, payload) => {
    state.isLoadingResults = payload.status;
  },
  setIngredientCodeToLoad: (state, payload) => {
    state.loadingIngredientCode = payload.code;
  },
  setSearchResults: (state, payload) => {
    state.searchResults = payload.searchResults;
  },
  setOriginalCode: (state, payload) => {
    state.ingredient.originalCode = payload.code;
  },
  openIngredientModal: (state, payload) => {
    state.ingredientModalOpen = true;
    state.ingredient.editMode = payload.editMode;
    if (payload.code) state.ingredient.code = payload.code;
  },

  updateIngredientCode: (state, payload) => {
    state.ingredient.code = payload.update;
  },

  clearSearchResults: state => {
    state.searchResults = [];
  },

  removeFromSearchResults: (state, payload) => {
    state.searchResults = state.searchResults.filter(
      e => e.code != payload.code,
    );
  },

  setIngredientToDelete: (state, payload) => {
    state.ingredientToDelete = payload.code;
  },

  /**
   * Close modal
   */
  closeModal: state => {
    state.ingredientToDelete = null;
    state.deleteModalOpen = false;
    state.ingredientModalOpen = false;
    state.ingredient.components = [];
    state.ingredient.code = '';
    state.ingredient.editMode = false;
    state.ingredient.allergens = [];
  },

  /**
   * Delete ingredient
   */
  openDeleteModal: (state, payload) => {
    state.ingredientToDelete = payload.code;
    state.deleteModalOpen = true;
  },

  /**
   * Remove a component
   */
  removeComponent: (state, payload) => {
    state.ingredient.components = state.ingredient.components.filter(
      c => c.cid != payload.cid,
    );
  },

  /**
   * Add a component
   */
  addComponent: (state, payload) => {
    payload.cid = 'CID' + cidGenerator.next().value;
    state.ingredient.components.push(payload);
  },

  /**
   * Select/Deselect allergen
   */
  toggleAllergen: (state, payload) => {
    const index = state.ingredient.allergens.indexOf(payload.value);
    if (index < 0) state.ingredient.allergens.push(payload.value);
    else state.ingredient.allergens.splice(index, 1);
  },

  /**
   * Reset state to defaults
   */
  reset(state) {
    state.searchResults = [];

    state.ingredientModalOpen = false;
    state.deleteModalOpen = false;

    state.isLoadingResults = false;
    state.loadingIngredientCode = null;
    state.ingredientToDelete = null;

    state.ingredient = {
      components: [],
      code: '',
      originalCode: null,
      editMode: false,
      allergens: [],
    };
  },
};
