<template>
  <BaseModal
    :show="openModal == 'editComponentModal'"
    @deny="closeProjectModal"
  >
    <div class="ui vertical segment title">
      <h3>Edit Component</h3>
    </div>

    <div class="ui vertical segment">
      <div class="ui form">
        <div class="field" :class="{ error: !isValidName }">
          <label>Component Name</label>
          <input
            ref="componentName"
            type="text"
            :value="ingredientStatementItemToEdit.name"
            placeholder="Enter component name..."
            maxlength="500"
            @keydown="isValidName = true"
            @keypress="isAllowedChar"
            @keyup.enter="pushEdit"
          />
        </div>
        <div
          v-if="hasSecondLanguage"
          class="field"
          :class="{ error: !isValidNameFr }"
        >
          <label>Component Name (FR)</label>
          <input
            ref="componentNameFr"
            type="text"
            :value="ingredientStatementItemToEdit.nameFr"
            placeholder="Enter component name..."
            maxlength="500"
            @keydown="isValidNameFr = true"
            @keypress="isAllowedChar"
            @keyup.enter="pushEdit"
          />
        </div>
      </div>
    </div>

    <div class="ui vertical segment actions">
      <button class="ui black mini button" @click="closeProjectModal">
        Cancel
      </button>
      <button class="ui positive mini button" @click="pushEdit">Confirm</button>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  data() {
    return {
      isValidName: true,
      isValidNameFr: true,
    };
  },
  computed: {
    ...mapState('project', ['openModal', 'ingredientStatementItemToEdit']),
    ...mapGetters('project', ['hasSecondLanguage']),
  },
  methods: {
    closeProjectModal() {
      this.isValidName = true;
      this.isValidNameFr = true;
      this.$store.commit('project/closeProjectModal');
    },
    isAllowedChar(event) {
      if (!/^[A-Za-z0-9À-ÖØ-öø-ÿ/ ()-]+$/.test(event.key))
        event.preventDefault();
    },

    pushEdit() {
      // Validation
      if (this.$refs.componentName.value.trim().length == 0)
        return (this.isValidName = false);
      if (
        this.hasSecondLanguage &&
        this.$refs.componentNameFr.value.trim().length == 0
      )
        return (this.isValidNameFr = false);

      // Update
      this.$store.commit('project/pushEditedComponent', {
        name: this.$refs.componentName.value,
        nameFr: this.hasSecondLanguage
          ? this.$refs.componentNameFr.value
          : null,
      });

      this.closeProjectModal();
    },
  },
};
</script>

<style scoped>
input {
  min-width: 40vw;
}
</style>
