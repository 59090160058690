<template>
  <div class="ui segment" :style="{ maxHeight: maxHeight + 'rem' }">
    <table>
      <thead>
        <tr class="thick underline">
          <th class="header">Nutrition Information</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="thick underline">
          <td></td>
          <td>Per 100 g</td>
          <td>Per portion of {{ servingSize }}</td>
        </tr>
        <tr>
          <td><b>Energy values / Valeurs énergétiques</b></td>
        </tr>
        <tr v-if="getNutrient('Calories') && getNutrient('Calories').show">
          <td>kcal</td>
          <td>{{ getNutrient('Calories', 100).roundedQty }}</td>
          <td>{{ getNutrient('Calories').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Calories') && getNutrient('Calories').show">
          <td>kJ</td>
          <td>{{ getNutrient('Calories', 100, 4.18).roundedQty }}</td>
          <td>{{ getNutrient('Calories', undefined, 4.18).roundedQty }}</td>
        </tr>
        <tr>
          <td>
            <b>Typical nutritional values / Valeurs nutritionnelles typiques</b>
          </td>
        </tr>
        <tr v-if="getNutrient('Fat') && getNutrient('Fat').show">
          <td>Fat / Matières grasses (g)</td>
          <td>{{ getNutrient('Fat', 100).roundedQty }}</td>
          <td>{{ getNutrient('Fat').roundedQty }}</td>
        </tr>
        <tr
          v-if="
            getNutrient('Saturated Fat') && getNutrient('Saturated Fat').show
          "
        >
          <td class="indent">
            Of which saturates / Dont acides gras saturés (g)
          </td>
          <td>{{ getNutrient('Saturated Fat', 100).roundedQty }}</td>
          <td>{{ getNutrient('Saturated Fat').roundedQty }}</td>
        </tr>

        <tr
          v-if="
            getNutrient('Omega 3 Fatty Acid') &&
            getNutrient('Omega 3 Fatty Acid').show
          "
        >
          <td class="indent">Of which omega 3 / Dont oméga 3 (g)</td>
          <td>{{ getNutrient('Omega 3 Fatty Acid', 100).roundedQty }}</td>
          <td>{{ getNutrient('Omega 3 Fatty Acid').roundedQty }}</td>
        </tr>

        <tr
          v-if="
            getNutrient('Carbohydrates') &&
            getNutrient('Carbohydrates').show &&
            getNutrient('Total Dietary Fiber')
          "
        >
          <td>Carbohydrate / Glucides (g)</td>
          <td>
            {{
              getNutrient('Carbohydrates', 100).roundedQty -
              getNutrient('Total Dietary Fiber', 100).roundedQty
            }}
          </td>
          <td>
            {{
              getNutrient('Carbohydrates').roundedQty -
              getNutrient('Total Dietary Fiber').roundedQty
            }}
              <!-- TODO: roundng after substraction -->
          </td>
        </tr>
        <tr
          v-if="
            getNutrient('Carbohydrates') &&
            getNutrient('Carbohydrates').show &&
            getNutrient('Total Sugars') &&
            getNutrient('Total Sugars').show
          "
        >
          <td class="indent">Of which sugars / Dont sucres (g)</td>
          <td>{{ getNutrient('Total Sugars', 100).roundedQty }}</td>
          <td>{{ getNutrient('Total Sugars').roundedQty }}</td>
        </tr>
        <tr
          v-if="
            getNutrient('Carbohydrates') &&
            getNutrient('Carbohydrates').show &&
            getNutrient('Total Sugars')
          "
        >
          <td class="indent">Of which starch / Dont amidon (g)</td>
          <td>
            {{
              getNutrient('Carbohydrates', 100).roundedQty -
              getNutrient('Total Sugars', 100).roundedQty -
              sugarAlcohols
            }}
          </td>
          <td>
            {{
              getNutrient('Carbohydrates').roundedQty -
              getNutrient('Total Sugars').roundedQty -
              sugarAlcohols * (servingSize / 100)
            }}
          </td>
        </tr>

        <!-- //TODO: add of which sugar alcohols -->

        <tr
          v-if="
            getNutrient('Total Dietary Fiber') &&
            getNutrient('Total Dietary Fiber').show
          "
        >
          <td>Fibre / Fibres alimentaires (g)</td>
          <td>{{ getNutrient('Total Dietary Fiber', 100).roundedQty }}</td>
          <td>{{ getNutrient('Total Dietary Fiber').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Protein') && getNutrient('Protein').show">
          <td>Protein / Protéines (g)</td>
          <td>{{ getNutrient('Protein', 100).roundedQty }}</td>
          <td>{{ getNutrient('Protein').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Sodium') && getNutrient('Sodium').show">
          <td>Salt / Sel (g)</td>
          <td>{{ getNutrient('Sodium', 100, 0.001).roundedQty }}</td>
          <td>{{ getNutrient('Sodium', undefined, 0.001).roundedQty }}</td>
        </tr>
        <tr class="thick underline">
          <td><b>Vitamins & minerals / Vitamines et minéraux</b></td>
        </tr>
        <tr
          v-if="
            getNutrient('Vitamin A - RAE') &&
            getNutrient('Vitamin A - RAE').show
          "
        >
          <td>Vitamin A / Vitamine A (μg RE)</td>
          <td>{{ getNutrient('Vitamin A - RAE', 100).roundedQty }}</td>
          <td>{{ getNutrient('Vitamin A - RAE').roundedQty }}</td>
        </tr>
        <tr
          v-if="
            getNutrient('Vitamin D - mcg') &&
            getNutrient('Vitamin D - mcg').show
          "
        >
          <td>Vitamin D / Vitamine D (μg)</td>
          <td>{{ getNutrient('Vitamin D - mcg', 100).roundedQty }}</td>
          <td>{{ getNutrient('Vitamin D - mcg').roundedQty }}</td>
        </tr>
        <tr
          v-if="
            getNutrient('Vitamin E - Alpha-Toco') &&
            getNutrient('Vitamin E - Alpha-Toco').show
          "
        >
          <td>Vitamin E / Vitamine E (mg)</td>
          <td>{{ getNutrient('Vitamin E - Alpha-Toco', 100).roundedQty }}</td>
          <td>{{ getNutrient('Vitamin E - Alpha-Toco').roundedQty }}</td>
        </tr>

        <tr v-if="getNutrient('Vitamin C') && getNutrient('Vitamin C').show">
          <td>Vitamin C / Vitamine C (mg)</td>
          <td>{{ getNutrient('Vitamin C', 100).roundedQty }}</td>
          <td>{{ getNutrient('Vitamin C').roundedQty }}</td>
        </tr>
        <tr
          v-if="
            getNutrient('Vitamin B1 - Thiamin') &&
            getNutrient('Vitamin B1 - Thiamin').show
          "
        >
          <td>Thiamin / Thiamine (mg)</td>
          <td>{{ getNutrient('Vitamin B1 - Thiamin', 100).roundedQty }}</td>
          <td>{{ getNutrient('Vitamin B1 - Thiamin').roundedQty }}</td>
        </tr>
        <tr
          v-if="
            getNutrient('Vitamin B2 - Riboflavin') &&
            getNutrient('Vitamin B2 - Riboflavin').show
          "
        >
          <td>Riboflavin / Riboflavine (mg)</td>
          <td>{{ getNutrient('Vitamin B2 - Riboflavin', 100).roundedQty }}</td>
          <td>{{ getNutrient('Vitamin B2 - Riboflavin').roundedQty }}</td>
        </tr>
        <tr
          v-if="
            getNutrient('Vitamin B3 - Niacin Equiv') &&
            getNutrient('Vitamin B3 - Niacin Equiv').show
          "
        >
          <td>Niacin / Niacine (mg NE)</td>
          <td>
            {{ getNutrient('Vitamin B3 - Niacin Equiv', 100).roundedQty }}
          </td>
          <td>{{ getNutrient('Vitamin B3 - Niacin Equiv').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Vitamin B6') && getNutrient('Vitamin B6').show">
          <td>Vitamin B6 / Vitamine B6 (mg)</td>
          <td>{{ getNutrient('Vitamin B6', 100).roundedQty }}</td>
          <td>{{ getNutrient('Vitamin B6').roundedQty }}</td>
        </tr>
        <tr
          v-if="getNutrient('Folate, DFE') && getNutrient('Folate, DFE').show"
        >
          <td>Folate / Folate (μg)</td>
          <td>{{ getNutrient('Folate, DFE', 100).roundedQty }}</td>
          <td>{{ getNutrient('Folate, DFE').roundedQty }}</td>
        </tr>
        <tr
          v-if="getNutrient('Vitamin B12') && getNutrient('Vitamin B12').show"
        >
          <td>Vitamin B12 / Vitamine B12 (μg)</td>
          <td>{{ getNutrient('Vitamin B12', 100).roundedQty }}</td>
          <td>{{ getNutrient('Vitamin B12').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Biotin') && getNutrient('Biotin').show">
          <td>Biotin / Biotine (μg)</td>
          <td>{{ getNutrient('Biotin', 100).roundedQty }}</td>
          <td>{{ getNutrient('Biotin').roundedQty }}</td>
        </tr>
        <tr
          v-if="
            getNutrient('Pantothenic Acid') &&
            getNutrient('Pantothenic Acid').show
          "
        >
          <td>Pantothenic acid / Acide pantothénique (mg)</td>
          <td>{{ getNutrient('Pantothenic Acid', 100).roundedQty }}</td>
          <td>{{ getNutrient('Pantothenic Acid').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Calcium') && getNutrient('Calcium').show">
          <td>Calcium / Calcium (mg)</td>
          <td>{{ getNutrient('Calcium', 100).roundedQty }}</td>
          <td>{{ getNutrient('Calcium').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Phosphorus') && getNutrient('Phosphorus').show">
          <td>Phosphorus / Phosphore (mg)</td>
          <td>{{ getNutrient('Phosphorus', 100).roundedQty }}</td>
          <td>{{ getNutrient('Phosphorus').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Potassium') && getNutrient('Potassium')">
          <td>Potassium / Potassium (mg)</td>
          <td>{{ getNutrient('Potassium', 100).roundedQty }}</td>
          <td>{{ getNutrient('Potassium').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Iron') && getNutrient('Iron').show">
          <td>Iron / Fer (mg)</td>
          <td>{{ getNutrient('Iron', 100).roundedQty }}</td>
          <td>{{ getNutrient('Iron').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Zinc') && getNutrient('Zinc').show">
          <td>Zinc / Zinc (mg)</td>
          <td>{{ getNutrient('Zinc', 100).roundedQty }}</td>
          <td>{{ getNutrient('Zinc').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Copper') && getNutrient('Copper').show">
          <td>Copper / Cuivre (mg)</td>
          <td>{{ getNutrient('Copper', 100).roundedQty }}</td>
          <td>{{ getNutrient('Copper').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Iodine') && getNutrient('Iodine').show">
          <td>Iodine / Iode (μg)</td>
          <td>{{ getNutrient('Iodine', 100).roundedQty }}</td>
          <td>{{ getNutrient('Iodine').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Molybdenum') && getNutrient('Molybdenum').show">
          <td>Molybdenum / Molybdenum (µg)</td>
          <td>{{ getNutrient('Molybdenum', 100).roundedQty }}</td>
          <td>{{ getNutrient('Molybdenum').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Selenium') && getNutrient('Selenium').show">
          <td>Selenium / Sélénite (μg)</td>
          <td>{{ getNutrient('Selenium', 100).roundedQty }}</td>
          <td>{{ getNutrient('Selenium').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Magnesium') && getNutrient('Magnesium').show">
          <td>Magnesium / Magnésium (mg)</td>
          <td>{{ getNutrient('Magnesium', 100).roundedQty }}</td>
          <td>{{ getNutrient('Magnesium').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Manganese') && getNutrient('Manganese').show">
          <td>Manganese / Manganèse (mg)</td>
          <td>{{ getNutrient('Manganese', 100).roundedQty }}</td>
          <td>{{ getNutrient('Manganese').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Chromium') && getNutrient('Chromium').show">
          <td>Chromium / Chrome (μg)</td>
          <td>{{ getNutrient('Chromium', 100).roundedQty }}</td>
          <td>{{ getNutrient('Chromium').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Fluoride') && getNutrient('Fluoride').show">
          <td>Fluoride / Fluor (mg)</td>
          <td>{{ getNutrient('Fluoride', 100).roundedQty }}</td>
          <td>{{ getNutrient('Fluoride').roundedQty }}</td>
        </tr>
        <tr v-if="getNutrient('Chloride') && getNutrient('Chloride').show">
          <td>Chloride / Chlorure (mg)</td>
          <td>{{ getNutrient('Chloride', 100).roundedQty }}</td>
          <td>{{ getNutrient('Chloride').roundedQty }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  props: {
    maxHeight: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('project', ['getNutrient']),
    ...mapState('project', ['numServings', 'servingSize', 'sugarAlcohols']),
  },
};
</script>

<style scoped>
.segment {
  border: 1px solid black;
  padding: 0.5rem;
  overflow: auto;
  scrollbar-width: thin;
}

table {
  border-collapse: collapse;
  font-size: 0.7rem;
  font-family: Helvetica, sans-serif;
  font-weight: 500;
  width: 100%;
}

tbody tr {
  border-bottom: 1px solid black;
}

tbody tr.thick-line {
  border-bottom: 3px solid black;
}

tbody tr td:nth-of-type(1),
thead tr th:nth-of-type(1) {
  text-align: left;
}

tbody tr td:nth-of-type(2),
tbody tr td:nth-of-type(3) {
  text-align: center;
  border-left: 1px solid rgba(126, 126, 126, 0.5);
}

tr.underline {
  border-bottom: 1px solid black;
}

tr.thick.underline {
  border-bottom: 3px solid black;
}

td.indent {
  padding-left: 1rem;
}

table .header {
  font-size: 1.2rem;
}
</style>