import request from '../../../request';

export default {
  setRegion: async (context, payload) => {
    // Clear current results
    context.state.regionItems = null;
    context.state.mealReplacement = null;
    context.state.selectedRegion = payload.region;

    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: `/api/rules/${context.state.selectedRegion}`,
      });
      context.state.regionItems = response.data.nutrients;
      context.state.mealReplacement = response.data.mealReplacement;
    } catch (err) {
      console.error(err);
    }
  },

  addRule: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'POST',
        url: `/api/rules/${context.state.selectedRegion}/new`,
        data: {
          nutrientName: context.state.activeNutrient,
          min: payload.min,
          max: payload.max,
          operation: payload.operation,
          operationData: payload.operationData,
          type: payload.type,
        },
      });
      context.dispatch('setRegion', { region: context.state.selectedRegion });
    } catch (err) {
      console.error(err);
    }
  },

  deleteRule: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'DELETE',
        url: `/api/rules/${context.state.selectedRegion}/${payload.type}`,
        data: {
          nutrientName: payload.nutrientName,
          index: payload.index,
          min: payload.min,
          max: payload.max,
        },
      });
      context.dispatch('setRegion', { region: context.state.selectedRegion });
    } catch (err) {
      console.error(err);
    }
  },

  changeRequiredOnLabel: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'POST',
        url: `/api/rules/${context.state.selectedRegion}/label-requirement`,
        data: {
          nutrientName: payload.nutrientName,
          required: payload.required,
        },
      });
    } catch (err) {
      console.error(err);
    }
  },

  updateDv: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'PUT',
        url: `/api/rules/${context.state.selectedRegion}/daily-value/${context.state.activeNutrient}`,
        data: { value: payload.value },
      });
      context.dispatch('setRegion', { region: context.state.selectedRegion });
    } catch (err) {
      console.error(err);
    }
  },

  updateMealReplacementCalculation: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'PUT',
        url: `/api/rules/${context.state.selectedRegion}/meal-replacement/${context.state.activeMealReplacementItem.item.name}`,
        data: {
          expression: payload.expression,
          type: context.state.activeMealReplacementItem.type,
        },
      });
      context.dispatch('setRegion', { region: context.state.selectedRegion });
    } catch (err) {
      console.error(err);
    }
  },
};
