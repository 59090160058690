<template>
  <div class="ui users segment">
    <h3>Users</h3>

    <div class="ui icon fluid small input mb-1">
      <i class="search icon"></i>
      <input v-model.trim="searchInput" type="text" placeholder="Search..." />
    </div>

    <div class="overflow">
      <BarLoader v-if="!allUsers || allUsers.length == 0" />
      <table v-else class="ui compact sortable celled table">
        <thead>
          <tr>
            <th
              class="three wide"
              :class="{
                sorted: sortingCol.col == 'fullName',
                ascending: sortingCol.ascending,
                descending: !sortingCol.ascending,
              }"
              @click="sortUsers('fullName')"
            >
              Full Name
            </th>
            <th
              class="two wide"
              :class="{
                sorted: sortingCol.col == 'username',
                ascending: sortingCol.ascending,
                descending: !sortingCol.ascending,
              }"
              @click="sortUsers('username')"
            >
              Username
            </th>
            <th
              class="four wide"
              :class="{
                sorted: sortingCol.col == 'email',
                ascending: sortingCol.ascending,
                descending: !sortingCol.ascending,
              }"
              @click="sortUsers('email')"
            >
              Email
            </th>
            <th
              class="two wide"
              :class="{
                sorted: sortingCol.col == 'clearanceLevel',
                ascending: sortingCol.ascending,
                descending: !sortingCol.ascending,
              }"
              @click="sortUsers('clearanceLevel')"
            >
              Account type
            </th>
            <th
              class="two wide"
              :class="{
                sorted: sortingCol.col == 'active',
                ascending: sortingCol.ascending,
                descending: !sortingCol.ascending,
              }"
              @click="sortUsers('active')"
            >
              Status
            </th>
            <th class="three wide" scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user._id">
            <td>{{ user.fullName }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ clearanceLevelName(user) }}</td>
            <td>{{ getUserStatus(user) }}</td>
            <td>
              <div>
                <div class="ui mini compact menu">
                  <div class="ui simple dropdown item">
                    Actions
                    <i class="dropdown icon"></i>
                    <div class="menu">
                      <div class="item" @click="openEditUser({ user })">
                        <i class="pencil alternate icon"></i>
                        Edit Type
                      </div>
                      <div class="item" @click="openResetPassword({ user })">
                        <i class="key icon"></i>
                        Reset password
                      </div>
                      <div class="red item" @click="openDeleteUser({ user })">
                        <i class="red close icon"></i>
                        Delete User
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    ...mapState('admin', ['allUsers', 'sortingCol']),
    ...mapGetters(['clearanceLevelName']),
    users() {
      var filteredUsers = this.allUsers;

      // Filter by name
      if (this.searchInput && this.searchInput != '') {
        filteredUsers = filteredUsers.filter(user => {
          return (
            user.username
              .toLowerCase()
              .includes(this.searchInput.toLowerCase()) ||
            user.fullName
              .toLowerCase()
              .includes(this.searchInput.toLowerCase()) ||
            user.email.toLowerCase().includes(this.searchInput.toLowerCase())
          );
        });
      }

      return filteredUsers;
    },
  },
  created() {
    this.$store.dispatch('admin/getAllUsers');
  },
  methods: {
    ...mapMutations('admin', [
      'openEditUser',
      'openDeleteUser',
      'openResetPassword',
      'sortUsers',
    ]),
    getUserStatus(user) {
      if (user.activationToken) return 'Pending Activation';
      if (user.active) return 'Active';
      else return 'Inactive';
    },
  },
};
</script>

<style scoped>
.users.segment {
  overflow: auto;
  scrollbar-width: thin;
  max-height: 70vh;
}
table {
  margin-bottom: 1rem !important;
}
td > div {
  min-height: 4.8rem !important;
}
</style>
