<template>
  <div class="overflow">
    <div class="ui vertical segment">
      <h3>Making changes to templates</h3>
    </div>
    <p>
      To make changes to the current template, simply download the template you
      want to edit, make your edits, then upload it using the appropriate card.
    </p>
    <p>
      When making changes, make sure not to change the names of any variables.
      Doing so could cause certain data not to show up on the spec sheet
      anymore. If you want certain variables to appear differently on the
      rendered spec sheet (larger font, underlined, etc.) you can change the
      style of the data variable directly. For example, unbolding
      <code>{calories}</code> will unbold it in the rendered spec sheet.
    </p>
    <p>
      You might have noticed that some conditional variables appear very small
      on the templates. This is done to save space since
      <u>conditional variables are unaffected by any styles</u>.
    </p>

    <div class="ui huge image">
      <img
        src="@/assets/templates/variables-example-2.png"
        alt="Styling variables"
      />
    </div>

    <div class="ui header">Common mistakes</div>
    <div class="ui bulleted list">
      <div class="item">
        Forgetting to start/end a variable with a curly bracket - For example
        writing <code>{totalFat</code> instead of <code>{totalFat}</code>
      </div>
      <div class="item">
        Forgetting that variables are case sensitive - For example writing
        <code>{Calories}</code> instead of <code>{calories}</code>
      </div>
    </div>

    <p>
      After making changes to a template it is recommended to export a couple of
      projects since any errors that occur will be caught there.
    </p>
  </div>
</template>
