<template>
  <div class="ui stackable grid">
    <div class="row">
      <div class="seven wide column">
        <RecipeSection />
      </div>

      <div class="nine wide column">
        <NFP :max-height="25" />
        <IngredientStatement :max-height="25" />
      </div>
    </div>
  </div>
</template>

<script>
import RecipeSection from '@/components/project/builder/eu/RecipeSection';
import NFP from '@/components/project/builder/eu/NFP';
import IngredientStatement from '@/components/project/builder/IngredientStatement';

export default {
  components: {
    RecipeSection,
    NFP,
    IngredientStatement,
  },
};
</script>
