import request from '../../../request';
import router from '../../../router';

export default {
  /**
   * Activate user account
   */
  activateAccount: async (context, payload) => {
    try {
      const response = await request.sendRequest(context, {
        method: 'POST',
        url: '/api/account/activate',
        auth: payload,
      });
      await router.replace({
        name: 'Login',
        query: { username: response.data.username },
      });
      context.commit(
        'showAlert',
        {
          type: 'success',
          header: `Welcome ${response.data.fullName} !`,
          message: 'Account activated',
        },
        { root: true },
      );
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Update user password
   */
  updatePassword: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'POST',
        url: '/api/account/password',
        data: payload,
      });
      context.commit(
        'showAlert',
        {
          type: 'success',
          header: 'Password Updated!',
        },
        { root: true },
      );
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Get organization info
   */
  getOrganizationInfo: async context => {
    try {
      const response = await request.sendRequest(context, {
        type: 'GET',
        url: '/api/account/organization',
      });
      return response.data.organization;
    } catch (err) {
      console.error(err);
    }
  },
};
