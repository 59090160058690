import request from '../../../request';

export default {
  /**
   * Get list of all users for organization
   */
  getAllUsers: async context => {
    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/account/users',
      });
      context.state.allUsers = response.data.users;
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Get currently active sessions
   */
  getActiveSessions: async context => {
    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/admin/sessions',
      });
      context.state.activeSessions = response.data.activeSessions;
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Delete a user
   * @param {object} context
   * @param {object} payload User id
   */
  deleteUser: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'DELETE',
        url: `/api/account/user/${payload._id}`,
      });
    } catch (err) {
      console.error(err);
    } finally {
      context.dispatch('getAllUsers');
    }
  },

  /**
   * Edit user details
   * @param {object} context
   * @param {object} payload Contains user id, new status and new clearanceLevel
   */
  editUser: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'PUT',
        url: `/api/account/user/${payload.id}`,
        data: {
          clearanceLevel: payload.clearanceLevel,
          status: payload.status,
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      context.dispatch('getAllUsers');
    }
  },

  /**
   * Delete session in DB
   * @param {object} context
   * @param {object} payload Contains user id
   */
  closeSession: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'DELETE',
        url: `/api/admin/session/${payload.id}`,
      });
    } catch (err) {
      console.error(err);
    } finally {
      context.dispatch('getActiveSessions');
    }
  },

  /**
   * Send user invite email
   * @param {object} context
   * @param {object} payload Contains user fullName, username, email, clearanceLevel, inviteType
   */
  sendInviteEmail: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'POST',
        url: '/api/admin/invite',
        data: payload,
      });
      context.commit(
        'showAlert',
        {
          type: 'success',
          header: 'Success',
          message: `Invite email sent to ${payload.email}`,
          timeout: 5000,
        },
        { root: true },
      );
    } catch (err) {
      console.error(err);
    } finally {
      context.dispatch('getAllUsers');
      context.commit('closeModal');
    }
  },

  /**
   * Generate user invite link
   * @param {object} context
   * @param {object} payload Contains user fullName, username, email, clearanceLevel, inviteType
   */
  generateInviteLink: async (context, payload) => {
    try {
      const response = await request.sendRequest(context, {
        method: 'POST',
        url: '/api/admin/invite',
        data: payload,
      });
      context.state.inviteLink = response.data.inviteLink;
      context.dispatch('getAllUsers');
    } catch (err) {
      console.error(err);
      context.commit('closeModal');
    }
  },

  /**
   * Send password reset email
   * @param {object} context
   * @param {object} payload Contains user id
   */
  sendPasswordReset: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'POST',
        url: '/api/admin/password-reset',
        data: payload,
      });
      context.commit(
        'showAlert',
        {
          type: 'success',
          header: 'Email sent',
          message: 'Password reset email sent',
        },
        { root: true },
      );
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Get JSON of all ingredients
   */
  downloadAllIngredients: async context => {
    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/admin/export/ingredients',
      });
      return response.data.ingredients;
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Get JSON of all projects
   */
  downloadAllProjects: async context => {
    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/admin/export/projects',
      });
      return response.data.projects;
    } catch (err) {
      console.error(err);
    }
  },
};
