<template>
  <h4 @click="logState">Meal Replacement</h4>

  <table
    class="ui very compact mini unstackable striped celled definition table"
  >
    <thead>
      <tr>
        <th></th>
        <th>Per Serving</th>
        <th>Min</th>
        <th>Max</th>
        <th>Compliance</th>
        <th>Claim only vit/min blend</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="nutrient in regionSettings.mealReplacement"
        :key="nutrient.name"
      >
        <td
          :data-tooltip="
            nutrient.calculation[
              mealReplacementSources.find(e => e.name == nutrient.name).source
            ].join('')
          "
          data-position="top left"
        >
          {{ nutrient.name }}
        </td>
        <td>
          <b>
            {{ Math.round(checkMR(nutrient).value * 1000) / 1000 }}
            {{ nutrient.unit }}
          </b>
        </td>
        <td>
          {{ nutrient.min == null ? '-' : `${nutrient.min} ${nutrient.unit}` }}
        </td>
        <td>
          {{ nutrient.max == null ? '-' : `${nutrient.max} ${nutrient.unit}` }}
        </td>
        <td v-if="checkMR(nutrient).status" class="positive">
          <i class="icon checkmark"></i>
          Good
        </td>
        <td v-else class="negative">
          <i class="icon close"></i>
          {{ checkMR(nutrient).message }}
        </td>
        <td>
          <div class="ui slider fitted checkbox">
            <input
              type="checkbox"
              :disabled="
                regionSettings.mealReplacement.find(
                  e => e.name == nutrient.name,
                ).calculation.vitaminBlend.length == 0 || isViewOnly
              "
              :checked="
                mealReplacementSources.find(e => e.name == nutrient.name)
                  .source == 'vitaminBlend' &&
                regionSettings.mealReplacement.find(
                  e => e.name == nutrient.name,
                ).calculation.vitaminBlend
              "
              @change="toggleMealReplacementSource({ name: nutrient.name })"
            />
            <label></label>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { arrayCalculator } from '../../../../shunting';
export default {
  computed: {
    ...mapState('project', ['regionSettings', 'mealReplacementSources']),
    ...mapGetters('project', ['isViewOnly']),
  },
  methods: {
    ...mapMutations('project', ['toggleMealReplacementSource']),
    checkMR(nutrient) {
      if (!this.mealReplacementSources.find(e => e.name == nutrient.name))
        return { status: false, message: 'Error', value: '' };

      const source = this.mealReplacementSources.find(
        e => e.name == nutrient.name,
      ).source;

      // Build
      let calculation = nutrient.calculation[source];
      if (!calculation) calculation = nutrient.calculation.recipe;

      let expression = [];
      for (let i = 0; i < calculation.length; i++) {
        const e = calculation[i];

        if (
          e == '+' ||
          e == '-' ||
          e == '*' ||
          e == '/' ||
          e == '(' ||
          e == ')'
        )
          expression.push(e);
        else if (!isNaN(parseFloat(e))) expression.push(parseFloat(e));
        else {
          const value = this.getNutrientValue(e, source);
          if (value == null)
            return { status: false, message: 'N/A', value: '' };
          else expression.push(value);
        }
      }

      // Calcuate
      let value;
      try {
        value = arrayCalculator(expression);
      } catch (err) {
        return { status: false, message: 'Invalid Expression', value: '' };
      }

      // Check validity
      if (
        (value >= nutrient.min || nutrient.min == null) &&
        (value <= nutrient.max || nutrient.max == null)
      )
        return { status: true, value };
      if (value < nutrient.min)
        return { status: false, message: 'Too low', value };
      if (value > nutrient.max)
        return { status: false, message: 'Too high', value };
      return { status: false, message: 'N/A', value: '' };
    },
    getNutrientValue(name, source) {
      let val;
      if (source == 'recipe')
        val = this.$store.state.project.nutrients.find(e => e.name == name);
      else if (
        source == 'vitaminBlend' &&
        this.$store.state.project.vitaminBlend
      )
        val = this.$store.state.project.vitaminBlend.find(e => e.name == name);
      return !val ? null : val.qty;
    },
    logState() {
      console.info(this.$store.state.project);
    },
  },
};
</script>

<style scoped>
table {
  margin-bottom: 1rem !important;
}
</style>
