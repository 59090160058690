<template>
  <CenterContainer>
    <BuilderToolbar />
    <component :is="country" />
  </CenterContainer>
</template>

<script>
import { mapState } from 'vuex';
import BuilderToolbar from '@/components/project/builder/BuilderToolbar';
import ca from '@/components/project/builder/ca/BuilderInterface';
import eu from '@/components/project/builder/eu/BuilderInterface';
import us from '@/components/project/builder/us/BuilderInterface';

export default {
  components: {
    BuilderToolbar,
    ca,
    eu,
    us,
  },
  computed: {
    ...mapState('project', ['country']),
  },
};
</script>
