<template>
  <BaseModal :show="openModal == 'editDv'" @deny="closeModal">
    <div class="ui vertical segment title">
      <h3>Edit Daily Value</h3>
    </div>
    <div class="ui vertical segment">
      <div class="content">
        <h4 class="ui header">
          Enter a new Daily Value for {{ activeNutrient }}
        </h4>
        <div class="ui icon input fluid" :class="{ error: !validValue }">
          <input
            v-model.trim="value"
            type="text"
            placeholder="Enter value..."
            @keydown="validValue = true"
            @keyup.enter="!isLoading ? updateDv() : ''"
          />
        </div>
        <small>To completely remove Daily Value enter <code>0</code></small>
      </div>
    </div>
    <div class="ui vertical segment">
      <div class="actions">
        <div class="ui black deny button" @click="closeModal">Cancel</div>
        <div
          class="ui green button"
          :class="{ 'disabled loading': isLoading }"
          @click="updateDv"
        >
          Edit
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      value: '',
      validValue: true,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('rules', ['activeNutrient', 'openModal']),
  },
  methods: {
    ...mapMutations('rules', ['closeModal']),
    async updateDv() {
      if (
        isNaN(parseFloat(this.value)) ||
        (this.value.split('.').length != 1 &&
          this.value.split('.')[1].length > 4) ||
        parseFloat(this.value) < 0 ||
        parseFloat(this.value) > 10000
      )
        return (this.validValue = false);

      this.isLoading = true;
      await this.$store.dispatch('rules/updateDv', {
        value: parseFloat(this.value),
      });
      this.closeModal();
      this.value = '';
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
small {
  color: rgba(128, 128, 128, 0.8);
}
</style>
