<template>
  <div class="card">
    <div class="content">
      <div class="header">{{ header }}</div>
      <div class="description">
        {{ description }}
      </div>
    </div>
    <div
      class="ui bottom attached button"
      :class="{ disabled }"
      @click="$emit('open')"
    >
      <i class="add icon"></i>
      Open
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    disabled: Boolean,
  },
  emits: ['open'],
};
</script>
