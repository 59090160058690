<template>
  <h4>Aminogram</h4>
  <table class="ui small compact table">
    <thead>
      <tr>
        <th>Essential Amino Acid Profile</th>
        <th>g/100g of protein</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>L-isoleucine</td>
        <td>
          <div class="ui transparent input">
            <input
              :value="aminogram.lIsoleucine"
              :disabled="isViewOnly"
              min="0"
              type="number"
              placeholder="Enter quantity"
              @keyup="
                isValidNumber($event.target.value)
                  ? setAminoAcid({
                      field: 'lIsoleucine',
                      value: $event.target.value,
                    })
                  : ''
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>L-leucine</td>
        <td>
          <div class="ui transparent input">
            <input
              :value="aminogram.lLeucine"
              :disabled="isViewOnly"
              min="0"
              type="number"
              placeholder="Enter quantity"
              @keyup="
                isValidNumber($event.target.value)
                  ? setAminoAcid({
                      field: 'lLeucine',
                      value: $event.target.value,
                    })
                  : ''
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>L-lysine</td>
        <td>
          <div class="ui transparent input">
            <input
              :value="aminogram.lLysine"
              :disabled="isViewOnly"
              min="0"
              type="number"
              placeholder="Enter quantity"
              @keyup="
                isValidNumber($event.target.value)
                  ? setAminoAcid({
                      field: 'lLysine',
                      value: $event.target.value,
                    })
                  : ''
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>L-méthionine + L-cystine</td>
        <td>
          <div class="ui transparent input">
            <input
              :value="aminogram.lMethionineLCystine"
              :disabled="isViewOnly"
              min="0"
              type="number"
              placeholder="Enter quantity"
              @keyup="
                isValidNumber($event.target.value)
                  ? setAminoAcid({
                      field: 'lMethionineLCystine',
                      value: $event.target.value,
                    })
                  : ''
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>L-phénylalanine + L-tyrosine</td>
        <td>
          <div class="ui transparent input">
            <input
              :value="aminogram.lPhenylalanineLTyrosine"
              :disabled="isViewOnly"
              min="0"
              type="number"
              placeholder="Enter quantity"
              @keyup="
                isValidNumber($event.target.value)
                  ? setAminoAcid({
                      field: 'lPhenylalanineLTyrosine',
                      value: $event.target.value,
                    })
                  : ''
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>L-thréonine</td>
        <td>
          <div class="ui transparent input">
            <input
              :value="aminogram.lThreonine"
              :disabled="isViewOnly"
              min="0"
              type="number"
              placeholder="Enter quantity"
              @keyup="
                isValidNumber($event.target.value)
                  ? setAminoAcid({
                      field: 'lThreonine',
                      value: $event.target.value,
                    })
                  : ''
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>L-tryptophane</td>
        <td>
          <div class="ui transparent input">
            <input
              :value="aminogram.lTryptophane"
              :disabled="isViewOnly"
              min="0"
              type="number"
              placeholder="Enter quantity"
              @keyup="
                isValidNumber($event.target.value)
                  ? setAminoAcid({
                      field: 'lTryptophane',
                      value: $event.target.value,
                    })
                  : ''
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>L-valine</td>
        <td>
          <div class="ui transparent input">
            <input
              :value="aminogram.lValine"
              :disabled="isViewOnly"
              min="0"
              type="number"
              placeholder="Enter quantity"
              @keyup="
                isValidNumber($event.target.value)
                  ? setAminoAcid({
                      field: 'lValine',
                      value: $event.target.value,
                    })
                  : ''
              "
            />
          </div>
        </td>
      </tr>
      <tr>
        <td>Histidine</td>
        <td>
          <div class="ui transparent input">
            <input
              :value="aminogram.histidine"
              :disabled="isViewOnly"
              min="0"
              type="number"
              placeholder="Enter quantity"
              @keyup="
                isValidNumber($event.target.value)
                  ? setAminoAcid({
                      field: 'histidine',
                      value: $event.target.value,
                    })
                  : ''
              "
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapState('project', ['aminogram']),
    ...mapGetters('project', ['isViewOnly']),
  },
  methods: {
    ...mapMutations('project', ['setAminoAcid']),
    isValidNumber(num) {
      return (
        !isNaN(num) &&
        num >= 0 &&
        (('' + num).split('.').length == 1 ||
          ('' + num).split('.')[1].length <= 4)
      );
    },
  },
};
</script>
