<template>
  <div class="ui sub header">Food Category</div>
  <select
    class="ui fluid search dropdown"
    :class="{ disabled: isViewOnly }"
    :value="foodCategory.en"
    @change="setFoodCategory($event.target)"
  >
    <option disabled value="">Please select one</option>
    <option
      v-for="item in regionSettings.foodCategories"
      :key="item.en"
      :value="item.en"
    >
      {{ item.categoryNumber }} | {{ item.fr }} | {{ item.en }}
    </option>
  </select>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapState('project', ['regionSettings', 'foodCategory']),
    ...mapGetters('project', ['isViewOnly']),
  },
  mounted() {
    window.$('.ui.dropdown').dropdown();
  },
  methods: {
    ...mapMutations('project', ['setFoodCategory']),
  },
};
</script>