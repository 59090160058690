<template>
  <div>
    <TopHeader header-title="Admin Tools" show-user-info></TopHeader>
    <CenterContainer>
      <div class="ui stackable grid">
        <div class="ten wide column">
          <BackupSegment />
        </div>
        <div class="six wide column">
          <div class="ui segment">
            <h3>Tools</h3>
            <p>Miscellaneous admin tools</p>
            <button
              class="ui basic compact button"
              @click="openNewModal({ name: 'inviteUser' })"
            >
              <i class="envelope icon"></i>
              Invite User
            </button>
            <InviteUser />
          </div>
        </div>
      </div>

      <div class="ui stackable grid">
        <div class="ten wide column">
          <ListUsers />
        </div>
        <div class="six wide column">
          <ActiveSessions />
        </div>
      </div>
    </CenterContainer>

    <EditUser
      v-if="openModal == 'editUser'"
      :show="openModal == 'editUser'"
      :user="userToModify"
    ></EditUser>
    <DeleteUser
      v-if="openModal == 'deleteUser'"
      :show="openModal == 'deleteUser'"
      :user="userToModify"
    ></DeleteUser>
    <ResetUserPassword
      v-if="openModal == 'resetPassword'"
      :show="openModal == 'resetPassword'"
      :user="userToModify"
    />
  </div>
</template>

<script>
import ListUsers from '@/components/admin/ListUsers';
import EditUser from '@/components/admin/EditUser';
import DeleteUser from '@/components/admin/DeleteUser';
import ResetUserPassword from '@/components/admin/ResetUserPassword';
import InviteUser from '@/components/admin/InviteUser';
import BackupSegment from '@/components/admin/BackupSegment';
import ActiveSessions from '@/components/admin/ActiveSessions';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  components: {
    ListUsers,
    EditUser,
    DeleteUser,
    ResetUserPassword,
    InviteUser,
    BackupSegment,
    ActiveSessions,
  },
  computed: {
    ...mapState('admin', ['allUsers', 'openModal', 'userToModify']),
  },
  methods: {
    ...mapMutations('admin', ['closeModal', 'openNewModal']),
    ...mapActions('admin', ['getAllUsers']),
  },
};
</script>

<style scoped>
.sortable.table {
  margin-top: 1rem;
}
p {
  color: #878787;
}
</style>
