import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: () => {
    return {
      searchResults: [],

      ingredientModalOpen: false,
      deleteModalOpen: false,

      isLoadingResults: false,
      loadingIngredientCode: null,
      ingredientToDelete: null,

      ingredient: {
        components: [],
        code: '',
        originalCode: null,
        editMode: false,
        allergens: [],
      },
    };
  },
  mutations,
  getters,
  actions,
};
