export default {
  /**
   * Get logged in user data
   */
  activeUser: state => {
    return state.user;
  },

  /**
   * Return clearance level name
   * @param {object} user User object
   * @returns {string}
   */
  clearanceLevelName: () => user => {
    if (user.clearanceLevel == 1) return 'User';
    if (user.clearanceLevel == 2) return 'Editor';
    if (user.clearanceLevel == 3) return 'Admin';
    if (user.clearanceLevel == 4) return 'Super Admin';
    else return 'Unknown';
  },

  /**
   * Check if user has clearance to level 2
   * @returns {boolean}
   */
  isEditor: state => {
    if (state.user && state.user.clearanceLevel >= 2) return true;
    else return false;
  },

  /**
   * Check if user has clearance to level 3
   * @returns {boolean}
   */
  isAdmin: state => {
    if (state.user && state.user.clearanceLevel >= 3) return true;
    else return false;
  },
};
