<template>
  <div class="ui form">
    <StatementOfIdentity />

    <hr />
    <Allergens />

    <hr />
    <FoodCategory />
    <ProductionOrigin class="mt-1" />

    <div class="three fields">
      <div class="field" :class="{ error: !isValidServingSize }">
        <label>Serving Size</label>
        <div class="ui right labeled input">
          <input
            v-model.number="servingSize"
            type="text"
            maxlength="10"
            autocomplete="off"
            placeholder="ex: 31"
            :disabled="isViewOnly"
            @keypress="isAllowedNumberChar($event)"
            @keyup="setServingSize"
          />
          <div class="ui basic label">g</div>
        </div>
      </div>

      <div class="field">
        <label>Serving Size Equivalent</label>
        <div class="ui input">
          <input
            :value="servingIdentifier"
            type="text"
            maxlength="30"
            autocomplete="off"
            placeholder="ex: 1,5 dose"
            :disabled="isViewOnly"
            @keypress="isAllowedTextChar($event)"
            @keyup="setServingIdentifier($event.target)"
          />
        </div>
      </div>

      <div class="field">
        <label>HS Code</label>
        <div class="ui input">
          <input
            :value="hsCode"
            type="text"
            maxlength="20"
            autocomplete="off"
            placeholder="ex: 18 06 90 70 10"
            :disabled="isViewOnly"
            @keypress="isAllowedTextChar($event)"
            @keyup="setHsCode($event.target)"
          />
        </div>
      </div>
    </div>

    <div class="three fields">
      <div class="field">
        <label>Score Europe</label>
        <div class="ui input">
          <input
            :value="scoreEurope"
            type="text"
            maxlength="20"
            autocomplete="off"
            placeholder="ex: 131"
            :disabled="isViewOnly"
            @keypress="isAllowedNumberChar($event)"
            @keyup="setScoreEurope($event.target)"
          />
        </div>
      </div>
      <div class="field">
        <label>Packaging</label>
        <div class="ui input">
          <input
            :value="packaging"
            type="text"
            maxlength="20"
            autocomplete="off"
            placeholder="ex: 24"
            :disabled="isViewOnly"
            @keypress="isAllowedNumberChar($event)"
            @keyup="setPackaging($event.target)"
          />
        </div>
      </div>
      <div class="field">
        <label>Sample Code</label>
        <div class="ui input">
          <input
            :value="sampleCode"
            type="text"
            maxlength="20"
            autocomplete="off"
            placeholder="ex: P830E02"
            :disabled="isViewOnly"
            @keypress="isAllowedTextChar($event)"
            @keyup="setSampleCode($event.target)"
          />
        </div>
      </div>
    </div>

    <hr />
    <div class="field">
      <label>Sugar Alcohols (g/100g)</label>
      <div class="ui input">
        <input
          v-model.number="sugarAlcohols"
          type="text"
          maxlength="10"
          autocomplete="off"
          placeholder="Enter quantity"
          :disabled="isViewOnly"
          @keypress="isAllowedNumberChar($event)"
          @keyup="setSugarAlcohols"
        />
      </div>
    </div>

    <hr />
    <SpecificStatus />

    <hr />
    <h4>Date of minimum durability and storage</h4>
    <div class="two fields">
      <div class="field">
        <label>Shelf Life</label>
        <div
          class="ui right labeled input"
          :class="{ error: !isValidShelfLife }"
        >
          <input
            v-model.number="shelfLife"
            type="text"
            maxlength="5"
            autocomplete="off"
            placeholder="ex: 24"
            :disabled="isViewOnly"
            @keypress="isAllowedNumberChar($event)"
            @keyup="setShelfLife"
          />
          <div class="ui basic label">months</div>
        </div>
      </div>
      <div class="field">
        <label>Packaged in a protective atmosphere</label>
        <div class="ui toggle checkbox">
          <input
            type="checkbox"
            :disabled="isViewOnly"
            :checked="packagedInProtectiveAtmosphere"
            @change="togglePackagedInProtectiveAtmosphere()"
          />
          <label></label>
        </div>
      </div>
    </div>

    <hr />
    <MicrobiologicalCriteria />

    <hr />
    <Aminogram />
  </div>
</template>

<script>
import StatementOfIdentity from '@/components/project/builder/eu/details/StatementOfIdentity';
import Allergens from '@/components/project/builder/eu/details/Allergens';
import FoodCategory from '@/components/project/builder/eu/details/FoodCategory';
import ProductionOrigin from '@/components/project/builder/eu/details/ProductionOrigin';
import SpecificStatus from '@/components/project/builder/eu/details/SpecificStatus';
import MicrobiologicalCriteria from '@/components/project/builder/eu/details/MicrobiologicalCriteria';
import Aminogram from '@/components/project/builder/eu/details/Aminogram';
import { mapMutations, mapGetters, mapState } from 'vuex';
export default {
  components: {
    StatementOfIdentity,
    Allergens,
    FoodCategory,
    ProductionOrigin,
    SpecificStatus,
    MicrobiologicalCriteria,
    Aminogram,
  },
  data() {
    return {
      servingSize: this.$store.state.project.servingSize,
      shelfLife: this.$store.state.project.shelfLife,

      sugarAlcohols: this.$store.state.project.sugarAlcohols,

      isValidServingSize: true,
      isValidShelfLife: true,
    };
  },
  computed: {
    ...mapState('project', [
      'servingIdentifier',
      'hsCode',
      'scoreEurope',
      'packaging',
      'sampleCode',
      'packagedInProtectiveAtmosphere',
    ]),
    ...mapGetters('project', ['isViewOnly']),
  },
  methods: {
    ...mapMutations('project', [
      'setServingIdentifier',
      'setHsCode',
      'setScoreEurope',
      'setPackaging',
      'setSampleCode',
      'togglePackagedInProtectiveAtmosphere',
    ]),
    isAllowedNumberChar(event) {
      if (/^[0-9.]+$/.test(event.key)) return true;
      else event.preventDefault();
    },
    isAllowedTextChar(event) {
      if (/^[a-zA-Z0-9À-ÖØ-öø-ÿ ().,-]+$/.test(event.key)) return true;
      else event.preventDefault();
    },

    setServingSize() {
      const value = parseFloat(this.servingSize);

      // Validation
      if (this.isValidNumber(value)) {
        this.isValidServingSize = true;
        this.$store.commit('project/setServingSize', { value });
      } else this.isValidServingSize = false;
    },

    setShelfLife() {
      const value = parseFloat(this.shelfLife);

      // Validation
      if (this.isValidNumber(value)) {
        this.isValidShelfLife = true;
        this.$store.commit('project/setShelfLife', { value });
      } else this.isValidShelfLife = false;
    },

    setSugarAlcohols() {
      const value = parseFloat(this.sugarAlcohols);

      // Validation
      if (
        !isNaN(value) &&
        value >= 0 &&
        (('' + value).split('.').length == 1 ||
          ('' + value).split('.')[1].length <= 4)
      )
        this.$store.commit('project/setSugarAlcohols', { value });
    },

    /**
     * Helper function
     */
    isValidNumber(num) {
      return (
        !isNaN(num) &&
        num > 0 &&
        (('' + num).split('.').length == 1 ||
          ('' + num).split('.')[1].length <= 4)
      );
    },
  },
};
</script>

<style scoped>
hr {
  border: 1px solid rgba(173, 173, 173, 0.25);
}
</style>