<template>
  <div class="card">
    <div class="content">
      <i class="icon right floated big" :class="icon"></i>
      <div class="header">{{ header }}</div>
      <div class="meta">{{ subheader }}</div>
    </div>
    <div class="extra content">
      <div class="ui two buttons">
        <a
          class="ui basic blue button"
          :class="{ 'disabled loading': isDownloading, disabled }"
          @click="download"
          >Download</a
        >
        <label
          class="ui basic orange button"
          :class="{ 'disabled loading': isUploading, disabled }"
        >
          <div>Upload New</div>
          <input :disabled="isUploading" type="file" @input="upload" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: '' },
    header: { type: String, required: true },
    subheader: { type: String, default: '' },
    country: { type: String, required: true },
    name: { type: String, required: true },
    disabled: Boolean,
  },
  data() {
    return {
      isUploading: false,
      isDownloading: false,
    };
  },
  methods: {
    async upload(e) {
      this.isUploading = true;
      await this.$store.dispatch('templates/uploadTemplate', {
        template: e.target.files[0],
        country: this.country,
        name: this.name,
      });
      this.isUploading = false;
    },
    async download() {
      this.isDownloading = true;
      await this.$store.dispatch('templates/downloadTemplate', {
        country: this.country,
        name: this.name,
      });
      this.isDownloading = false;
    },
  },
};
</script>

<style scoped>
input[type='file'] {
  display: none;
}
</style>
