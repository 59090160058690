<template>
  <BaseModal :show="ingredientModalOpen" @deny="closeModal">
    <div class="ui vertical segment title">
      <h3>Add Ingredient</h3>
    </div>

    <div class="ui top attached tabular menu">
      <a class="item" :class="{ active: openTab == 0 }" @click="openTab = 0">
        Components
      </a>
      <a class="item" :class="{ active: openTab == 1 }" @click="openTab = 1">
        Allergens
      </a>
    </div>

    <div
      class="ui bottom attached tab segment"
      :class="{ active: openTab == 0 }"
    >
      <div class="ui vertical segment">
        <div class="ui stackable grid">
          <div class="five wide column">
            <div class="ui segment">
              <div class="ui form">
                <div class="field">
                  <label>US English Name</label>
                  <div class="ui search">
                    <div class="ui input">
                      <input
                        v-model.trim="usEnName"
                        type="text"
                        placeholder="ex: sugar..."
                        @keydown="validUsEnName = true"
                        @keypress="isAllowedComponentChar($event)"
                        @keyup="search('usEnName')"
                        @blur="timeoutClear('usEnNameResults')"
                        @change="cleanComponentString($event, 'usEnName')"
                      />
                    </div>
                    <div
                      v-if="usEnNameResults.length > 0"
                      class="results transition visible"
                    >
                      <a
                        v-for="result in usEnNameResults"
                        :key="result.title"
                        class="result"
                        @click="usEnName = result.title"
                      >
                        <div class="content">
                          <div class="title">
                            {{ result.title }}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <label>Canada English Name</label>
                  <div class="ui search">
                    <div class="ui input">
                      <input
                        v-model.trim="caEnName"
                        type="text"
                        placeholder="ex: sugar..."
                        @keydown="validCaEnName = true"
                        @keypress="isAllowedComponentChar($event)"
                        @keyup="search('caEnName')"
                        @blur="timeoutClear('caEnNameResults')"
                        @change="cleanComponentString($event, 'caEnName')"
                      />
                    </div>
                    <div
                      v-if="caEnNameResults.length > 0"
                      class="results transition visible"
                    >
                      <a
                        v-for="result in caEnNameResults"
                        :key="result.title"
                        class="result"
                        @click="caEnName = result.title"
                      >
                        <div class="content">
                          <div class="title">
                            {{ result.title }}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <label>Canada French Name</label>
                  <div class="ui ca-fr search">
                    <div class="ui input">
                      <input
                        v-model.trim="caFrName"
                        type="text"
                        placeholder="ex: sucre..."
                        @keydown="validCaFrName = true"
                        @keypress="isAllowedComponentChar($event)"
                        @keyup="search('caFrName')"
                        @blur="timeoutClear('caFrNameResults')"
                        @change="cleanComponentString($event, 'caFrName')"
                      />
                    </div>
                    <div
                      v-if="caFrNameResults.length > 0"
                      class="results transition visible"
                    >
                      <a
                        v-for="result in caFrNameResults"
                        :key="result.title"
                        class="result"
                        @click="caFrName = result.title"
                      >
                        <div class="content">
                          <div class="title">
                            {{ result.title }}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <label>Europe English Name</label>
                  <div class="ui eu-en search">
                    <div class="ui input">
                      <input
                        v-model.trim="euEnName"
                        placeholder="ex: sugar"
                        type="text"
                        @keydown="validEuEnName = true"
                        @keypress="isAllowedComponentChar($event)"
                        @keyup="search('euEnName')"
                        @blur="timeoutClear('euEnNameResults')"
                        @change="cleanComponentString($event, 'euEnName')"
                      />
                    </div>
                    <div
                      v-if="euEnNameResults.length > 0"
                      class="results transition visible"
                    >
                      <a
                        v-for="result in euEnNameResults"
                        :key="result.title"
                        class="result"
                        @click="euEnName = result.title"
                      >
                        <div class="content">
                          <div class="title">
                            {{ result.title }}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <label>Europe French Name</label>
                  <div class="ui eu-fr search">
                    <div class="ui input">
                      <input
                        v-model.trim="euFrName"
                        type="text"
                        placeholder="ex: sucre..."
                        @keydown="validEuFrName = true"
                        @keypress="isAllowedComponentChar($event)"
                        @keyup="search('euFrName')"
                        @blur="timeoutClear('euFrNameResults')"
                        @change="cleanComponentString($event, 'euFrName')"
                      />
                    </div>
                    <div
                      v-if="euFrNameResults.length > 0"
                      class="results transition visible"
                    >
                      <a
                        v-for="result in euFrNameResults"
                        :key="result.title"
                        class="result"
                        @click="euFrName = result.title"
                      >
                        <div class="content">
                          <div class="title">
                            {{ result.title }}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="required field" :class="{ error: !validQty }">
                  <label>Enter percent weight</label>
                  <div class="ui right labeled input">
                    <input
                      v-model="qty"
                      type="text"
                      placeholder="Enter percentage..."
                      @keyup="validQty = true"
                      @keyup.enter="addComponent"
                    />
                    <div class="ui basic label">%</div>
                  </div>
                </div>
                <div
                  v-if="errorMessage.length > 0"
                  class="ui visible error message"
                >
                  {{ errorMessage }}
                </div>
                <button
                  class="ui inverted secondary button"
                  @click="addComponent"
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <div class="eleven wide column">
            <div class="ui components segment">
              <table class="ui compact new-ingredient basic table">
                <thead>
                  <tr>
                    <th>US EN Name</th>
                    <th>CA EN Name</th>
                    <th>CA FR Name</th>
                    <th>EU EN Name</th>
                    <th>EU FR Name</th>
                    <th>Qty</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="component in ingredient.components"
                    :key="component.cid"
                  >
                    <td>{{ component.usEnName }}</td>
                    <td>{{ component.caEnName }}</td>
                    <td>{{ component.caFrName }}</td>
                    <td>{{ component.euEnName }}</td>
                    <td>{{ component.euFrName }}</td>
                    <td>
                      {{
                        Math.round(component.qty * 100 * 10000000) / 10000000
                      }}%
                    </td>
                    <td>
                      <i
                        class="circular small pencil link icon"
                        @click="editComponent(component.cid)"
                      ></i>
                      <i
                        class="circular small close link icon"
                        @click="removeComponent({ cid: component.cid })"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="ui bottom attached tab segment"
      :class="{ active: openTab == 1 }"
    >
      <SpinLoader v-if="allergenList.length == 0" />
      <div v-else class="ui form">
        <div class="grouped fields">
          <label>This Ingredient <u>Contains</u>:</label>
          <div v-for="allergen in allergenList" :key="allergen" class="field">
            <div class="ui checkbox">
              <input
                type="checkbox"
                :checked="ingredient.allergens.includes(allergen)"
                :value="allergen"
                @change="toggleAllergen($event.target)"
              />
              <label>{{ allergen }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui vertical segment actions">
      <div class="ui label mr-1">
        Total QTY:
        <div class="detail">{{ ingredientTotal }}%</div>
      </div>
      <div
        class="ui small labeled input mr-1"
        :class="{ fluid: windowWidth < 900 }"
      >
        <div class="ui label">Ingredient Code</div>
        <input
          :value="ingredient.code"
          type="text"
          placeholder="RE12345"
          @keyup.enter="hasMinRequirements ? pushIngredient() : ''"
          @keypress="isAllowedIngredientChar($event)"
          @input="updateCode"
          @change="cleanIngredientString($event)"
        />
      </div>
      <div
        class="ui black small deny button mr-1"
        :class="{ disabled: isPushingIngredient, fluid: windowWidth < 900 }"
        @click="closeModal"
      >
        Close
      </div>
      <button
        class="ui green right small labeled icon button"
        :class="{
          disabled: !hasMinRequirements,
          loading: isPushingIngredient,
          fluid: windowWidth < 900,
        }"
        @click="pushIngredient"
      >
        Done
        <i class="checkmark icon"></i>
      </button>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      usEnName: '',
      caEnName: '',
      caFrName: '',
      euEnName: '',
      euFrName: '',
      qty: '',

      validQty: true,
      errorMessage: '',

      usEnNameResults: [],
      caEnNameResults: [],
      caFrNameResults: [],
      euEnNameResults: [],
      euFrNameResults: [],

      isPushingIngredient: false,

      allergenList: [],
      openTab: 0,
    };
  },
  computed: {
    ...mapState('ingredient', ['ingredientModalOpen', 'ingredient']),
    ...mapState(['windowWidth']),
    ...mapGetters('ingredient', ['ingredientTotal']),
    hasMinRequirements() {
      return (
        this.ingredientTotal == 100 &&
        this.ingredient.code.length != 0 &&
        !this.isPushingIngredient
      );
    },
  },
  async mounted() {
    const list = await this.$store.dispatch('ingredient/getAllergenList');
    this.allergenList = list.list;
  },
  methods: {
    ...mapMutations('ingredient', [
      'closeModal',
      'removeComponent',
      'toggleAllergen',
    ]),
    ...mapActions('ingredient', ['newIngredient']),
    updateCode(e) {
      this.$store.commit('ingredient/updateIngredientCode', {
        update: e.target.value.toUpperCase().trim(),
      });
    },
    addComponent() {
      // Check qty
      if (
        this.qty &&
        this.qty.length > 0 &&
        !isNaN(parseFloat(this.qty)) &&
        (this.qty.split('.').length == 1 ||
          this.qty.split('.')[1].length <= 4) &&
        parseFloat(this.qty) > 0 &&
        parseFloat(this.qty) <= 100
      ) {
        this.validQty = true;
      } else this.validQty = false;

      // Check if qty will go over 100%
      if (parseFloat(this.qty) + this.ingredientTotal > 100) {
        this.errorMessage = 'Total amount will go over 100%';
        return;
      }
      if (!this.validQty) return;

      // Check if min 1 field is filled
      const minOneField =
        this.usEnName.length > 0 ||
        this.caEnName.length > 0 ||
        this.caFrName.length > 0 ||
        this.euEnName.length > 0 ||
        this.euFrName.length > 0;

      // Tell user min 1 field
      if (!minOneField) {
        this.errorMessage = 'At least 1 field must be filled';
        return;
      }

      // If all valid commit
      this.$store.commit('ingredient/addComponent', {
        usEnName: this.usEnName.length == 0 ? 'N/A' : this.usEnName,
        caEnName: this.caEnName.length == 0 ? 'N/A' : this.caEnName,
        caFrName: this.caFrName.length == 0 ? 'N/A' : this.caFrName,
        euEnName: this.euEnName.length == 0 ? 'N/A' : this.euEnName,
        euFrName: this.euFrName.length == 0 ? 'N/A' : this.euFrName,
        qty: parseFloat(this.qty) / 100,
      });

      // Clear fields
      this.usEnName = '';
      this.caEnName = '';
      this.caFrName = '';
      this.euEnName = '';
      this.euFrName = '';
      this.qty = '';
      this.errorMessage = '';
    },
    async pushIngredient() {
      // Check if allergens list is empty and ask for confirmation
      if (
        this.ingredient.allergens.length == 0 &&
        !confirm('No allergens were added. Are you sure you want to save?')
      )
        return;

      this.isPushingIngredient = true;

      // Check mode
      if (this.ingredient.editMode)
        await this.$store.dispatch('ingredient/updateIngredient');
      else await this.$store.dispatch('ingredient/newIngredient');
      this.isPushingIngredient = false;
    },
    cleanIngredientString(event) {
      let found = event.target.value.match(/[A-Z0-9-]/g);
      if (found != null) {
        event.target.value = found.join('');
        this.updateCode(event);
      } else this.updateCode('');
    },
    cleanComponentString(event, field) {
      let found = event.target.value.match(/[A-Za-z0-9À-ÖØ-öø-ÿ/ ().,'-]/gi);
      if (found != null) this[field] = found.join('');
      else this[field] = '';
    },
    isAllowedIngredientChar(event) {
      if (/^[0-9A-Za-z-]+$/.test(event.key)) return true;
      else event.preventDefault();
    },
    isAllowedComponentChar(event) {
      if (/^[A-Za-z0-9À-ÖØ-öø-ÿ/ ().,'-]+$/.test(event.key)) return true;
      else event.preventDefault();
    },
    timeoutClear(field) {
      setTimeout(() => (this[field] = []), 200);
    },
    async search(field) {
      if (this[field].length > 3) {
        // Get search suggestions
        const results = await this.$store.dispatch(
          'ingredient/componentsSearch',
          {
            query: this[field],
            type: field,
          },
        );

        this[`${field}Results`] = results;
      } else {
        this[`${field}Results`] = [];
      }
    },
    editComponent(cid) {
      const component = this.$store.getters['ingredient/component'](cid);
      this.usEnName = component.usEnName;
      this.caEnName = component.caEnName;
      this.caFrName = component.caFrName;
      this.euEnName = component.euEnName;
      this.euFrName = component.euFrName;
      this.qty = '' + Math.round(component.qty * 100 * 10000) / 10000;
      this.$store.commit('ingredient/removeComponent', { cid });
    },
  },
};
</script>

<style scoped>
.actions {
  text-align: right;
}
.title {
  padding-top: 0.5rem;
}
.vertical.segment {
  width: 80vw;
}
.components.segment {
  overflow: auto;
  scrollbar-width: thin;
  max-height: 37rem;
}
</style>
