import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: () => {
    return {
      id: null,
      name: null,
      author: null,
      country: null,
      files: {
        nutrients: null,
        recipe: null,
      },
      recipeInfo: null,
      foodlist: null,
      vitaminBlend: null,
      nutrients: null,

      // Lists
      components: [],
      ingredientStatement: [],
      allergensContains: [],
      allergensMayContain: [],

      // Details
      reduceVitamins: false,
      pdcaas: 1,
      numServings: 1,
      servingIdentifier: 'bar',
      servingIdentifierFr: 'barre',
      servingSize: null,
      servingSizeInfo: null,
      servingsPerContainer: 1,
      foodCategory: { en: '', fr: '' },

      soiHeader: '',
      soiContent: '',
      soiHeaderFr: '',
      soiContentFr: '',
      shelfLife: '',
      hsCode: '',
      scoreEurope: '',
      packaging: '',
      productionOrigin: '',
      productionOriginFr: '',
      sampleCode: '',
      specificStatus: [],
      additionalAllergenStatement: '',
      additionalAllergenStatementFr: '',
      packagedInProtectiveAtmosphere: false,

      mealReplacementSources: [],

      sugarAlcohols: '0',

      instructions: '',
      instructionsFr: '',
      claims: [],
      additionalClaims: [],

      microbiologicalCriteria: {
        ebG: '',
        ebT: '',
        famG: '',
        famT: '',
        yeastG: '',
        yeastT: '',
        moldG: '',
        moldT: '',
        salmonellaG: '',
        salmonellaT: '',
        eColiG: '',
        eColiT: '',
        bcG: '',
        bcT: '',
        sacG: '',
        sacT: '',
        cpG: '',
        cpT: '',
      },

      aminogram: {
        lIsoleucine: '',
        lLeucine: '',
        lLysine: '',
        lMethionineLCystine: '',
        lPhenylalanineLTyrosine: '',
        lThreonine: '',
        lTryptophane: '',
        lValine: '',
        histidine: '',
      },

      // No save
      regionSettings: {
        templates: null,
        nutrients: null,
        allergensContains: null,
        allergensMayContain: null,
        foodCategories: null,
        specificStatus: null,
        claims: null,
      },
      selectedComponents: [],
      ingredientStatementItemToEdit: null,
      presentAllergens: null,

      // Modals
      openModal: null,
    };
  },
  mutations,
  getters,
  actions,
};
