<template>
  <div class="bg">
    <div class="ui card">
      <div class="content">
        <div class="header">Activate Your Account</div>
      </div>
      <div class="content">
        <div class="ui large form">
          <div class="field">
            <label>Token</label>
            <div class="ui left icon input">
              <input
                v-model="token"
                type="text"
                autocomplete="off"
                @keydown.prevent
              />
              <i class="key icon"></i>
            </div>
          </div>
          <div class="field">
            <label>Create Your Password</label>
            <div class="ui left icon input">
              <input
                ref="pw"
                type="password"
                placeholder="Password"
                autocomplete="off"
                maxlength="64"
                @keypress.enter="submit"
                @keyup="check"
              />
              <i class="lock icon"></i>
            </div>
          </div>
          <div class="ui tiny indicating progress">
            <div class="bar"></div>
          </div>

          <button
            type="submit"
            class="ui fluid teal submit button"
            :class="{ disabled: score < 3, loading: isLoading }"
            @click="submit"
          >
            Activate
          </button>
          <div
            v-if="warning || feedback.length > 0"
            class="ui mini info message"
          >
            <div class="header">{{ warning }}</div>
            <ul class="list">
              <li v-for="(item, index) in feedback" :key="index">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn';
export default {
  data() {
    return {
      token: null,
      score: 0,
      warning: '',
      feedback: '',
      timeout: null,
      isLoading: false,
    };
  },
  created() {
    this.token = this.$route.params.token;
    window.$('.progress').progress();
  },
  methods: {
    check() {
      // Set progress bar
      const res = zxcvbn(this.$refs.pw.value);
      window.$('.progress').progress({ value: res.score, total: 4 });
      this.score = res.score;

      if (!this.$refs.pw.value) return false;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.warning = res.feedback.warning;
        this.feedback = res.feedback.suggestions;
      }, 500);
    },

    async submit() {
      this.isLoading = true;

      // Check password strength
      if (zxcvbn(this.$refs.pw.value).score < 3) {
        this.isLoading = false;
        return;
      }

      await this.$store.dispatch('account/activateAccount', {
        username: this.token,
        password: this.$refs.pw.value,
      });

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.bg {
  background: rgb(14, 63, 92);
  background: radial-gradient(
    circle,
    rgba(14, 63, 92, 1) 0%,
    rgba(8, 37, 54, 1) 100%
  );
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
  position: absolute;
}
</style>
