<template>
  <h4 @click="showState">Instructions</h4>
  <div class="ui form">
    <div class="field" :class="{ disabled: isLoading || isViewOnly }">
      <label>French</label>
      <textarea
        v-model.trim="instructionsFr"
        rows="2"
        @keyup="setInstructionsFr($event.target)"
        @change="!instructions ? translateToEnglish($event.target.value) : ''"
      ></textarea>
    </div>
    <div class="field" :class="{ disabled: isLoading || isViewOnly }">
      <label>English</label>
      <textarea
        v-model.trim="instructions"
        rows="2"
        @keyup="setInstructions($event.target)"
        @change="!instructionsFr ? translateToFrench($event.target.value) : ''"
      ></textarea>
    </div>
  </div>

  <hr />

  <div class="ui form">
    <h4>Claims</h4>
    <div class="ui grid">
      <div
        v-for="(claim, index) in regionSettings.claims"
        :key="index"
        class="eight wide column"
      >
        <div class="field">
          <div class="ui checkbox">
            <input
              type="checkbox"
              autocomplete="off"
              name="low-fat"
              :disabled="isViewOnly"
              :checked="claims.find(e => e.en == claim.en)"
              @change="toggleClaim({ claim })"
            />
            <label>{{ claim.en }}</label>
          </div>
        </div>
      </div>
    </div>

    <h4>Additional Claims</h4>
    <div class="ui form">
      <div class="grouped fields">
        <div
          v-for="(claim, index) in additionalClaimsList"
          :key="index"
          class="field"
        >
          <div class="ui checkbox">
            <input
              type="checkbox"
              :value="claim"
              :disabled="isViewOnly"
              :checked="additionalClaims.includes(claim)"
              @change="toggleAdditionalClaims($event.target)"
            />
            <label>{{ claim }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      instructions: this.$store.state.project.instructions,
      instructionsFr: this.$store.state.project.instructionsFr,

      additionalClaimsList: [
        'Contains polyols (> 10 % : excessive consumption may produce laxative effects). - Contient des polyols (> 10 % : une consommation excessive peut avoir des effets laxatifs).',
        '(Regulation / Règlement CE n° 1169/2011 du 25 octobre 2011 annexe III)',
        'With sweetener(s) - Avec édulcorant(s)',
        'With sugar(s) et édulcorant(s) - Avec sucre(s) et édulcorant(s)',
        "With no added sugars, contains naturally the following indication should also appear on the label : « Contains naturally occurring sugars». / Sans sucres ajoutés l'indication suivante devrait également figurer sur l'étiquette : « Contient des sucres naturellement présents ».",
        'With aspartame (contains a source of phenylalanine) – Avec aspartame (contient une source de phénylalanine)',
      ],

      isLoading: false,
    };
  },
  computed: {
    ...mapState('project', ['regionSettings', 'claims', 'additionalClaims']),
    ...mapGetters('project', ['isViewOnly']),
  },
  methods: {
    showState() {
      console.log(this.$store.state.project);
    },
    ...mapMutations('project', [
      'setInstructions',
      'setInstructionsFr',
      'toggleClaim',
      'toggleAdditionalClaims',
    ]),
    async translateToEnglish(text) {
      if (!text) return;

      this.isLoading = true;
      const translation = await this.$store.dispatch('project/translate', {
        to: 'en',
        from: 'fr',
        text: text,
      });
      this.instructions = translation;
      this.setInstructions({ value: this.instructions });
      this.isLoading = false;
    },

    async translateToFrench(text) {
      if (!text) return;

      this.isLoading = true;
      const translation = await this.$store.dispatch('project/translate', {
        to: 'fr',
        from: 'en',
        text: text,
      });
      this.instructionsFr = translation;
      this.setInstructionsFr({ value: this.instructionsFr });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
hr {
  border: 1px solid rgba(173, 173, 173, 0.25);
}
</style>
