import axios from 'axios';
import router from '../router';
import request from '../request';
import { port } from '../../../config';

export default {
  /**
   * Authenticate with credentials
   * @param {object} context
   * @param {object} payload Username and password
   * @returns {boolean} If user is logged in
   * @throws Error if cannot connect
   */
  authenticate: async (context, payload) => {
    let response;
    try {
      response = await axios({
        method: 'POST',
        url:
          process.env.NODE_ENV === 'development'
            ? `http://localhost:${port}/api/auth/login`
            : '/api/auth/login',
        auth: payload,
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
      context.commit('reset');
      throw 'Network error';
    }

    // Check status
    if (response.status != 200 || !response.data.auth) {
      context.commit('showAlert', {
        type: 'warning',
        header: 'Oops...',
        message:
          response.data && response.data.error
            ? response.data.error
            : 'Soemthing went wrong',
        timeout: 5000,
      });
      return false;
    }

    // Set user data
    try {
      context.commit('setUserData', JSON.parse(response.headers['user-data']));
    } catch (err) {
      console.error(err);
      return false;
    }

    // Get organization settings
    await context.dispatch('getOrganizationSettings');

    return true;
  },

  /**
   * Get organization details
   */
  getOrganizationSettings: async context => {
    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/account/organization',
      });
      context.state.organization = response.data.organization;
    } catch (err) {
      console.error(err);

      // If error logout
      await context.dispatch('logout');
      context.commit('showAlert', {
        type: 'error',
        header: 'Something went wrong',
        message: 'Could not connect to server or get account details',
      });
    }
  },

  /**
   * Logout
   */
  logout: async context => {
    try {
      await axios({
        method: 'POST',
        url:
          process.env.NODE_ENV === 'development'
            ? `http://localhost:${port}/api/auth/logout`
            : '/api/auth/logout',
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    } finally {
      // Navigate to login and reset all states
      await router.replace({ name: 'Login' });
      context.commit('reset');
      context.commit('account/reset');
      context.commit('admin/reset');
      context.commit('dashboard/reset');
      context.commit('ingredient/reset');
      context.commit('project/reset');
      context.commit('rules/reset');
      context.commit('templates/reset');
    }
  },

  /**
   * Checks user authentication and refreshes organization data
   * - Does not automatically log out on failure
   * @returns {boolean}
   */
  verifyAuth: async context => {
    try {
      const response = await axios({
        method: 'GET',
        url:
          process.env.NODE_ENV === 'development'
            ? `http://localhost:${port}/api/auth`
            : '/api/auth',
        withCredentials: true,
      });

      if (response.status == 204) {
        await context.dispatch('getOrganizationSettings');
        return true;
      } else return false;
    } catch (_) {
      return false;
    }
  },

  /**
   * Check if user logged in
   * 1. Checks if user & organization data in state
   * 2. If not sends request to verify
   *
   * - Does not automatically log out on failure
   * @returns {boolean}
   */
  isLoggedIn: async context => {
    if (context.state.user && context.state.organization) return true;
    return await context.dispatch('verifyAuth');
  },
};
