<template>
  <teleport to="body">
    <transition name="banner">
      <div v-if="alert.show" class="ui floating message" :class="alert.type">
        <i class="close icon" @click="closeAlert"></i>
        <div class="header">{{ alert.header }}</div>
        <p>{{ alert.message }}</p>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['alert']),
  },
  methods: {
    ...mapMutations(['closeAlert']),
  },
};
</script>

<style>
.floating.message {
  position: absolute !important;
  z-index: 300;
  left: calc(50vw - 19rem);
  top: 1rem;
  width: 38rem;
  box-shadow: 6px 10px 48px 0px rgba(0, 0, 0, 0.75) !important;
}

@media screen and (max-width: 700px) {
  .floating.message {
    width: 75vw;
    left: 12.5vw;
  }
}

/* Animations */
.banner-enter-active,
.banner-leave-active {
  transition: all 0.5s ease-in-out;
}

.banner-enter-from,
.banner-leave-to {
  opacity: 0;
}
</style>
