export default {
  /**
   * Place all projects in state
   */
  setProjects: (state, payload) => {
    if (!payload.projects) return;
    state.displayedProjects = payload.projects;
  },

  /**
   * Reset state to defaults
   */
  reset: state => {
    state.displayedProjects = [];
  },
};
