<template>
  <label class="file-select">
    <div class="select-button" :class="{ green: filePresent, disabled: disabled }">
      <i v-if="filePresent" class="check circle icon"></i>
      <i v-else class="upload icon"></i>
      <span v-if="filePresent">Selected</span>
      <span v-else>{{ buttonText }}</span>
    </div>
    <input
      :disabled="disabled"
      type="file"
      @input="$emit('file-change', $event.target.files[0])"
    />
  </label>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Select File',
    },
    filePresent: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['file-change'],
};
</script>

<style scoped>
.file-select > .select-button {
  padding: 1rem;
  margin: 1rem 0;
  color: white;
  background-color: #de5c25;
  border-radius: 0.3rem;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.file-select > input[type='file'] {
  display: none;
}
.select-button.disabled {
  background-color: grey;
  cursor: default;
}
.green {
  background-color: #1aa72a !important;
}
</style>
