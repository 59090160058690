<template>
  <BaseModal
    :show="openModal == 'editMealReplacementCalculation'"
    @deny="closeModal"
  >
    <div class="ui vertical segment title">
      <h3>
        Edit Calculation for <i>{{ activeMealReplacementItem.item.name }}</i>
      </h3>
    </div>
    <div class="ui vertical segment">
      <div class="ui form mb-1">
        <div class="field" :class="{ error: !validCalculation }">
          <label>Enter calculation:</label>
          <input
            v-model="calculation"
            type="text"
            maxlength="100"
            @keyup="validCalculation = true"
            @keydown="isAllowedChar($event)"
          />
        </div>
      </div>

      <div
        v-for="(item, index) in parseCalculation"
        :key="index"
        class="ui label"
      >
        {{ item }}
      </div>
    </div>
    <div class="ui vertical segment actions">
      <div class="ui cancel button" @click="closeModal">Close</div>
      <div
        class="ui positive button"
        :class="{
          disabled: calculation.length == 0 || isUpdating,
          loading: isUpdating,
        }"
        @click="submitCalculation"
      >
        Submit
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { arrayCalculator } from '../../shunting';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      calculation: '',
      validCalculation: true,
      isUpdating: false,
      operators: ['+', '-', '*', '/'],
    };
  },
  computed: {
    ...mapState('rules', ['openModal', 'activeMealReplacementItem']),
    parseCalculation() {
      const parsed = [];
      let item = '';

      for (let i = 0; i < this.calculation.length; i++) {
        if (
          this.operators.includes(this.calculation[i]) ||
          this.calculation[i] == '(' ||
          this.calculation[i] == ')'
        ) {
          if (item.length > 0) {
            parsed.push(item);
            item = '';
          }
          parsed.push(this.calculation[i]);
        } else item += this.calculation[i];
      }
      if (item.length > 0) parsed.push(item);

      return parsed;
    },
  },
  methods: {
    isAllowedChar(event) {
      if (/^[A-Za-z0-9À-ÖØ-öø-ÿ/ ().,'+*/-]+$/.test(event.key)) return true;
      else event.preventDefault();
    },
    async submitCalculation() {
      if (this.parseCalculation.length == 0)
        return (this.validCalculation = false);

      const expression = this.parseCalculation;
      const sampleExpression = [];

      // Create sample expression and test it with arrayCalculator
      try {
        expression.forEach(item => {
          if (!this.operators.includes(item) && item != '(' && item != ')')
            sampleExpression.push(1);
          else sampleExpression.push(item);
        });
        arrayCalculator(sampleExpression);
      } catch (err) {
        console.error(err);
        return (this.validCalculation = false);
      }

      this.isUpdating = true;
      await this.$store.dispatch('rules/updateMealReplacementCalculation', {
        expression,
      });
      this.isUpdating = false;
      this.closeModal();
    },
    closeModal() {
      this.calculation = '';
      this.validCalculation = true;
      this.$store.commit('rules/closeModal');
    },
  },
};
</script>
