<template>
  <transition name="splash">
    <SplashScreen v-if="!isMounted" />
  </transition>
  <TheNavbar v-if="!$route.meta.hideNav"></TheNavbar>
  <TheAlertBanner />

  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component" v-if="isMounted"></component>
    </transition>
  </router-view>
</template>

<script>
import SplashScreen from '@/components/SplashScreen';
import TheNavbar from '@/components/ui/TheNavbar';
import TheAlertBanner from '@/components/ui/TheAlertBanner';

export default {
  components: {
    TheNavbar,
    TheAlertBanner,
    SplashScreen,
  },
  data() {
    return {
      isMounted: false,
    };
  },
  mounted() {
    this.$nextTick(() => setTimeout(() => (this.isMounted = true), 1000));

    // Check auth every 5 minutes
    setInterval(async () => {
      if (!this.$route.meta || !this.$route.meta.guest) {
        const loggedIn = await this.$store.dispatch('verifyAuth');
        if (!loggedIn) this.$store.dispatch('logout');
      }
    }, 5 * 60 * 1000);

    // Resize listener
    window.addEventListener(
      'resize',
      () => (this.$store.state.windowWidth = window.innerWidth),
    );
  },
};
</script>

<style scoped>
.splash-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.splash-leave-to {
  opacity: 0;
}

/* Route Animations */
.route-enter-from,
.route-leave-to {
  opacity: 0;
}

.route-enter-active {
  transition: opacity 0.2s ease-out;
}

.route-leave-active {
  transition: opacity 0.2s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
}
</style>

<style>
/* Global Styles */
body {
  background-color: #f5f7fb;
}

@font-face {
  font-family: 'helveticablack';
  src: url('./fonts/helvetica-black.woff2') format('woff2'),
    url('./fonts/helvetica-black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sophie-light';
  src: url('./fonts/sophie-light.woff2') format('woff2'),
    url('./fonts/sophie-light.woff') format('woff');
}

@font-face {
  font-family: 'sophie-bold';
  src: url('./fonts/sophie-bold.woff2') format('woff2'),
    url('./fonts/sophie-bold.woff') format('woff');
}

:root {
  --dark-blue: #242d3c;
}

a:focus {
  outline: none;
}

/* Shorthand */
.mt-half {
  margin-top: 0.5rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.tc {
  text-align: center;
}
</style>
