import request from '../../../request';
import { port } from '../../../../../config';

export default {
  /**
   * Update template
   * @param {object} context
   * @param {object} payload Template document, name and country
   */
  uploadTemplate: async (context, payload) => {
    const formData = new FormData();
    formData.append('template', payload.template);
    formData.append('name', payload.name);
    formData.append('country', payload.country);

    try {
      await request.sendRequest(context, {
        method: 'POST',
        url: '/api/template/update',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      context.commit(
        'showAlert',
        {
          type: 'success',
          header: 'Updated',
          message: 'Template successfully updated',
          timeout: 5000,
        },
        { root: true },
      );
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Download Template
   */
  downloadTemplate: async (context, payload) => {
    try {
      // Get download location
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: `/api/template/${payload.country}/${payload.name}`,
      });

      // Navigate to download location
      window.location =
        process.env.NODE_ENV === 'development'
          ? `http://localhost:${port}/api/template/generated/${response.data.filename}`
          : '/api/template/generated/' + response.data.filename;
    } catch (err) {
      console.error(err);
    }
  },
};
