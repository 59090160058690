<template>
  <ConfirmationModal
    :show="show"
    :confirm-loading="isLoading"
    @deny="closeModal"
    @confirm="editUser"
  >
    <template #header>Edit User Type</template>
    <template #meta>{{ user.fullName }}</template>
    <template #default>
      <div class="ui header">Change user type</div>
      <select ref="newUserType" class="ui dropdown">
        <option value="1" :selected="user.clearanceLevel == 1">User</option>
        <option value="2" :selected="user.clearanceLevel == 2">Editor</option>
        <option value="3" :selected="user.clearanceLevel == 3">Admin</option>
      </select>
      <div class="ui header">Enable/Disable User Access</div>
      <select ref="newUserAccess" class="ui dropdown">
        <option value="true" :selected="user.active">Enabled</option>
        <option value="false" :selected="!user.active">Disabled</option>
      </select>
    </template>
    <template #deny-button>Cancel</template>
    <template #confirm-button>Confirm</template>
  </ConfirmationModal>
</template>

<script>
import ConfirmationModal from '@/components/ui/ConfirmationModal';
import { mapMutations } from 'vuex';

export default {
  components: {
    ConfirmationModal,
  },
  props: {
    show: Boolean,
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations('admin', ['closeModal']),
    async editUser() {
      this.isLoading = true;
      await this.$store.dispatch('admin/editUser', {
        id: this.user._id,
        clearanceLevel: parseInt(this.$refs.newUserType.value),
        status: this.$refs.newUserAccess.value == 'true',
      });
      this.$store.commit('admin/closeModal');
      this.isLoading = false;
    },
  },
};
</script>
