<template>
  <div>
    <TopHeader header-title="Rules" show-user-info />
    <CenterContainer>
      <div class="three ui buttons">
        <button
          class="ui button"
          :class="{ black: selectedRegion == 'ca' }"
          @click="setRegion({ region: 'ca' })"
        >
          <i class="ca flag"></i>
          Canada
        </button>
        <button
          class="ui button"
          :class="{ black: selectedRegion == 'eu' }"
          @click="setRegion({ region: 'eu' })"
        >
          <i class="eu flag"></i>
          Europe
        </button>
        <button
          class="ui button"
          :class="{ black: selectedRegion == 'us' }"
          @click="setRegion({ region: 'us' })"
        >
          <i class="us flag"></i>
          United States
        </button>
      </div>

      <div class="ui top attached tabular menu">
        <a
          class="item"
          :class="{ active: activeTab == 1 }"
          @click="activeTab = 1"
        >
          Rounding
        </a>
        <a
          v-if="mealReplacement && mealReplacement.length > 0"
          class="item"
          :class="{ active: activeTab == 2 }"
          @click="activeTab = 2"
        >
          Meal Replacement
        </a>
      </div>
      <div
        class="ui bottom attached tab segment"
        :class="{ active: activeTab == 1 }"
      >
        <RulesList v-if="regionItems" />
        <SpinLoader v-else />
      </div>
      <div
        class="ui bottom attached tab segment"
        :class="{ active: activeTab == 2 }"
      >
        <MealReplacementRules v-if="mealReplacement" />
        <SpinLoader v-else />
      </div>
    </CenterContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RulesList from '@/components/rules/RulesList';
import MealReplacementRules from '@/components/rules/MealReplacementRules';

export default {
  components: {
    RulesList,
    MealReplacementRules,
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  computed: {
    ...mapState('rules', ['selectedRegion', 'regionItems', 'mealReplacement']),
  },
  created() {
    if (this.selectedRegion)
      this.$store.dispatch('rules/setRegion', { region: this.selectedRegion });
  },
  methods: {
    setRegion(payload) {
      // Reset tab
      this.activeTab = 1;
      this.$store.dispatch('rules/setRegion', payload);
    },
  },
};
</script>

<style scoped>
.segment {
  max-height: 78vh;
  overflow: auto;
}
</style>
