const isNumber = x => !isNaN(parseFloat(x));
const getOperator = x => {
  switch (x) {
    case '+':
      return {
        val: '+',
        precedence: 1,
        assoc: 'left',
        action: (a, b) => a + b,
      };
    case '-':
      return {
        val: '-',
        precedence: 1,
        assoc: 'left',
        action: (a, b) => b - a,
      };
    case '*':
      return {
        val: '*',
        precedence: 2,
        assoc: 'left',
        action: (a, b) => a * b,
      };
    case '/':
      return {
        val: '/',
        precedence: 2,
        assoc: 'left',
        action: (a, b) => b / a,
      };
  }
};

/**
 * Shunting yard algorithm
 * - Helper method for arrayCalculator
 * @param {string} expression Mathematical expression
 * @returns {Array} Reverse polish notation
 * @throws Expression error
 */
const shuntingYard = expression => {
  const outputQueue = [];
  const operatorStack = [];

  while (expression.length > 0) {
    const token = expression[0];

    if (isNumber(token)) outputQueue.push(token);
    else if (token == '+' || token == '-' || token == '*' || token == '/') {
      const operator = getOperator(token);
      while (
        operatorStack.length > 0 &&
        (operatorStack[operatorStack.length - 1].precedence >
          operator.precedence ||
          (operatorStack[operatorStack.length - 1].precedence ==
            operator.precedence &&
            operator.assoc == 'left')) &&
        operatorStack[operatorStack.length - 1].val != '('
      )
        outputQueue.push(operatorStack.pop());
      operatorStack.push(operator);
    } else if (token == '(')
      operatorStack.push({
        val: '(',
        precedence: 3,
      });
    else if (token == ')') {
      while (
        operatorStack.length > 0 &&
        operatorStack[operatorStack.length - 1].val != '('
      )
        outputQueue.push(operatorStack.pop());

      if (operatorStack[operatorStack.length - 1].val == '(')
        operatorStack.pop();
      else throw 'Parenthesis mismatch';
    }

    expression.splice(0, 1);
  }

  if (expression.length == 0)
    while (operatorStack.length > 0) outputQueue.push(operatorStack.pop());

  return outputQueue;
};

/**
 * Calculate mathematical expression from string
 * @param {string} expression Mathematical expression
 * @returns {number} Answer
 * @throws Expression error
 */
const arrayCalculator = expression => {
  if (!Array.isArray(expression)) throw 'Empty expression';

  const rpn = shuntingYard(expression);
  const stack = [];

  // Calculate from reverse polish notation
  for (let i = 0; i < rpn.length; i++) {
    const token = rpn[i];
    if (typeof token == 'number') stack.push(token);
    else if (typeof token == 'object') {
      const a = stack.pop();
      const b = stack.pop();
      if (isNaN(a) || isNaN(b)) throw 'Invalid expression';

      try {
        stack.push(token.action(a, b));
      } catch (err) {
        throw 'Invalid expression';
      }
    }
  }

  if (stack.length > 1) throw 'Error';

  return stack.pop();
};

module.exports = {
  arrayCalculator,
};
