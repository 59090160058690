<template>
  <div class="ui compact segment">
    <div class="nfp">
      <header>
        <h1>Nutrition Facts</h1>
        <div v-if="servingIdentifier" class="sub">
          {{ numServings }} servings per container
        </div>
        <div class="serving-size">
          <span>Serving Size</span>
          <span>{{
            servingSizeInfo
              ? `${servingSizeInfo} (${servingSize}g)`
              : servingSize + 'g'
          }}</span>
        </div>
      </header>

      <hr class="thicc" />

      <div class="overflow">
        <table>
          <tbody>
            <tr
              v-if="getNutrient('Calories') && getNutrient('Calories').show"
              class="blank"
            >
              <td colspan="3">
                <div class="bold7">Amount per serving</div>
                <b class="calories">Calories</b>
              </td>
              <td class="calories-amount">
                {{ getNutrient('Calories').roundedQty }}
              </td>
            </tr>

            <tr class="border thicker">
              <td colspan="4">
                <span class="light-bold">% Daily Value*</span>
              </td>
            </tr>

            <tr v-if="getNutrient('Fat') && getNutrient('Fat').show">
              <td colspan="3">
                <b>Total Fat</b>
                {{ getNutrient('Fat').roundedQty }}g
              </td>
              <td>
                <b v-if="getNutrient('Fat').roundedPercentDv">
                  {{ getNutrient('Fat').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Saturated Fat') &&
                getNutrient('Saturated Fat').show
              "
            >
              <td></td>
              <td colspan="2">
                Saturated Fat {{ getNutrient('Saturated Fat').roundedQty }}g
              </td>
              <td>
                <b v-if="getNutrient('Saturated Fat').roundedPercentDv">
                  {{ getNutrient('Saturated Fat').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Trans Fatty Acid') &&
                getNutrient('Trans Fatty Acid').show
              "
            >
              <td></td>
              <td colspan="2">
                <i>Trans</i> Fat
                {{ getNutrient('Trans Fatty Acid').roundedQty }}g
              </td>
              <td>
                <b v-if="getNutrient('Trans Fatty Acid').roundedPercentDv">
                  {{ getNutrient('Trans Fatty Acid').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Cholesterol') && getNutrient('Cholesterol').show
              "
            >
              <td colspan="3">
                <b>Cholesterol</b> {{ getNutrient('Cholesterol').roundedQty }}mg
              </td>
              <td>
                <b v-if="getNutrient('Cholesterol').roundedPercentDv">
                  {{ getNutrient('Cholesterol').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr v-if="getNutrient('Sodium') && getNutrient('Sodium').show">
              <td colspan="3">
                <b>Sodium</b> {{ getNutrient('Sodium').roundedQty }}mg
              </td>
              <td>
                <b v-if="getNutrient('Sodium').roundedPercentDv">
                  {{ getNutrient('Sodium').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr v-if="getNutrient('Fluoride') && getNutrient('Fluoride').show">
              <td colspan="3">
                <b>Fluroide</b> {{ getNutrient('Fluoride').roundedQty }}mg
              </td>
              <td>
                <b v-if="getNutrient('Fluroide').roundedPercentDv">
                  {{ getNutrient('Fluroide').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Carbohydrates') &&
                getNutrient('Carbohydrates').show
              "
            >
              <td colspan="3">
                <b>Total Carbohydrate</b>
                {{ getNutrient('Carbohydrates').roundedQty }}g
              </td>
              <td>
                <b v-if="getNutrient('Carbohydrates').roundedPercentDv">
                  {{ getNutrient('Carbohydrates').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Dietary Fiber (2016)') &&
                getNutrient('Dietary Fiber (2016)').show
              "
            >
              <td></td>
              <td colspan="2">
                Dietary Fiber
                {{ getNutrient('Dietary Fiber (2016)').roundedQty }}g
              </td>
              <td>
                <b v-if="getNutrient('Dietary Fiber (2016)').roundedPercentDv">
                  {{ getNutrient('Dietary Fiber (2016)').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Total Sugars') && getNutrient('Total Sugars').show
              "
            >
              <td></td>
              <td colspan="2">
                Total Sugars {{ getNutrient('Total Sugars').roundedQty }}g
              </td>
              <td>
                <b v-if="getNutrient('Total Sugars').roundedPercentDv">
                  {{ getNutrient('Total Sugars').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Added Sugar') && getNutrient('Added Sugar').show
              "
            >
              <td class="blank"></td>
              <td class="blank"></td>
              <td>
                Includes {{ getNutrient('Added Sugar').roundedQty }}g Added
                Sugars
              </td>
              <td>
                <b v-if="getNutrient('Added Sugar').roundedPercentDv">
                  {{ getNutrient('Added Sugar').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Sugar Alcohol') &&
                getNutrient('Sugar Alcohol').show
              "
            >
              <td class="blank-border"></td>
              <td colspan="2">
                Sugar Alcohol {{ getNutrient('Sugar Alcohol').roundedQty }}g
              </td>
              <td>
                <b v-if="getNutrient('Sugar Alcohol').roundedPercentDv">
                  {{ getNutrient('Sugar Alcohol').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr v-if="getNutrient('Protein') && getNutrient('Protein').show">
              <td colspan="3">
                <b>Protein {{ getNutrient('Protein').roundedQty }}g</b>
              </td>
              <td>
                <b v-if="getNutrient('Protein').roundedPercentDv">
                  {{ getNutrient('Protein').roundedPercentDv }}
                </b>
              </td>
            </tr>

            <tr class="blank">
              <td colspan="4">
                <hr class="thicc" />
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Vitamin D - mcg') &&
                getNutrient('Vitamin D - mcg').show
              "
              class="blank"
            >
              <td colspan="3">
                Vitamin D {{ getNutrient('Vitamin D - mcg').roundedQty }}mcg
              </td>
              <td>
                <span v-if="getNutrient('Vitamin D - mcg').roundedPercentDv">
                  {{ getNutrient('Vitamin D - mcg').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr v-if="getNutrient('Calcium') && getNutrient('Calcium').show">
              <td colspan="3">
                Calcium {{ getNutrient('Calcium').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Calcium').roundedPercentDv">
                  {{ getNutrient('Calcium').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr v-if="getNutrient('Iron') && getNutrient('Iron').show">
              <td colspan="3">Iron {{ getNutrient('Iron').roundedQty }}mg</td>
              <td>
                <span v-if="getNutrient('Iron').roundedPercentDv">
                  {{ getNutrient('Iron').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="getNutrient('Potassium') && getNutrient('Potassium').show"
            >
              <td colspan="3">
                Potassium {{ getNutrient('Potassium').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Potassium').roundedPercentDv">
                  {{ getNutrient('Potassium').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Vitamin A - RAE') &&
                getNutrient('Vitamin A - RAE').show
              "
            >
              <td colspan="3">
                Vitamin A {{ getNutrient('Vitamin A - RAE').roundedQty }}mcg
              </td>
              <td>
                <span v-if="getNutrient('Vitamin A - RAE').roundedPercentDv">
                  {{ getNutrient('Vitamin A - RAE').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="getNutrient('Vitamin C') && getNutrient('Vitamin C').show"
            >
              <td colspan="3">
                Vitamin C {{ getNutrient('Vitamin C').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Vitamin C').roundedPercentDv">
                  {{ getNutrient('Vitamin C').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Vitamin E - mg') &&
                getNutrient('Vitamin E - mg').show
              "
            >
              <td colspan="3">
                Vitamin E {{ getNutrient('Vitamin E - mg').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Vitamin E - mg').roundedPercentDv">
                  {{ getNutrient('Vitamin E - mg').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="getNutrient('Vitamin K') && getNutrient('Vitamin K').show"
            >
              <td colspan="3">
                Vitamin K {{ getNutrient('Vitamin K').roundedQty }}mcg
              </td>
              <td>
                <span v-if="getNutrient('Vitamin K').roundedPercentDv">
                  {{ getNutrient('Vitamin K').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Vitamin B1 - Thiamin') &&
                getNutrient('Vitamin B1 - Thiamin').show
              "
            >
              <td colspan="3">
                Thiamin {{ getNutrient('Vitamin B1 - Thiamin').roundedQty }}mg
              </td>
              <td>
                <span
                  v-if="getNutrient('Vitamin B1 - Thiamin').roundedPercentDv"
                >
                  {{ getNutrient('Vitamin B1 - Thiamin').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Vitamin B2 - Riboflavin') &&
                getNutrient('Vitamin B2 - Riboflavin').show
              "
            >
              <td colspan="3">
                Riboflavin
                {{ getNutrient('Vitamin B2 - Riboflavin').roundedQty }}mg
              </td>
              <td>
                <span
                  v-if="getNutrient('Vitamin B2 - Riboflavin').roundedPercentDv"
                >
                  {{ getNutrient('Vitamin B2 - Riboflavin').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Vitamin B3 - Niacin Equiv') &&
                getNutrient('Vitamin B3 - Niacin Equiv').show
              "
            >
              <td colspan="3">
                Niacin
                {{ getNutrient('Vitamin B3 - Niacin Equiv').roundedQty }}mg
              </td>
              <td>
                <span
                  v-if="
                    getNutrient('Vitamin B3 - Niacin Equiv').roundedPercentDv
                  "
                >
                  {{
                    getNutrient('Vitamin B3 - Niacin Equiv').roundedPercentDv
                  }}
                </span>
              </td>
            </tr>

            <tr
              v-if="getNutrient('Vitamin B6') && getNutrient('Vitamin B6').show"
            >
              <td colspan="3">
                Vitamin B6 {{ getNutrient('Vitamin B6').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Vitamin B6').roundedPercentDv">
                  {{ getNutrient('Vitamin B6').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Folate, DFE') && getNutrient('Folate, DFE').show
              "
            >
              <td colspan="3">
                Folate {{ getNutrient('Folate, DFE').roundedQty }}mcg DFE
              </td>
              <td>
                <span v-if="getNutrient('Folate, DFE').roundedPercentDv">
                  {{ getNutrient('Folate, DFE').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Vitamin B12') && getNutrient('Vitamin B12').show
              "
            >
              <td colspan="3">
                Vitamin B12 {{ getNutrient('Vitamin B12').roundedQty }}mcg
              </td>
              <td>
                <span v-if="getNutrient('Vitamin B12').roundedPercentDv">
                  {{ getNutrient('Vitamin B12').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr v-if="getNutrient('Biotin') && getNutrient('Biotin').show">
              <td colspan="3">
                Biotin {{ getNutrient('Biotin').roundedQty }}mcg
              </td>
              <td>
                <span v-if="getNutrient('Biotin').roundedPercentDv">
                  {{ getNutrient('Biotin').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="
                getNutrient('Pantothenic Acid') &&
                getNutrient('Pantothenic Acid').show
              "
            >
              <td colspan="3">
                Pantothenic Acid
                {{ getNutrient('Pantothenic Acid').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Pantothenic Acid').roundedPercentDv">
                  {{ getNutrient('Pantothenic Acid').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="getNutrient('Phosphorus') && getNutrient('Phosphorus').show"
            >
              <td colspan="3">
                Phosphorus {{ getNutrient('Phosphorus').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Phosphorus').roundedPercentDv">
                  {{ getNutrient('Phosphorus').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr v-if="getNutrient('Iodine') && getNutrient('Iodine').show">
              <td colspan="3">
                Iodine {{ getNutrient('Iodine').roundedQty }}mcg
              </td>
              <td>
                <span v-if="getNutrient('Iodine').roundedPercentDv">
                  {{ getNutrient('Iodine').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="getNutrient('Magnesium') && getNutrient('Magnesium').show"
            >
              <td colspan="3">
                Magnesium {{ getNutrient('Magnesium').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Magnesium').roundedPercentDv">
                  {{ getNutrient('Magnesium').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr v-if="getNutrient('Zinc') && getNutrient('Zinc').show">
              <td colspan="3">Zinc {{ getNutrient('Zinc').roundedQty }}mg</td>
              <td>
                <span v-if="getNutrient('Zinc').roundedPercentDv">
                  {{ getNutrient('Zinc').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr v-if="getNutrient('Selenium') && getNutrient('Selenium').show">
              <td colspan="3">
                Selenium {{ getNutrient('Selenium').roundedQty }}mcg
              </td>
              <td>
                <span v-if="getNutrient('Selenium').roundedPercentDv">
                  {{ getNutrient('Selenium').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr v-if="getNutrient('Copper') && getNutrient('Copper').show">
              <td colspan="3">
                Copper {{ getNutrient('Copper').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Copper').roundedPercentDv">
                  {{ getNutrient('Copper').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="getNutrient('Manganese') && getNutrient('Manganese').show"
            >
              <td colspan="3">
                Manganese {{ getNutrient('Manganese').roundedQty }}mg
              </td>
              <td>
                <span v-if="getNutrient('Manganese').roundedPercentDv">
                  {{ getNutrient('Manganese').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr v-if="getNutrient('Chromium') && getNutrient('Chromium').show">
              <td colspan="3">
                Chromium {{ getNutrient('Chromium').roundedQty }}mcg
              </td>
              <td>
                <span v-if="getNutrient('Chromium').roundedPercentDv">
                  {{ getNutrient('Chromium').roundedPercentDv }}
                </span>
              </td>
            </tr>

            <tr
              v-if="getNutrient('Molybdenum') && getNutrient('Molybdenum').show"
            >
              <td colspan="3">
                Molybdenum {{ getNutrient('Molybdenum').roundedQty }}mcg
              </td>
              <td>
                <span v-if="getNutrient('Molybdenum').roundedPercentDv">
                  {{ getNutrient('Molybdenum').roundedPercentDv }}
                </span>
              </td>
            </tr>
            <tr class="border thicker">
              <td class="wrap" colspan="4">
                <p class="footer">
                  * The % Daily Value (DV) tells you how much a nutrient in a
                  serving of food contributes to a daily diet. 2,000 calories a
                  day is used for general nutrition
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  computed: {
    ...mapGetters('project', ['getNutrient']),
    ...mapState('project', [
      'numServings',
      'servingSize',
      'servingIdentifier',
      'servingSizeInfo',
    ]),
  },
};
</script>

<style scoped>
* {
  font-family: Helvetica, sans-serif;
}

.nfp {
  border: 1px solid black;
  width: 18rem;
  padding: 0.3rem;
  background-color: white;
}
.nutrition-facts-section table {
  border-collapse: collapse;
}

.nfp header h1 {
  font-weight: bold;
  font-size: 18pt;
  margin: 0 0 0.1rem 0;
  border-bottom: 1px solid black;
  font-family: helveticablack, Helvetica, sans-serif !important;
}

.nfp header .sub {
  font-weight: normal;
  font-family: helvetica, sans-serif;
  font-size: 10pt;
  padding: 0.2em 0 0em 0;
}

.calories,
.calories-amount {
  font-weight: bold;
  margin: 0;
  font-family: helveticablack, Helvetica, sans-serif;
}
.calories {
  font-size: 16pt;
}
.calories-amount {
  font-size: 22pt;
}

.serving-size {
  font-size: 10pt;
  font-weight: bold;
  margin-bottom: 3pt;
  display: flex;
  justify-content: space-between;
  font-family: helvetica, sans-serif;
}

td {
  font-size: 8pt;
}

/* Thic divider */
hr.thicc {
  border-top: 7pt solid black;
  padding: 0;
  margin: 0;
}

.light-bold {
  font-weight: bold;
  font-size: 8pt;
}
b {
  font-weight: 500;
  font-family: helveticablack, Helvetica, sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr td:nth-child(1) {
  width: 5%;
}

table th,
table td {
  font-weight: normal;
  text-align: left;
  border-top: 0.25pt solid black;
}

table td:last-child {
  text-align: right;
}

table tr.blank td,
table td.blank {
  width: 12pt;
  border-top: 0;
}

table .blank-border {
  width: 10pt;
  border-top: 1px solid black;
}

table .thick-row th,
table .thick-row td {
  border-top-width: 5px;
}

.border.thicker {
  border-top: 2pt solid black;
}
.footer {
  font-size: 6pt;
  margin-left: 1pt;
  padding-top: 0.75em;
  text-align: left;
}
table td.wrap {
  width: 100%;
  word-wrap: break-word !important;
}
.overflow {
  overflow: auto;
  max-height: 71vh;
  scrollbar-width: thin;
}
</style>
