export default {
  /**
   * Show alert banner
   * Types: info, warning, positive, negative, orange, olive, teal, violet, purple, brown, black
   * Header: Message header (optional)
   * Message: Message to show
   * Timeout: Amount of ms to wait before automatically close (optional)
   */
  showAlert: (state, payload) => {
    clearTimeout(state.alert.timeoutID);
    state.alert.type = payload.type;
    state.alert.header = payload.header;
    state.alert.message = payload.message;
    state.alert.show = true;
    if (payload.timeout)
      state.alert.timeoutID = setTimeout(
        () => (state.alert.show = false),
        payload.timeout,
      );
  },

  /**
   * Close alert banner
   */
  closeAlert: state => {
    state.alert.show = false;
    clearTimeout(state.alert.timeoutID);
  },

  /**
   * Open Navbar
   */
  openNavbar: state => {
    state.showNavbar = true;
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  },

  /**
   * Close Navbar
   */
  closeNavbar: state => {
    state.showNavbar = false;
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  },

  /**
   * Set user data
   */
  setUserData: (state, payload) => {
    state.user = payload;
  },

  /**
   * Reset state to defaults
   */
  reset: state => {
    state.alert = {
      show: false,
      type: 'info',
      header: 'Info',
      message: '',
      timeoutID: -1,
    };
    state.organization = null;
    state.user = null;
    state.showNavbar = true;
  },
};
