<template>
  <div class="ui stackable grid">
    <div class="row">
      <div class="seven wide column">
        <RecipeSection />
      </div>

      <div class="four wide column">
        <NFP />
      </div>

      <div class="five wide column">
        <IngredientStatement :max-height="40" />
      </div>
    </div>
  </div>
</template>

<script>
import NFP from '@/components/project/builder/us/NFP';
import RecipeSection from '@/components/project/builder/us/RecipeSection';
import IngredientStatement from '@/components/project/builder/IngredientStatement';

export default {
  components: {
    RecipeSection,
    NFP,
    IngredientStatement,
  },
};
</script>
