<template>
  <teleport to="body">
    <div class="ui dimmer" :class="{ active: dimmerActive }">
      <div class="ui text loader">Compiling</div>
    </div>
  </teleport>
  <div class="ui segment">
    <h3>Backup & Export</h3>
    <p>
      Backups in JSON format are used as a restore point in case of data loss
    </p>
    <div class="ui labeled icon dropdown basic compact button">
      <i class="download icon"></i>
      Ingredients
      <div class="menu">
        <div class="header">Select Format</div>
        <div class="divider"></div>
        <div class="item" @click="exportIngredientsToCsv">
          <i class="file excel icon"></i>
          CSV
        </div>
        <div class="item" @click="exportIngredientsToJson">
          <svg
            height="12"
            style="padding-left: 0.1rem; margin-right: 0.78571429rem"
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M208 32h-48a96 96 0 0 0-96 96v37.48a32.06 32.06 0 0 1-9.38 22.65L9.37 233.37a32 32 0 0 0 0 45.26l45.25 45.25A32 32 0 0 1 64 346.51V384a96 96 0 0 0 96 96h48a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-48a32 32 0 0 1-32-32v-37.48a96 96 0 0 0-28.13-67.89L77.25 256l22.63-22.63A96 96 0 0 0 128 165.48V128a32 32 0 0 1 32-32h48a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm358.63 201.37l-45.25-45.24a32.06 32.06 0 0 1-9.38-22.65V128a96 96 0 0 0-96-96h-48a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h48a32 32 0 0 1 32 32v37.47a96 96 0 0 0 28.13 67.91L498.75 256l-22.62 22.63A96 96 0 0 0 448 346.52V384a32 32 0 0 1-32 32h-48a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h48a96 96 0 0 0 96-96v-37.49a32 32 0 0 1 9.38-22.63l45.25-45.25a32 32 0 0 0 0-45.26z"
              class=""
            ></path>
          </svg>
          JSON
        </div>
      </div>
    </div>

    <div class="ui labeled icon dropdown basic compact button">
      <i class="download icon"></i>
      Projects
      <div class="menu">
        <div class="header">Select Format</div>
        <div class="divider"></div>
        <div class="item" @click="exportProjectsToJson">
          <svg
            height="12"
            style="padding-left: 0.1rem; margin-right: 0.78571429rem"
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M208 32h-48a96 96 0 0 0-96 96v37.48a32.06 32.06 0 0 1-9.38 22.65L9.37 233.37a32 32 0 0 0 0 45.26l45.25 45.25A32 32 0 0 1 64 346.51V384a96 96 0 0 0 96 96h48a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-48a32 32 0 0 1-32-32v-37.48a96 96 0 0 0-28.13-67.89L77.25 256l22.63-22.63A96 96 0 0 0 128 165.48V128a32 32 0 0 1 32-32h48a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm358.63 201.37l-45.25-45.24a32.06 32.06 0 0 1-9.38-22.65V128a96 96 0 0 0-96-96h-48a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h48a32 32 0 0 1 32 32v37.47a96 96 0 0 0 28.13 67.91L498.75 256l-22.62 22.63A96 96 0 0 0 448 346.52V384a32 32 0 0 1-32 32h-48a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h48a96 96 0 0 0 96-96v-37.49a32 32 0 0 1 9.38-22.63l45.25-45.25a32 32 0 0 0 0-45.26z"
              class=""
            ></path>
          </svg>
          JSON
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
  data() {
    return {
      dimmerActive: false,
    };
  },
  mounted() {
    window.$('.ui.dropdown').dropdown();
  },
  methods: {
    async exportIngredientsToJson() {
      this.dimmerActive = true;

      // Get ingredients
      const ingredients = await this.$store.dispatch(
        'admin/downloadAllIngredients',
      );
      if (!ingredients) return;

      // Save file
      let blob = new Blob([JSON.stringify(ingredients)], {
        type: 'application/json',
      });
      const date = new Date();
      FileSaver.saveAs(
        blob,
        `Nutrispec - ingredients - ${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}.json`,
      );

      this.dimmerActive = false;
    },

    async exportIngredientsToCsv() {
      this.dimmerActive = true;

      // Get ingredients
      const ingredients = await this.$store.dispatch(
        'admin/downloadAllIngredients',
      );
      if (!ingredients) return;

      const clean = s => {
        let found = s.match(/[A-Za-z0-9À-ÖØ-öø-ÿ/ ().,-]/g);
        if (found != null) return found.join('');
        return '';
      };

      // Compile
      let data =
        'CODE,US EN Name,CA EN Name,CA FR Name,EU EN Name,EU FR Name,QTY\n';
      ingredients.forEach(ingredient => {
        data += ingredient.code + ',';
        ingredient.components.forEach((component, i) => {
          if (i != 0) data += ',';
          data += `"${clean(component.usEnName)}","${clean(
            component.caEnName,
          )}","${clean(component.caFrName)}","${clean(
            component.euEnName,
          )}","${clean(component.euFrName)}","${component.qty}"\n`;
        });
      });

      // Save file
      let blob = new Blob([data], {
        type: 'text/csv;charset=utf-8',
      });
      const date = new Date();
      FileSaver.saveAs(
        blob,
        `Ingredients Export - ${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}`,
      );

      this.dimmerActive = false;
    },

    async exportProjectsToJson() {
      this.dimmerActive = true;

      // Get projects
      const projects = await this.$store.dispatch('admin/downloadAllProjects');
      if (!projects) return;

      // Save file
      let blob = new Blob([JSON.stringify(projects)], {
        type: 'application/json',
      });
      const date = new Date();
      FileSaver.saveAs(
        blob,
        `Nutrispec - projects - ${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}.json`,
      );

      this.dimmerActive = false;
    },
  },
};
</script>

<style scoped>
p {
  color: #878787;
}
</style>