<template>
  <div class="ui dimmer" :class="{ active: isSaving || isExporting }">
    <div class="ui text loader">
      {{ isSaving ? 'Saving' : 'Generating Document' }}
    </div>
  </div>

  <div class="small ui stackable menu">
    <a class="item" @click="closeProject">
      <i class="close icon"></i>
      Close
    </a>
    <a
      v-if="!isViewOnly"
      class="item"
      :class="{ loading: isSaving, disabled: components.length == 0 }"
      @click="components.length == 0 ? '' : saveProject()"
    >
      <i class="save icon"></i>
      Save
    </a>
    <a class="ui simple dropdown item">
      <i class="icon">
        <svg
          width="12"
          transform="translate(0,-1)"
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            fill="currentColor"
            d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"
          ></path>
        </svg>
      </i>
      Export
      <i class="dropdown icon"></i>
      <div class="menu">
        <div
          v-for="template in regionSettings.templates"
          :key="template"
          class="item"
          @click="exportProject(template)"
        >
          {{ template.charAt(0).toUpperCase() + template.substring(1) }}
        </div>
      </div>
    </a>
    <a class="item" @click="openFileInfoModal">
      <i class="file alternate icon"></i>
      File Info
    </a>

    <div class="right menu">
      <a
        v-if="hasMissingComponents"
        class="item red"
        @click="openComponentMissingModal"
      >
        <i class="yellow exclamation circle icon"></i>
        Missing Components
      </a>
      <div v-if="isViewOnly" class="item">
        <div class="ui black label">
          Project By:
          <div class="detail">
            {{ author.fullName }}
          </div>
        </div>
      </div>
      <div v-if="windowWidth > 900" class="item">
        <i
          class="flag"
          :class="country"
          :title="
            country == 'ca'
              ? 'Canada'
              : country == 'us'
              ? 'United States'
              : country == 'eu'
              ? 'Europe'
              : 'Unknown Country'
          "
        ></i>
      </div>
    </div>
  </div>

  <FileInfoModal />
</template>

<script>
import FileInfoModal from '@/components/project/builder/FileInfoModal';
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  components: { FileInfoModal },
  data() {
    return {
      isSaving: false,
      isExporting: false,
    };
  },
  computed: {
    ...mapGetters('project', ['isViewOnly', 'hasMissingComponents']),
    ...mapState(['windowWidth']),
    ...mapState('project', [
      'author',
      'country',
      'components',
      'regionSettings',
    ]),
  },
  methods: {
    ...mapMutations('project', [
      'closeProject',
      'openComponentMissingModal',
      'openFileInfoModal',
    ]),
    async saveProject() {
      this.isSaving = true;
      await this.$store.dispatch('project/saveProject');
      this.isSaving = false;
    },
    async exportProject(template) {
      this.isExporting = true;
      await this.$store.dispatch('project/exportProject', { template });
      this.isExporting = false;
    },
  },
};
</script>

<style scoped>
.red.item {
  color: #eab30e !important;
}
.red.item:hover {
  color: #eab30e !important;
  background-color: rgb(255, 248, 219) !important;
}
</style>
