<template>
  <div class="ui segment">
    <h3>Active Sessions</h3>
    <BarLoader v-if="!activeSessions || activeSessions.length == 0" />
    <table v-else class="ui small compact celled unstackable table">
      <thead>
        <th class="four wide">Full Name</th>
        <th class="twelve wide">Info</th>
      </thead>
      <tbody>
        <tr v-for="session in activeSessions" :key="session.userId">
          <td>
            <p>{{ session.user.fullName }}</p>
            <button
              class="ui button mini basic compact"
              @click="closeSession(session._id)"
              :class="{
                disabled: isRemoving,
                loading: isRemoving == session._id,
              }"
            >
              Logout
            </button>
          </td>
          <td>
            <p><i>Created at:</i> {{ session.createdAt }}</p>
            <p><i>User Agent:</i> {{ session.userAgent }}</p>
            <p><i>IP address:</i> {{ session.ipv4 }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      isRemoving: null,
    };
  },
  computed: {
    ...mapState('admin', ['activeSessions']),
  },
  created() {
    this.$store.dispatch('admin/getActiveSessions');
  },
  methods: {
    async closeSession(id) {
      this.isRemoving = id;
      await this.$store.dispatch('admin/closeSession', { id });
      this.isRemoving = null;
    },
  },
};
</script>

<style scoped>
.segment {
  overflow: auto;
  scrollbar-width: thin;
  max-height: 70vh;
}
</style>
