<template>
  <table class="ui small compact striped table">
    <thead>
      <tr>
        <th colspan="3">Nutritionals | Per {{ recipeInfo.gramWeight }}g</th>
      </tr>
      <tr v-if="!isViewOnly">
        <th colspan="2">
          <button class="ui basic mini button" @click="showAllNutrients">
            Include All
          </button>
          <button class="ui basic mini button" @click="showMinNutrients">
            Include Minimum
          </button>
        </th>
        <th>Include</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="nutrient in regionNutrients" :key="nutrient.name">
        <td>{{ nutrient.name }}</td>
        <td>{{ nutrient.qty }}</td>
        <td>
          <div
            class="ui slider fitted checkbox"
            :class="{ disabled: nutrient.required }"
          >
            <input
              type="checkbox"
              :disabled="nutrient.required || isViewOnly"
              :checked="nutrient.show || nutrient.required"
              @change="toggleNutrient({ name: nutrient.name })"
            />
            <label></label>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  computed: {
    ...mapState('project', ['recipeInfo']),
    ...mapGetters('project', ['regionNutrients', 'isViewOnly']),
  },
  methods: {
    ...mapMutations('project', [
      'toggleNutrient',
      'showAllNutrients',
      'showMinNutrients',
    ]),
  },
};
</script>

<style scoped>
td {
  font-size: 0.8rem;
}
table {
  margin-bottom: 1rem !important;
}
</style>
