<template>
  <div class="loader" :class="{ center }">
    <div
      class="circle"
      :style="{
        borderTop: `3px solid ${color1}`,
        borderRight: `3px solid ${color1}`,
        borderBottom: `3px solid ${color1}`,
        borderLeft: `3px solid ${color2}`,
        height: `${size}px`,
        width: `${size}px`,
      }"
    ></div>
  </div>
  <h4 v-if="header" :class="{ center }">{{ header }}</h4>
</template>

<script>
export default {
  props: {
    color1: {
      type: String,
      default: 'rgba(128, 128, 128, 0.25)',
    },
    color2: {
      type: String,
      default: 'rgba(36, 45, 60, 0.75)',
    },
    header: {
      type: String,
      default: '',
    },
    center: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 30,
    },
  },
};
</script>

<style scoped>
.loader.center,
h4 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: 25px;
}
.circle {
  border-radius: 50%;
  animation: loader 1s cubic-bezier(0.41, 0.25, 0.32, 0.83) infinite;
  display: flex;
}
@keyframes loader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
</style>
