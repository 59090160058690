<template>
  <div class="ui main container">
    <slot><h1>No Content</h1></slot>
  </div>
</template>

<style scoped>
@media screen and (min-width: 900px) {
  .main.container {
    padding-top: 2em;
    margin-left: 18em !important;
    margin-right: 3em !important;
    padding-bottom: 1rem;
    width: auto !important;
  }
}
</style>
