<template>
  <BaseModal
    :show="openModal == 'inviteUser'"
    @deny="this.$store.commit('admin/closeModal')"
  >
    <div class="ui content">
      <div class="ui form segment">
        <div class="two fields">
          <div
            class="field"
            :class="{ error: !fullNameValid }"
            @keyup="fullNameValid = true"
          >
            <div class="input">
              <label>Full Name</label>
              <input
                ref="fullName"
                :disabled="isGeneratingLink || isSendingEmail"
                type="text"
                placeholder="Min 5 characters"
                @keypress="isAllowedFullNameChar($event)"
              />
            </div>
          </div>
          <div
            class="field"
            :class="{ error: !usernameValid }"
            @keyup="usernameValid = true"
          >
            <div class="input">
              <label>Username</label>
              <input
                ref="username"
                :disabled="isGeneratingLink || isSendingEmail"
                type="text"
                placeholder="Min 5 characters"
                @keypress="isAllowedUsernameChar($event)"
              />
            </div>
          </div>
        </div>
        <div class="two fields">
          <div
            class="field"
            :class="{ error: !emailValid }"
            @keyup="emailValid = true"
          >
            <div class="input">
              <label>Email</label>
              <input
                ref="email"
                :disabled="isGeneratingLink || isSendingEmail"
                type="email"
                placeholder="ex: mkuhn@bariatrix.com"
              />
            </div>
          </div>
          <div class="field">
            <div class="input">
              <label>User Type</label>
              <select
                ref="clearanceLevel"
                class="ui fluid dropdown"
                :disabled="isGeneratingLink || isSendingEmail"
              >
                <option value="1" selected>User</option>
                <option value="2">Editor</option>
                <option value="3">Admin</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="ui segment">
        <div class="ui two column very relaxed stackable grid">
          <div class="column">
            <h2>Invite by email</h2>
            <button
              class="ui large fluid teal animated button"
              :class="{
                loading: isSendingEmail,
                disabled:
                  isSendingEmail || isGeneratingLink || inviteLink != null,
              }"
              @click="sendInviteEmail"
            >
              <div class="visible content">Send</div>
              <div class="hidden content">
                <i class="paper plane icon"></i>
              </div>
            </button>
          </div>

          <div class="column">
            <h2>Generate invite link</h2>
            <div v-if="inviteLink != null" class="ui action input fluid">
              <input id="invite-link" type="text" :value="inviteLink" />
              <button
                class="ui submit right teal labeled icon button"
                @click="copyLink"
              >
                <i class="copy icon"></i>
                Copy
              </button>
            </div>
            <button
              v-else
              class="ui large fluid animated teal button"
              :class="{
                loading: isGeneratingLink,
                disabled: isGeneratingLink || isSendingEmail,
              }"
              @click="generateInviteLink"
            >
              <div class="visible content">Generate</div>
              <div class="hidden content">
                <i class="cogs icon"></i>
              </div>
            </button>
          </div>
        </div>
        <div v-if="windowWidth > 800" class="ui vertical divider">Or</div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      isSendingEmail: false,
      isGeneratingLink: false,
      fullNameValid: true,
      usernameValid: true,
      emailValid: true,
    };
  },
  computed: {
    ...mapState('admin', ['openModal', 'inviteLink']),
    ...mapState(['windowWidth']),
  },
  methods: {
    isAllowedFullNameChar(event) {
      if (/^[0-9A-Za-zÀ-ÖØ-öø-ÿ- ]+$/.test(event.key)) return true;
      else event.preventDefault();
    },
    isAllowedUsernameChar(event) {
      if (/^[0-9a-z]+$/.test(event.key)) return true;
      else event.preventDefault();
    },
    async sendInviteEmail() {
      this.isSendingEmail = true;

      // Check form
      if (!this.validateUserInfo()) return (this.isSendingEmail = false);

      await this.$store.dispatch('admin/sendInviteEmail', {
        fullName: this.$refs.fullName.value,
        username: this.$refs.username.value,
        email: this.$refs.email.value,
        clearanceLevel: this.$refs.clearanceLevel.value,
        inviteType: 'email',
      });

      this.isSendingEmail = false;
    },
    async generateInviteLink() {
      this.isGeneratingLink = true;

      // Check form
      if (!this.validateUserInfo()) return (this.isGeneratingLink = false);

      await this.$store.dispatch('admin/generateInviteLink', {
        fullName: this.$refs.fullName.value,
        username: this.$refs.username.value,
        email: this.$refs.email.value,
        clearanceLevel: this.$refs.clearanceLevel.value,
        inviteType: 'link',
      });

      this.isGeneratingLink = false;
    },
    validateUserInfo() {
      const fullNameValid =
        this.$refs.fullName.value &&
        this.$refs.fullName.value.length > 5 &&
        this.$refs.fullName.value.trim().split(' ').length > 1;
      const usernameValid =
        this.$refs.username.value && this.$refs.username.value.length >= 4;

      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailValid =
        this.$refs.email.value && emailRegex.test(this.$refs.email.value);

      if (!fullNameValid) this.fullNameValid = false;
      if (!usernameValid) this.usernameValid = false;
      if (!emailValid) this.emailValid = false;

      return fullNameValid && usernameValid && emailValid;
    },
    copyLink() {
      var txt = document.getElementById('invite-link');
      txt.select();
      txt.setSelectionRange(0, 99999);
      document.execCommand('copy');
    },
  },
};
</script>
