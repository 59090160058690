<template>
  <ConfirmationModal
    :show="show"
    :confirm-loading="isLoading"
    @deny="closeModal"
    @confirm="sendPasswordReset"
  >
    <template #header>Password Reset</template>
    <template #meta>{{ user.email }}</template>
    <template #default>
      <h4>
        Are you sure you want to send a password reset to
        {{ user.fullName.split(' ')[0] }}?
      </h4>
      <p>
        A new password will be sent by email and the previous one will be
        deleted.
      </p>
    </template>
  </ConfirmationModal>
</template>

<script>
import { mapMutations } from 'vuex';
import ConfirmationModal from '@/components/ui/ConfirmationModal';
export default {
  components: { ConfirmationModal },
  props: {
    show: Boolean,
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { isLoading: false };
  },
  methods: {
    ...mapMutations('admin', ['closeModal']),
    async sendPasswordReset() {
      this.isLoading = true;
      await this.$store.dispatch('admin/sendPasswordReset', {
        id: this.user._id,
      });
      this.closeModal();
      this.isLoading = false;
    },
  },
};
</script>
