import router from '../../../router';

/**
 * Reset State
 * @param {object} state
 */
const reset = state => {
  state.id = null;
  state.name = null;
  state.author = null;
  state.country = null;
  state.files = {
    nutrients: null,
    recipe: null,
  };
  state.recipeInfo = null;
  state.foodlist = null;
  state.vitaminBlend = null;
  state.nutrients = null;

  state.components = [];
  state.ingredientStatement = [];
  state.allergensContains = [];
  state.allergensMayContain = [];

  state.reduceVitamins = false;
  state.pdcaas = 1;
  state.numServings = 1;
  state.servingIdentifier = 'bar';
  state.servingIdentifierFr = 'barre';
  state.servingSize = null;
  state.servingSizeInfo = null;
  state.servingsPerContainer = 1;
  state.foodCategory = { en: '', fr: '' };

  state.soiHeader = '';
  state.soiContent = '';
  state.soiHeaderFr = '';
  state.soiContentFr = '';
  state.shelfLife = '';
  state.hsCode = '';
  state.scoreEurope = '';
  state.packaging = '';
  state.productionOrigin = '';
  state.productionOriginFr = '';
  state.sampleCode = '';
  state.specificStatus = [];
  state.additionalAllergenStatement = '';
  state.additionalAllergenStatementFr = '';
  state.packagedInProtectiveAtmosphere = false;

  state.mealReplacementSources = [];

  state.sugarAlcohols = '0';

  state.instructions = '';
  state.instructionsFr = '';
  state.claims = [];
  state.additionalClaims = [];

  state.microbiologicalCriteria = {
    ebG: '',
    ebT: '',
    famG: '',
    famT: '',
    yeastG: '',
    yeastT: '',
    moldG: '',
    moldT: '',
    salmonellaG: '',
    salmonellaT: '',
    eColiG: '',
    eColiT: '',
    bcG: '',
    bcT: '',
    sacG: '',
    sacT: '',
    cpG: '',
    cpT: '',
  };

  state.aminogram = {
    lIsoleucine: '',
    lLeucine: '',
    lLysine: '',
    lMethionineLCystine: '',
    lPhenylalanineLTyrosine: '',
    lThreonine: '',
    lTryptophane: '',
    lValine: '',
    histidine: '',
  };

  state.regionSettings = {
    templates: null,
    nutrients: null,
    allergensContains: null,
    allergensMayContain: null,
    foodCategories: null,
    specificStatus: null,
    claims: null,
  };
  state.selectedComponents = [];
  state.ingredientStatementItemToEdit = null;
  state.presentAllergens = null;

  state.openModal = null;
};

export default {
  /**
   * Close project
   * @param {object} state
   * @param {object} payload Use option skipRedirect to not redirect to New Project page
   */
  closeProject: (state, payload) => {
    reset(state);
    if (payload && payload.skipRedirect) return;
    router.replace({ name: 'New Project' });
  },

  /**
   * Show/Hide nutrient
   */
  toggleNutrient: (state, payload) => {
    const nutrient = state.nutrients.find(e => e.name == payload.name);
    nutrient.show = !nutrient.show;
  },

  /**
   * Toggle reduce vitamins on/off
   */
  toggleReduceVitamins: state => {
    state.reduceVitamins = !state.reduceVitamins;
  },

  /**
   * Set serving size
   */
  setServingSize: (state, payload) => {
    state.servingSize = payload.value;
  },

  /**
   * Set amount of servings
   */
  setNumServings: (state, payload) => {
    state.numServings = payload.value;
  },

  /**
   * Set serving identifier
   */
  setServingIdentifier: (state, payload) => {
    state.servingIdentifier = payload.value;
  },

  /**
   * Set serving identifier (french)
   */
  setServingIdentifierFr: (state, payload) => {
    state.servingIdentifierFr = payload.value;
  },

  /**
   * Set serving size info
   */
  setServingSizeInfo: (state, payload) => {
    state.servingSizeInfo = payload.value;
  },

  /**
   * Set servings per container
   */
  setServingsPerContainer: (state, payload) => {
    state.servingsPerContainer = payload.value;
  },

  /**
   * Set PDCAAS
   */
  setPdcaas: (state, payload) => {
    state.pdcaas = payload.value;
  },

  /**
   * Set statement of identity header
   */
  setSoiHeader: (state, payload) => {
    state.soiHeader = payload.value;
  },

  /**
   * Set statement of identity content
   */
  setSoiContent: (state, payload) => {
    state.soiContent = payload.value;
  },

  /**
   * Set statement of identity header (french)
   */
  setSoiHeaderFr: (state, payload) => {
    state.soiHeaderFr = payload.value;
  },

  /**
   * Set statement of identity content (french)
   */
  setSoiContentFr: (state, payload) => {
    state.soiContentFr = payload.value;
  },

  /**
   * Set shelf life
   */
  setShelfLife: (state, payload) => {
    state.shelfLife = payload.value;
  },

  /**
   * Set HS code
   */
  setHsCode: (state, payload) => {
    state.hsCode = payload.value;
  },

  /**
   * Set score europe
   */
  setScoreEurope: (state, payload) => {
    state.scoreEurope = payload.value;
  },

  /**
   * Set packaging number
   */
  setPackaging: (state, payload) => {
    state.packaging = payload.value;
  },

  /**
   * Set production origin
   */
  setProductionOrigin: (state, payload) => {
    state.productionOrigin = payload.value;
  },

  /**
   * Set production origin (french)
   */
  setProductionOriginFr: (state, payload) => {
    state.productionOriginFr = payload.value;
  },

  /**
   * Set sample code
   */
  setSampleCode: (state, payload) => {
    state.sampleCode = payload.value;
  },

  /**
   * Set instructions
   */
  setInstructions: (state, payload) => {
    state.instructions = payload.value;
  },

  /**
   * Set instructions (french)
   */
  setInstructionsFr: (state, payload) => {
    state.instructionsFr = payload.value;
  },

  /**
   * Set microbiological criteria
   */
  setMicrobiologicalCriteria: (state, payload) => {
    state.microbiologicalCriteria[payload.field] = payload.value;
  },

  /**
   * Set food category
   */
  setFoodCategory: (state, payload) => {
    state.foodCategory = state.regionSettings.foodCategories.find(
      e => e.en == payload.value,
    );
  },

  /**
   * Set amino acid
   */
  setAminoAcid: (state, payload) => {
    state.aminogram[payload.field] = payload.value;
  },

  /**
   * Set additional allergen statement
   */
  setAdditionalAllergenStatement: (state, payload) => {
    state.additionalAllergenStatement = payload.value;
  },

  /**
   * Set additional allergen statement (french)
   */
  setAdditionalAllergenStatementFr: (state, payload) => {
    state.additionalAllergenStatementFr = payload.value;
  },

  /**
   * Set sugar alcohols
   */
  setSugarAlcohols: (state, payload) => {
    state.sugarAlcohols = payload.value;
  },

  /**
   * Show all nutrients
   */
  showAllNutrients: state => {
    state.nutrients.forEach(nutrient => (nutrient.show = true));
  },

  /**
   * Hide all non-required nutrients
   */
  showMinNutrients: state => {
    state.nutrients.forEach(nutrient => {
      if (!nutrient.required) nutrient.show = false;
    });
  },

  /**
   * Add/Remove allergen from contains list
   */
  toggleAllergenContains: (state, payload) => {
    const index = state.allergensContains.indexOf(payload.allergen);
    if (index < 0) state.allergensContains.push(payload.allergen);
    else state.allergensContains.splice(index, 1);
  },

  /**
   * Add/Remove allergen from may contain list
   */
  toggleAllergenMayContain: (state, payload) => {
    const index = state.allergensMayContain.indexOf(payload.allergen);
    if (index < 0) state.allergensMayContain.push(payload.allergen);
    else state.allergensMayContain.splice(index, 1);
  },

  /**
   * Add/Remove status from specific status list
   */
  toggleSpecificStatus: (state, payload) => {
    const index = state.specificStatus.indexOf(payload.status);
    if (index < 0) state.specificStatus.push(payload.status);
    else state.specificStatus.splice(index, 1);
  },

  /**
   * Add/Remove sulfites from ingredient statement
   */
  toggleShowSulfites: state => {
    const index = state.ingredientStatement.findIndex(e =>
      e.cid.includes('SULFITE'),
    );
    if (index < 0)
      state.ingredientStatement.push({
        code: null,
        name: 'sulfites',
        nameFr: 'sulfites',
        qty: 0,
        cid: ['SULFITE'],
      });
    else state.ingredientStatement.splice(index, 1);
  },

  /**
   * Select/Deselect component
   */
  toggleSelectedComponent: (state, payload) => {
    const index = state.selectedComponents.indexOf(payload.component);
    if (index < 0) state.selectedComponents.push(payload.component);
    else state.selectedComponents.splice(index, 1);
  },

  /**
   * Select/Deselect claim
   */
  toggleClaim: (state, payload) => {
    const index = state.claims.findIndex(e => e.en == payload.claim.en);
    if (index < 0) state.claims.push(payload.claim);
    else state.claims.splice(index, 1);
  },

  /**
   * Turn packaged in protective atmosphere on/off
   */
  togglePackagedInProtectiveAtmosphere: state => {
    state.packagedInProtectiveAtmosphere = !state.packagedInProtectiveAtmosphere;
  },

  /**
   * Select/Deselect additional claims
   */
  toggleAdditionalClaims: (state, payload) => {
    const index = state.additionalClaims.indexOf(payload.value);
    if (index < 0) state.additionalClaims.push(payload.value);
    else state.additionalClaims.splice(index, 1);
  },

  /**
   * Toggle meal replacement source (recipe <-> vitaminBlend)
   */
  toggleMealReplacementSource: (state, payload) => {
    const item = state.mealReplacementSources.find(e => e.name == payload.name);
    if (item.source == 'recipe') item.source = 'vitaminBlend';
    else item.source = 'recipe';
  },

  /**
   * Add component to ingredient statement
   */
  addToIngredientStatement: (state, payload) => {
    state.ingredientStatement.push({
      code: payload.code,
      name: payload.name,
      nameFr: payload.nameFr,
      qty: payload.qty,
      cid: payload.cid,
    });
    state.ingredientStatement.sort((a, b) => b.qty - a.qty);
  },

  /**
   * Remove item from ingredient statement
   */
  removeFromIngredientStatement: (state, payload) => {
    const index = state.ingredientStatement.findIndex(item =>
      item.cid.every(e => payload.cid.includes(e)),
    );
    if (index >= 0) state.ingredientStatement.splice(index, 1);
  },

  /**
   * Open modal to edit ingredient statement item
   */
  editIngredientStatementItem: (state, payload) => {
    const item = state.ingredientStatement.find(item =>
      item.cid.every(e => payload.cid.includes(e)),
    );
    if (!item) return;
    state.ingredientStatementItemToEdit = item;
    state.openModal = 'editComponentModal';
  },

  /**
   * Push edits to ingredient statement item
   */
  pushEditedComponent: (state, payload) => {
    const index = state.ingredientStatement.findIndex(
      item => item == state.ingredientStatementItemToEdit,
    );
    if (index < 0) return alert('Could not edit - Something went wrong');
    state.ingredientStatement[index].name = payload.name;
    state.ingredientStatement[index].nameFr = payload.nameFr;
  },

  /**
   * Clear ingredient statement
   */
  clearIngredientStatement: state => {
    state.ingredientStatement = [];
  },

  /**
   * Clear selected components
   */
  clearSelectedComponents: state => {
    state.selectedComponents = [];
  },

  /**
   * Close modal
   */
  closeProjectModal: state => {
    state.openModal = null;
    state.ingredientStatementItemToEdit = null;
  },

  /* Open Modals */
  openAddComponentModal: state => {
    state.openModal = 'addComponentModal';
  },
  openGroupComponentsModal: state => {
    state.openModal = 'groupComponentsModal';
  },
  openComponentMissingModal: state => {
    state.openModal = 'componentMissingModal';
  },
  openFileInfoModal: state => {
    state.openModal = 'fileInfoModal';
  },

  /**
   * Reset state to defaults
   */
  reset: state => {
    reset(state);
  },
};
