<template>
  <div class="bg">
    <h1 class="header">Nutrispec</h1>
    <SpinLoader
      color1="rgba(255, 255, 255, 0.7)"
      color2="rgba(255, 255, 255, 0.3)"
    />
  </div>
</template>

<style scoped>
h1 {
  color: white;
}
.bg {
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  background-color: rgb(12, 54, 79);
  position: fixed;
}
h1 {
  font-size: 2.2rem;
  font-family: 'sophie-bold', sans-serif;
  font-weight: 500;
}
</style>
