<template>
  <tr>
    <td>
      <div>
        {{ item.name }}
      </div>
      <a class="ui mini label" @click="isEditor ? editDv() : ''">
        Daily Value
        <div v-if="item.dailyValue.qty" class="detail">
          {{ item.dailyValue.qty }}{{ item.dailyValue.measure }}
        </div>
        <div v-else class="detail">N/A</div>
      </a>
    </td>
    <td>
      <div class="ui toggle checkbox">
        <input
          type="checkbox"
          :disabled="!isEditor || isLoading"
          :checked="item.required"
          @change="updateRequiredOnLabel"
        />
        <label></label>
      </div>
    </td>
    <td>
      <table class="ui small compact collapsing unstackable celled table">
        <thead>
          <tr>
            <th>Min</th>
            <th>Max</th>
            <th>Operation</th>
            <th v-if="isEditor">
              <button
                class="ui mini secondary compact basic button"
                @click="
                  openModal({ name: 'addQtyRule', nutrientName: item.name })
                "
              >
                Add Rule
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rule, index) in item.qtyRounding" :key="index">
            <td>{{ rule.min }}</td>
            <td>{{ rule.max }}</td>
            <td>{{ ruleText(rule) }}</td>
            <td v-if="isEditor">
              <button
                class="ui mini compact negative button"
                :class="{ disabled: isDeleting }"
                @click="deleteRule(index, 'qty', rule.min, rule.max)"
              >
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
    <td>
      <table class="ui small compact collapsing unstackable celled table">
        <thead>
          <tr>
            <th>Min</th>
            <th>Max</th>
            <th>Operation</th>
            <th v-if="isEditor">
              <button
                class="ui mini secondary compact basic button"
                @click="
                  openModal({ name: 'addDvRule', nutrientName: item.name })
                "
              >
                Add Rule
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rule, index) in item.dvRounding" :key="index">
            <td>{{ rule.min }}</td>
            <td>{{ rule.max }}</td>
            <td>{{ ruleText(rule) }}</td>
            <td v-if="isEditor">
              <button
                class="ui mini negative compact button"
                :class="{ disabled: isDeleting }"
                @click="deleteRule(index, 'dv', rule.min, rule.max)"
              >
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDeleting: false,
      isLoading: false,
    };
  },
  computed: { ...mapGetters(['isEditor']) },
  methods: {
    ...mapMutations('rules', ['openModal']),
    ruleText(rule) {
      if (rule.roundTo !== undefined) return `Round to: ${rule.roundTo}`;
      if (rule.roundNearest !== undefined)
        return `Round to nearest: ${rule.roundNearest}`;
      if (rule.showText !== undefined) return `Show text: ${rule.showText}`;
    },
    async deleteRule(index, type, min, max) {
      this.isDeleting = true;
      await this.$store.dispatch('rules/deleteRule', {
        index,
        type,
        min,
        max,
        nutrientName: this.item.name,
      });
      this.isDeleting = false;
    },
    async updateRequiredOnLabel() {
      this.isLoading = true;
      await this.$store.dispatch('rules/changeRequiredOnLabel', {
        required: !this.item.required,
        nutrientName: this.item.name,
      });
      this.isLoading = false;
    },
    editDv() {
      this.$store.commit('rules/openModal', {
        name: 'editDv',
        nutrientName: this.item.name,
      });
    },
  },
};
</script>

<style scoped>
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #21d070 !important;
}
table td {
  font-size: 0.9rem;
}
</style>
