export default {
  /**
   * Get nutrients to use for this region/country
   */
  regionNutrients: state => {
    return state.nutrients.filter(nutrient => nutrient.use);
  },

  /**
   * Get select nutrient
   */
  getNutrient: state => (
    name,
    servingSize = state.servingSize,
    multiplier = 1,
  ) => {
    // Get nutrient
    const found = state.nutrients.find(
      nutrient => nutrient.name == name.trim(),
    );
    if (!found) return null;

    // Deep copy
    let nutrient = JSON.parse(JSON.stringify(found));

    // Get nutrient settings
    const nutrientSettings = state.regionSettings.nutrients.find(
      nut => nut.name == nutrient.name,
    );
    if (!nutrientSettings) return null;

    // Set qty
    const unroundedQty =
      nutrientSettings.isVitamin && state.reduceVitamins
        ? nutrient.qty *
          0.8 *
          (servingSize / state.recipeInfo.gramWeight) *
          multiplier
        : nutrient.qty *
          (servingSize / state.recipeInfo.gramWeight) *
          multiplier;

    // Set default rounded qty
    nutrient.roundedQty = unroundedQty;

    // Round QTY
    for (let i = 0; i < nutrientSettings.qtyRounding.length; i++) {
      const rule = nutrientSettings.qtyRounding[i];
      if (rule.min <= unroundedQty && rule.max > unroundedQty) {
        if (rule.roundNearest !== undefined && rule.roundNearest > 0)
          nutrient.roundedQty =
            Math.round(
              rule.roundNearest *
                Math.round(
                  (unroundedQty * 10000000) / (rule.roundNearest * 10000000),
                ) *
                10000000,
            ) / 10000000;
        else if (rule.roundTo !== undefined && rule.roundTo >= 0) {
          nutrient.roundedQty = rule.roundTo;
        } else if (rule.showText) {
          nutrient.roundedQty = rule.showText;
          nutrient.percentDv = 0;
          nutrient.roundedPercentDv = '0%';
          return nutrient;
        }
      }
    }

    // Round DV
    if (nutrientSettings.dailyValue.qty) {
      // Set % DV - Add PDCAAS if protein
      if (nutrient.name == 'Protein')
        nutrient.percentDv =
          ((nutrient.roundedQty * state.pdcaas) /
            nutrientSettings.dailyValue.qty) *
          100;
      else
        nutrient.percentDv =
          (nutrient.roundedQty / nutrientSettings.dailyValue.qty) * 100;

      // Set default
      nutrient.roundedPercentDv = nutrient.percentDv;

      // Set rounded % DV
      nutrientSettings.dvRounding.forEach(rule => {
        if (rule.min <= nutrient.percentDv && rule.max > nutrient.percentDv) {
          if (rule.roundNearest !== undefined && rule.roundNearest > 0)
            nutrient.roundedPercentDv =
              Math.round(
                rule.roundNearest *
                  Math.round(nutrient.percentDv / rule.roundNearest) *
                  10000000,
              ) /
                10000000 +
              '%';
          else if (rule.roundTo !== undefined && rule.roundTo >= 0)
            nutrient.roundedPercentDv = rule.roundTo + '%';
        }
      });
    } else nutrient.percentDv = null;

    // CA rule
    if (state.country == 'ca' && nutrient.roundedPercentDv == '0%')
      nutrient.roundedPercentDv = null;

    // PDCAAS rule
    if (state.pdcaas == 0 && nutrient.name == 'Protein')
      nutrient.roundedPercentDv = null;

    return nutrient;
  },

  /**
   * Check if project is owned by current user
   * @returns {boolean}
   */
  isViewOnly: (state, _getters, _rootState, rootGetters) => {
    return rootGetters.activeUser.username != state.author.username;
  },

  /**
   * Is project ready to be displayed - Have all items been loaded
   * @param {object} state
   * @returns {boolean}
   */
  projectReady: state => {
    const ready =
      state.author &&
      state.country &&
      state.recipeInfo &&
      state.foodlist &&
      state.nutrients &&
      state.regionSettings &&
      state.regionSettings.nutrients &&
      state.regionSettings.allergensContains &&
      state.regionSettings.allergensMayContain;
    return !!ready;
  },

  /**
   * Get sulfite ppm
   * @returns {number}
   */
  sulfitePpm: state => {
    const sulfites = state.nutrients.find(
      e => e.name == 'Sulfites (see 100g to reflect ppm)',
    );
    if (!sulfites) return 0;
    else {
      return (
        Math.round((sulfites.qty / state.servingSize) * 100 * 10000) / 10000
      );
    }
  },

  /**
   * Get component language/languages
   */
  componentLanguages: state => {
    switch (state.country) {
      case 'us':
        return ['usEnName'];

      case 'ca':
        return ['caEnName', 'caFrName'];

      case 'eu':
        return ['euEnName', 'euFrName'];

      default:
        return null;
    }
  },

  /**
   * Check if selected region has more than one langauge
   * @returns {boolean}
   */
  hasSecondLanguage: state => {
    return state.country == 'ca' || state.country == 'eu';
  },

  /**
   * Check if components list has a ingredient not found in db
   * @returns {boolean}
   */
  hasMissingComponents: state => {
    return state.components.some(component => !component.name);
  },

  /**
   * Get missing component codes
   * @returns {Array}
   */
  missingComponentCodes: state => {
    let codes = [];
    state.components
      .filter(component => !component.name)
      .forEach(component => {
        component.code.forEach(code => codes.push(code));
      });
    return codes;
  },
};
