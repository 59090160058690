<template>
  <span>
    <TopHeader header-title="New Project" show-user-info />
    <CenterContainer>
      <div class="middle">
        <h2 class="ui center aligned icon header" style="margin-bottom: 1em">
          <i class="circular book icon"></i>
          Project Builder
        </h2>
        <div class="ui form tc">
          <a @click="infoModalIsOpen = true">What files should I upload?</a>

          <FileSelector
            button-text="Select Recipe & Nutrients File"
            :file-present="recipeFile != null"
            :disabled="isLoading"
            @file-change="checkFile('recipeFile', $event)"
          />

          <FileSelector
            v-show="country == 'ca'"
            button-text="Select Spreadsheet File"
            :file-present="multicolumnFile != null && country == 'ca'"
            :disabled="isLoading || country != 'ca'"
            @file-change="checkFile('multicolumnFile', $event)"
          />

          <div class="ui form">
            <div class="inline fields">
              <div class="field">
                <div class="ui radio checkbox">
                  <input v-model="country" value="ca" type="radio" />
                  <label>Canada</label>
                </div>
              </div>
              <div class="field">
                <div class="ui radio checkbox">
                  <input v-model="country" value="eu" type="radio" />
                  <label>Europe</label>
                </div>
              </div>
              <div class="field">
                <div class="ui radio checkbox">
                  <input v-model="country" value="us" type="radio" />
                  <label>United States</label>
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <button
              class="ui black fluid submit button"
              :class="{
                loading: isLoading,
                disabled: !recipeFile || !country,
              }"
              @click="start"
            >
              Start
            </button>
          </div>
        </div>
      </div>
    </CenterContainer>

    <BaseModal :show="infoModalIsOpen" @deny="infoModalIsOpen = false">
      <div class="overflow">
        <div class="ui vertical segment title">
          <h2>Files to export from Genesis</h2>
        </div>
        <div class="ui vertical segment">
          <h3>Recipe & Nutrients file</h3>
          <img
            class="ui big image"
            src="@/assets/recipe-file-export.png"
            alt="Export recipe file"
          />
        </div>
        <div class="ui vertical segment">
          <h3>Spreadsheet file</h3>
          <p>
            The multicolumn file is optional and is used in the Meal Replacement
            tab. To export it go to spreadsheet report and right click > Export
            Report to File.
          </p>
          <img
            class="ui large image"
            src="@/assets/multicolumn-file-export.png"
            alt="Export multicolumn file"
          />
        </div>
      </div>
    </BaseModal>
  </span>
</template>

<script>
import FileSelector from '@/components/ui/FileSelector';
export default {
  components: {
    FileSelector,
  },
  data() {
    return {
      isLoading: false,
      recipeFile: null,
      multicolumnFile: null,
      country: this.$store.state.organization.defaultRegion,
      infoModalIsOpen: false,
    };
  },
  methods: {
    checkFile(target, file) {
      // Check file type
      if (file.type != 'text/plain') {
        return this.$store.commit('showAlert', {
          type: 'info',
          header: 'Wrong file type',
          message: `The file you uploaded is was supposed to be a text file but instead was: ${file.type}`,
        });
      }

      // Check file size
      const maxFilesize = 100 * 1000;
      if (file.size > maxFilesize) {
        return this.$store.commit('showAlert', {
          type: 'info',
          header: 'Max file size exceeded',
          message: `Max filesize is ${maxFilesize / 1000000}MB. 
          The file you uploaded is ${file.size / 1000000}MB.`,
        });
      }

      this[target] = file;
    },

    async start() {
      this.isLoading = true;
      await this.$store.dispatch('project/createProject', {
        recipeFile: this.recipeFile,
        multicolumnFile: this.multicolumnFile,
        country: this.country,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.middle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80vh;
}
a {
  cursor: pointer;
}
.overflow {
  max-height: 80vh;
  overflow: scroll;
}
</style>
