<template>
  <tr>
    <td>
      <router-link
        :to="{
          name: 'Open Project',
          params: {
            id: project._id,
          },
        }"
      >
        {{ project.name }}
      </router-link>
    </td>
    <td>{{ parsedDate }}</td>
    <td><i class="flag" :class="project.country"></i></td>
    <td>
      <button
        v-if="project.author.username == activeUser.username"
        class="ui icon button"
        :class="{ loading: isDeleting }"
        @click="deleteProject"
      >
        <i class="trash alternate icon"></i>
      </button>
      <span v-else>
        {{ project.author.fullName }}
      </span>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDeleting: false,
    };
  },
  computed: {
    ...mapGetters(['activeUser']),
    parsedDate() {
      const parsedDate = new Date(this.project.lastEdit);
      const time = `${parsedDate.getHours()}:${
        parsedDate.getMinutes() < 10
          ? '0' + parsedDate.getMinutes()
          : parsedDate.getMinutes()
      }`;
      const day = `${parsedDate.getDate()}`;
      const month = `${parsedDate.getMonth() + 1}`;
      const year = `${parsedDate.getFullYear()}`;
      return `${day}/${month}/${year} - ${time}`;
    },
  },
  methods: {
    async deleteProject() {
      this.isDeleting = true;
      await this.$store.dispatch('dashboard/deleteProject', {
        id: this.project._id,
        name: this.project.name,
        country: this.project.country,
      });
      this.isDeleting = false;
    },
  },
};
</script>
