<template>
  <div>
    <TopHeader header-title="Ingredient Database" show-user-info />
    <CenterContainer>
      <SearchSegment />

      <div class="ui segment">
        <h2>Search results</h2>
        <BarLoader v-if="isLoadingResults" />
        <h4 v-else-if="!searchResults || searchResults.length == 0">
          Nothing to show
        </h4>
        <div v-else class="overflow">
          <table class="ui small structured celled compact table">
            <thead>
              <tr>
                <th>Code</th>
                <th>US EN Name</th>
                <th>CA EN Name</th>
                <th>CA FR Name</th>
                <th>EU EN Name</th>
                <th>EU FR Name</th>
                <th>QTY</th>
              </tr>
            </thead>
            <tbody>
              <IngredientView
                v-for="ingredient in searchResults"
                :key="ingredient._id"
                :ingredient="ingredient"
              ></IngredientView>
            </tbody>
          </table>
        </div>
      </div>

      <ConfirmationModal
        :show="deleteModalOpen"
        :confirm-loading="isDeletingIngredient"
        deny-color="grey"
        confirm-color="red"
        @confirm="deleteIngredient"
        @deny="closeModal"
      >
        <template #header>
          Are you sure you want to delete {{ ingredientToDelete }}
        </template>
        <template #default>This action cannot be undone</template>
        <template #deny-button>Cancel</template>
        <template #confirm-button>Delete</template>
      </ConfirmationModal>
      <IngredientModal />
    </CenterContainer>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import SearchSegment from '@/components/ingredient/SearchSegment';
import IngredientView from '@/components/ingredient/IngredientView';
import IngredientModal from '@/components/ingredient/IngredientModal';

export default {
  components: {
    SearchSegment,
    IngredientView,
    IngredientModal,
  },
  data() {
    return {
      isDeletingIngredient: false,
    };
  },
  computed: {
    ...mapState('ingredient', [
      'searchResults',
      'ingredientToDelete',
      'deleteModalOpen',
      'isLoadingResults',
      'ingredient',
    ]),
  },
  methods: {
    ...mapActions('ingredient', ['getSearchResults']),
    ...mapMutations('ingredient', ['openIngredientModal', 'closeModal']),
    async deleteIngredient() {
      this.isDeletingIngredient = true;
      await this.$store.dispatch('ingredient/deleteIngredient');
      this.isDeletingIngredient = false;
    },
  },
};
</script>

<style scoped>
table {
  word-wrap: break-word;
}
.overflow {
  overflow: auto;
  max-height: 60vh;
  scrollbar-width: thin;
}
</style>
