import request from '../../../request';

export default {
  /**
   * Get user owned projects
   */
  getPersonalProjects: async context => {
    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/projects',
      });
      context.commit('setProjects', { projects: response.data.projects });
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Search for project by name
   */
  projectSearch: async (context, payload) => {
    // If no input show user projects
    if (payload.name.trim().length == 0)
      context.dispatch('getPersonalProjects');

    if (payload.name.trim().length < 3) return;

    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/search/projects',
        params: { name: payload.name },
      });
      context.commit('setProjects', { projects: response.data.projects });
    } catch (err) {
      console.error(err);
    }
  },

  deleteProject: async (context, payload) => {
    try {
      await request.sendRequest(context, {
        method: 'DELETE',
        url: `/api/projects/${payload.id}`,
      });
      if (
        context.rootState.project.name == payload.name &&
        context.rootState.project.country == payload.country
      ) {
        context.commit(
          'project/closeProject',
          { skipRedirect: true },
          { root: true },
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      // Remove deleted project from list
      context.state.displayedProjects = context.state.displayedProjects.filter(
        e => e._id != payload.id,
      );
    }
  },
};
