<template>
  <teleport to="body">
    <transition name="backdrop">
      <div v-if="show" class="backdrop" @click="$emit('deny')">
        <div class="ui card" @click.stop>
          <div class="content">
            <div class="header" :class="{ 'center aligned': alignCenter }">
              <slot name="header">Info</slot>
            </div>
            <div class="meta" :class="{ 'center aligned': alignCenter }">
              <slot name="meta"></slot>
            </div>
          </div>
          <div class="content" :class="{ 'center aligned': alignCenter }">
            <slot name="default"></slot>
          </div>
          <div class="extra content">
            <div class="ui two buttons">
              <div
                v-if="hasDenyButton"
                class="ui basic button"
                :class="[denyColor, { disabled: confirmLoading }]"
                @click="$emit('deny')"
              >
                <slot name="deny-button">No</slot>
              </div>
              <div
                class="ui basic button"
                :class="[
                  confirmColor,
                  { loading: confirmLoading, disabled: confirmLoading },
                ]"
                @click="$emit('confirm')"
              >
                <slot name="confirm-button">Yes</slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: Boolean,
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    alignCenter: Boolean,
    hasDenyButton: {
      type: Boolean,
      default: true,
    },
    confirmColor: {
      type: String,
      default() {
        return 'green';
      },
    },
    denyColor: {
      type: String,
      default() {
        return 'red';
      },
    },
  },
  emits: ['confirm', 'deny'],
};
</script>

<style scoped>
.card {
  margin: 0 calc(50% - 145px);
  position: fixed;
  top: 20vh;
  z-index: 201;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 200;
}

/* Animations */
.backdrop-enter-active,
.backdrop-leave-active {
  transition: all 0.3s ease-in-out;
}

.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}
</style>
