<template>
  <h4>Allergens</h4>
  <div class="equal width fields">
    <div class="field">
      <div class="ui sub header">Contains:</div>
      <div class="grouped fields">
        <div
          v-for="allergen in regionSettings.allergensContains"
          :key="allergen"
          class="field"
        >
          <div class="ui checkbox">
            <input
              type="checkbox"
              :disabled="allergensMayContain.includes(allergen) || isViewOnly"
              :checked="allergensContains.includes(allergen)"
              @change="toggleAllergenContains({ allergen })"
            />
            <label>{{ allergen }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="ui sub header">May Contain:</div>
      <div class="grouped fields">
        <div
          v-for="(allergen, index) in regionSettings.allergensMayContain"
          :key="index"
          class="field"
        >
          <div class="ui checkbox">
            <input
              type="checkbox"
              :disabled="allergensContains.includes(allergen) || isViewOnly"
              :checked="allergensMayContain.includes(allergen)"
              @change="toggleAllergenMayContain({ allergen })"
            />
            <label>{{ allergen }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="field">
    <label>Additional Statement</label>
    <div class="ui input">
      <input
        type="text"
        placeholder="French | ex: Fabriqué sur des équipements où sont également utilisés oeuf"
        :disabled="isViewOnly"
        :value="additionalAllergenStatementFr"
        @keyup="setAdditionalAllergenStatementFr($event.target)"
      />
    </div>
    <div class="ui input mt-half">
      <input
        type="text"
        placeholder="English | ex: Manufactured on equipment that processes egg"
        :disabled="isViewOnly"
        :value="additionalAllergenStatement"
        @keyup="setAdditionalAllergenStatement($event.target)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  computed: {
    ...mapState('project', [
      'allergensContains',
      'allergensMayContain',
      'regionSettings',
      'additionalAllergenStatement',
      'additionalAllergenStatementFr',
    ]),
    ...mapGetters('project', ['isViewOnly']),
  },
  methods: {
    ...mapMutations('project', [
      'toggleAllergenContains',
      'toggleAllergenMayContain',
      'setAdditionalAllergenStatement',
      'setAdditionalAllergenStatementFr',
    ]),
  },
};
</script>
