export default {
  /**
   * Get sum of all components qty
   * @returns {number}
   */
  ingredientTotal: state => {
    let total = 0;
    state.ingredient.components.forEach(component => (total += component.qty));
    return Math.round(total * 100 * 10000000) / 10000000;
  },

  /**
   * Get component from cid
   */
  component: state => cid => {
    return state.ingredient.components.find(e => e.cid == cid);
  },
};
