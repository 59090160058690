<template>
  <h4 @click="logProject">Vitamins & Allergens</h4>

  <div class="ui form">
    <div class="equal width fields">
      <div class="field">
        <div class="ui sub header">Contains:</div>
        <div class="ui list mt-half">
          <div
            v-if="presentAllergens != null"
            v-for="allergen in allergensContains"
            :key="allergen"
            class="item"
          >
            <i
              class="small teal star icon"
              title="Allergen found in Ingredient list"
            ></i>
            <div
              class="content"
              :data-tooltip="
                'Source: ' +
                presentAllergens
                  .find(e => e.name == allergen.split('/')[0])
                  .source.join(', ')
              "
              data-position="top left"
            >
              {{ allergen }}
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="ui sub header">May Contain:</div>
        <div class="grouped fields">
          <div
            v-for="(allergen, index) in regionSettings.allergensMayContain"
            :key="index"
            class="field"
          >
            <div class="ui checkbox">
              <input
                type="checkbox"
                :disabled="
                  allergensContains.includes(allergen) ||
                  components.length == 0 ||
                  presentAllergens == null ||
                  isViewOnly
                "
                :checked="allergensMayContain.includes(allergen)"
                @change="toggleAllergenMayContain({ allergen })"
              />
              <label>{{ allergen }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <h4>Label Text</h4>
  <div class="ui form">
    <div class="two fields">
      <div class="field">
        <label>Servings:</label>
        <input
          v-model.number="numServings"
          type="text"
          autocomplete="off"
          placeholder="ex: 2"
          maxlength="10"
          :disabled="isViewOnly"
          @keypress="isAllowedNumberChar($event)"
          @keyup="setNumServings"
        />
      </div>
      <div class="field" :class="{ error: !isValidServingSize }">
        <label>Serving Size:</label>
        <div class="ui right labeled input">
          <input
            v-model.number="servingSize"
            type="text"
            maxlength="10"
            autocomplete="off"
            placeholder="ex: 31"
            :disabled="isViewOnly"
            @keypress="isAllowedNumberChar($event)"
            @keyup="setServingSize"
          />
          <div class="ui basic label">g</div>
        </div>
      </div>
    </div>
    <div class="field">
      <label>Serving Size Text:</label>
      <input
        v-model.trim="servingSizeInfo"
        autocomplete="off"
        type="text"
        placeholder="optional"
        maxlength="20"
        :disabled="isViewOnly"
        @keypress="isAllowedTextChar($event)"
        @keyup="setServingSizeInfo($event.target)"
      />
    </div>
  </div>

  <hr />

  <h4>Reductions</h4>
  <div class="ui checkbox">
    <input
      type="checkbox"
      :disabled="isViewOnly"
      :checked="reduceVitamins"
      @change="toggleReduceVitamins"
    />
    <label>Vitamins -20%</label>
  </div>

  <hr />

  <div class="ui grid">
    <div class="eight wide column">
      <h4>Protein</h4>
      <div class="ui form">
        <div class="field">
          <label>PDCAAS</label>
          <input
            v-model.number="pdcaas"
            type="text"
            maxlength="6"
            autocomplete="off"
            :disabled="isViewOnly"
            @keypress="isAllowedNumberChar($event)"
            @keyup="setPdcaas"
          />
          <small>Setting value to zero will hide %DV</small>
        </div>
      </div>
    </div>

    <div class="eight wide column">
      <h4>Sulfites</h4>
      <div class="ui form">
        <div class="field">
          <label>Include in ingredient statement</label>
          <div
            class="ui toggle checkbox"
            :class="{ disabled: sulfitePpm < 10 }"
          >
            <input
              type="checkbox"
              :checked="
                ingredientStatement.some(e => e.cid.includes('SULFITE'))
              "
              :disabled="sulfitePpm < 10 || isViewOnly"
              autocomplete="off"
              @change="toggleShowSulfites"
            />
            <label>
              Sulfites quantity:
              <strong>{{ sulfitePpm }}ppm</strong>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      numServings: this.$store.state.project.numServings,
      servingSize: this.$store.state.project.servingSize,
      servingSizeInfo: this.$store.state.project.servingSizeInfo,

      pdcaas: this.$store.state.project.pdcaas,

      isValidNumServings: true,
      isValidServingSize: true,
      isValidPdcaas: true,
    };
  },
  computed: {
    ...mapState('project', [
      'components',
      'reduceVitamins',
      'regionSettings',
      'allergensContains',
      'allergensMayContain',
      'ingredientStatement',
      'presentAllergens',
    ]),
    ...mapGetters('project', ['sulfitePpm', 'isViewOnly']),
  },
  methods: {
    ...mapMutations('project', [
      'toggleReduceVitamins',
      'setServingSizeInfo',
      'toggleAllergenMayContain',
      'toggleShowSulfites',
    ]),
    isAllowedNumberChar(event) {
      if (/^[0-9.]+$/.test(event.key)) return true;
      else event.preventDefault();
    },
    isAllowedTextChar(event) {
      if (/^[a-zA-Z0-9À-ÖØ-öø-ÿ ().,-]+$/.test(event.key)) return true;
      else event.preventDefault();
    },

    /**
     * Set number of servings
     */
    setNumServings() {
      const value = parseFloat(this.numServings);

      // Validation
      if (this.isValidNumber(value)) {
        this.isValidNumServings = true;
        this.$store.commit('project/setNumServings', { value });
      } else this.isValidNumServings = false;
    },

    /**
     * Set serving size
     */
    setServingSize() {
      const value = parseFloat(this.servingSize);

      // Validation
      if (this.isValidNumber(value)) {
        this.isValidServingSize = true;
        this.$store.commit('project/setServingSize', { value });
      } else this.isValidServingSize = false;
    },

    setPdcaas() {
      const value = parseFloat(this.pdcaas);
      //Validation
      if (
        !isNaN(value) &&
        (('' + value).split('.').length == 1 ||
          ('' + value).split('.')[1].length <= 4)
      ) {
        this.isValidPdcaas = true;
        this.$store.commit('project/setPdcaas', { value });
      } else this.isValidPdcaas = false;
    },
    /**
     * Helper function
     */
    isValidNumber(num) {
      return (
        !isNaN(num) &&
        num > 0 &&
        (('' + num).split('.').length == 1 ||
          ('' + num).split('.')[1].length <= 4)
      );
    },

    logProject() {
      console.info(this.$store.state.project);
    },
  },
};
</script>

<style scoped>
hr {
  border: 1px solid rgba(173, 173, 173, 0.25);
}
</style>
