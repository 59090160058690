<template>
  <BaseModal
    :show="openModal == 'groupComponentsModal'"
    @deny="closeProjectModal"
  >
    <div class="ui vertical segment title">
      <h3>Group Components</h3>
    </div>

    <div class="ui vertical segment">
      <div class="ui form">
        <div class="two fields">
          <div class="field" :class="{ error: !isValidGroupName }">
            <label>Group Name</label>
            <input
              ref="groupName"
              type="text"
              placeholder="Enter group name..."
              maxlength="100"
              @keydown="isValidGroupName = true"
              @keypress="isAllowedChar"
              @keyup.enter="add"
              @change="cleanString($event)"
            />
          </div>
          <div class="field">
            <label>Components Name</label>
            <input
              ref="componentsName"
              :value="
                selectedComponents
                  .sort((a, b) => b.qty - a.qty)
                  .map(e => e.name)
                  .join(', ')
              "
              type="text"
              placeholder="Enter components name..."
              maxlength="100"
              @keypress="isAllowedChar"
              @keyup.enter="add"
              @change="cleanString($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasSecondLanguage" class="ui vertical segment">
      <div class="ui form">
        <div class="two fields">
          <div class="field" :class="{ error: !isValidGroupNameFr }">
            <label>Group Name (FR)</label>
            <input
              ref="groupNameFr"
              type="text"
              placeholder="Enter group name..."
              maxlength="100"
              @keydown="isValidGroupNameFr = true"
              @keypress="isAllowedChar"
              @keyup.enter="add"
              @change="cleanString($event)"
            />
          </div>
          <div class="field">
            <label>Components Name (FR)</label>
            <input
              ref="componentsNameFr"
              :value="
                selectedComponents
                  .sort((a, b) => b.qty - a.qty)
                  .map(e => e.nameFr)
                  .join(', ')
              "
              type="text"
              placeholder="Enter components name..."
              maxlength="100"
              @keypress="isAllowedChar"
              @keyup.enter="add"
              @change="cleanString($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="ui vertical segment actions">
      <button class="ui black mini button" @click="closeProjectModal">
        Cancel
      </button>
      <button class="ui positive mini button" @click="add">Confirm</button>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      isValidGroupName: true,
      isValidGroupNameFr: true,
    };
  },
  computed: {
    ...mapState('project', ['openModal', 'selectedComponents']),
    ...mapGetters('project', ['hasSecondLanguage']),
  },
  methods: {
    ...mapMutations('project', [
      'closeProjectModal',
      'clearSelectedComponents',
    ]),
    isAllowedChar(event) {
      if (!/^[A-Za-z0-9À-ÖØ-öø-ÿ/ &(),.-]+$/.test(event.key))
        event.preventDefault();
    },
    add() {
      // Validation
      if (this.$refs.groupName.value.trim().length == 0)
        return (this.isValidGroupName = false);
      if (
        this.selectedComponents[0].nameFr &&
        this.$refs.groupNameFr.value.trim().length == 0
      )
        return (this.isValidGroupNameFr = false);

      // Add
      let codeList = [];
      this.selectedComponents.forEach(e => {
        e.code.forEach(c => codeList.push(c));
      });

      this.$store.commit('project/addToIngredientStatement', {
        code: codeList,
        name:
          this.$refs.componentsName.value.trim().length == 0
            ? this.$refs.groupName.value.trim()
            : `${this.$refs.groupName.value.trim()} (${
                this.$refs.componentsName.value
              })`,
        nameFr: this.hasSecondLanguage
          ? this.$refs.componentsNameFr.value.trim().length > 0
            ? `${this.$refs.groupNameFr.value.trim()} (${
                this.$refs.componentsNameFr.value
              })`
            : this.$refs.groupNameFr.value.trim()
          : null,
        qty: this.selectedComponents.reduce((acc, curr) => acc + curr.qty, 0),
        cid: this.selectedComponents.map(e => e.cid),
      });

      this.closeProjectModal();
      this.isValidGroupName = true;
      this.isValidComponentsName = true;
      this.isValidGroupNameFr = true;
      this.isValidComponentsNameFr = true;
      this.clearSelectedComponents();
    },
    cleanString(event) {
      let found = event.target.value.match(/[A-Za-z0-9À-ÖØ-öø-ÿ/ &(),.-]/g);
      if (found != null) event.target.value = found.join('').trim();
      else event.target.value = '';
    },
  },
};
</script>

<style scoped>
input {
  min-width: 25vw;
}
</style>
