<template>
  <transition name="fade" mode="out-in">
    <ProjectBuilder v-if="projectReady" />
    <RecipeUpload v-else />
  </transition>
</template>

<script>
import RecipeUpload from '@/components/project/RecipeUpload';
import ProjectBuilder from '@/components/project/ProjectBuilder';
import { mapGetters } from 'vuex';

export default {
  components: {
    RecipeUpload,
    ProjectBuilder,
  },
  computed: {
    ...mapGetters('project', ['projectReady']),
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
