<template>
  <div class="ui fluid card">
    <div class="content">
      <div class="header" @click="logIngredientStatement()">
        Ingredient Statement
        <button
          v-if="!isViewOnly()"
          class="ui mini compact icon button"
          title="Clear"
          @click="clearIngredientStatement"
        >
          <i class="eraser icon"></i>
        </button>
        <button
          class="ui mini compact icon button"
          title="Export to CSV file"
          @click="exportToCsv"
        >
          <i class="file excel icon"></i>
        </button>
      </div>
    </div>
    <div class="content">
      <h4 class="ui sub header">Ingredients:</h4>
      <div class="ui small feed" :style="{ maxHeight: maxHeight + 'em' }">
        <i v-if="ingredientStatement.length == 0">None</i>
        <div
          v-for="item in ingredientStatement"
          v-else
          :key="item.cid.join('')"
          class="event"
        >
          <div class="content">
            <div class="summary">
              <div class="ui grid">
                <div
                  class="ten wide column"
                  :class="{ name: !isViewOnly() }"
                  @click="
                    isViewOnly()
                      ? ''
                      : editIngredientStatementItem({ cid: item.cid })
                  "
                >
                  <div>
                    {{ item.name }}
                  </div>
                  <div v-if="hasSecondLanguage()" class="meta">
                    {{ item.nameFr }}
                  </div>
                </div>
                <div class="six wide column">
                  <span v-if="item.qty > 0">
                    {{
                      Math.round(
                        (item.qty / recipeInfo.gramWeight) * 100 * 1000,
                      ) / 1000
                    }}%
                    <i
                      v-if="!isViewOnly()"
                      title="Remove"
                      class="minus circle icon"
                      @click="removeFromIngredientStatement({ cid: item.cid })"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver';
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  props: {
    maxHeight: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    ...mapState('project', [
      'ingredientStatement',
      'selectedComponents',
      'recipeInfo',
    ]),
  },
  methods: {
    ...mapMutations('project', [
      'clearIngredientStatement',
      'removeFromIngredientStatement',
      'editIngredientStatementItem',
    ]),
    ...mapGetters('project', ['hasSecondLanguage', 'isViewOnly']),
    logIngredientStatement() {
      console.info(this.ingredientStatement);
    },
    exportToCsv() {
      try {
        let data = 'NAME,QTY,%\n';
        this.ingredientStatement.forEach(item => {
          data += `"${item.name}","${item.qty}","${
            Math.round(
              (item.qty / this.recipeInfo.gramWeight) * 100 * 1000000,
            ) / 1000000
          }"\n`;
        });

        let blob = new Blob([data], {
          type: 'text/csv;charset=utf-8',
        });
        FileSaver.saveAs(blob, 'Ingredient Breakdown.csv');
      } catch (err) {
        console.error(err);
        this.$store.commit('showAlert', {
          type: 'error',
          header: 'Oops',
          message:
            'Something went wrong while trying to export the ingredient statement',
        });
      }
    },
  },
};
</script>

<style scoped>
button {
  float: right;
}
.summary .column:nth-child(1) span {
  font-size: 0.8rem;
}
.summary .column:nth-child(2) span {
  float: right;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.85714286em;
}
.event {
  border-bottom: 1px solid #00000019;
}
.event:last-child {
  border-bottom: none;
}
i.icon {
  margin-left: 0.25rem;
  cursor: pointer;
}
i.icon:hover {
  color: rgb(35, 35, 35);
}
.feed {
  overflow-y: auto;
  scrollbar-width: thin;
}
.name {
  cursor: pointer;
}
.name:hover {
  text-decoration: underline;
}
</style>
