import request from '../../../request';

/**
 * Get ingredient from code
 * @param {string} code Ingredient Code
 * @throws Will throw network related error
 */
async function loadIngredient(context, code) {
  const response = await request.sendRequest(context, {
    method: 'GET',
    url: `/api/ingredients/${code}`,
  });
  return response.data.result;
}

export default {
  /**
   * Search for ingredient
   */
  search: async (context, payload) => {
    context.commit('setLoadingSearchResults', { status: true });

    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/search/ingredient',
        params: payload,
      });
      context.commit('setSearchResults', {
        searchResults: response.data.results,
      });
    } catch (err) {
      console.error(err);
    } finally {
      context.commit('setLoadingSearchResults', { status: false });
    }
  },

  /**
   * Delete ingredient
   */
  deleteIngredient: async context => {
    try {
      await request.sendRequest(context, {
        method: 'DELETE',
        url: `/api/ingredients/${context.state.ingredientToDelete}`,
      });
    } catch (err) {
      return console.error(err);
    }

    context.commit('removeFromSearchResults', {
      code: context.state.ingredientToDelete,
    });

    context.commit('closeModal');
    context.commit('setIngredientToDelete', { code: null });
  },

  /**
   * Push New Ingredient
   */
  newIngredient: async context => {
    try {
      // Check if exists
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: `/api/ingredients/${context.state.ingredient.code}`,
      });

      if (
        response.data.result &&
        response.data.result.code == context.state.ingredient.code
      ) {
        return context.commit(
          'showAlert',
          {
            header: 'Try again',
            message: 'The ingredient code you are trying to add already exists',
            timeout: 10000,
          },
          { root: true },
        );
      }

      // Add
      await request.sendRequest(context, {
        method: 'POST',
        url: '/api/ingredients/new',
        data: {
          code: context.state.ingredient.code,
          components: context.state.ingredient.components,
          allergens: context.state.ingredient.allergens,
        },
      });

      context.commit(
        'showAlert',
        {
          header: 'Success!',
          message: `Ingredient ${context.state.ingredient.code} added`,
          type: 'success',
          timeout: 10000,
        },
        { root: true },
      );

      // Clear search results
      context.commit('clearSearchResults');
    } catch (err) {
      console.error(err);
    }

    // Clear ingredient code
    context.state.ingredient.code = '';
  },

  /**
   * Push update of ingredient
   */
  updateIngredient: async context => {
    try {
      const response = await request.sendRequest(context, {
        method: 'PUT',
        url: `/api/ingredients/${context.state.ingredient.originalCode}`,
        data: {
          code: context.state.ingredient.code,
          components: context.state.ingredient.components,
          allergens: context.state.ingredient.allergens,
        },
      });

      // Check if duplicate issue
      if (response.data.exists) {
        context.commit(
          'showAlert',
          {
            type: 'warning',
            header: 'Already exists',
            message:
              'Ingredient code already exists. Please choose another one.',
            timeout: 5000,
          },
          { root: true },
        );
      } else {
        // Clear search results
        context.commit('clearSearchResults');

        // User message
        context.commit(
          'showAlert',
          {
            header: 'Success!',
            message: `Ingredient ${context.state.ingredient.code} updated`,
            type: 'success',
            timeout: 5000,
          },
          { root: true },
        );
        context.commit('closeModal');
      }
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Load ingredient data for editing
   */
  editIngredient: async (context, payload) => {
    context.commit('setIngredientCodeToLoad', { code: payload.code });

    try {
      const result = await loadIngredient(context, payload.code);
      context.commit('setOriginalCode', { code: result.code });
      result.components.forEach(component =>
        context.commit('addComponent', component),
      );
      context.state.ingredient.allergens = result.allergens;
      context.commit('openIngredientModal', {
        editMode: true,
        code: result.code,
      });
    } catch (err) {
      console.error(err);
    }

    context.commit('setIngredientCodeToLoad', { code: null });
  },

  /**
   * Load ingredient data for duplication
   */
  loadIngredient: async (context, payload) => {
    context.commit('setIngredientCodeToLoad', { code: payload.code });

    try {
      const result = await loadIngredient(
        context,
        context.state.loadingIngredientCode,
      );
      result.components.forEach(component =>
        context.commit('addComponent', component),
      );
      context.state.ingredient.allergens = result.allergens;
      context.commit('openIngredientModal', { editMode: false });
    } catch (err) {
      console.error(err);
    }

    context.commit('setIngredientCodeToLoad', { code: null });
  },

  componentsSearch: async (context, payload) => {
    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/search/components',
        params: {
          q: payload.query,
          type: payload.type,
        },
      });
      return response.data.results;
    } catch (err) {
      console.error(err);
    }
  },

  getAllergenList: async context => {
    try {
      const response = await request.sendRequest(context, {
        method: 'GET',
        url: '/api/ingredients/allergens-list',
      });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
};
