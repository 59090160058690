import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';

import NotFound from '@/views/NotFound';
import Login from '@/views/Login';
import Dashboard from '@/views/Dashboard.vue';
import Admin from '@/views/Admin.vue';
import IngredientDatabase from '@/views/IngredientDatabase';
import Project from '@/views/project/Project';
import NewProject from '@/views/project/NewProject';
import OpenProject from '@/views/project/OpenProject';
import Rules from '@/views/Rules';
import Templates from '@/views/Templates';
import Account from '@/views/Account';
import AccountActivation from '@/views/AccountActivation';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true,
      hideNav: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    alias: '/',
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: '/ingredients',
    name: 'Ingredient Database',
    component: IngredientDatabase,
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: '/project',
    component: Project,
    children: [
      {
        path: '',
        beforeEnter: (_to, _from, next) => {
          if (store.state.project.id)
            next('/project/open/' + store.state.project.id);
          else next({ name: 'New Project' });
        },
      },
      {
        path: 'new',
        name: 'New Project',
        component: NewProject,
      },
      {
        path: 'open/:id',
        name: 'Open Project',
        props: true,
        component: OpenProject,
      },
    ],
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
  },
  {
    path: '/account/activate/:token',
    name: 'Activate Account',
    component: AccountActivation,
    props: true,
    meta: {
      guest: true,
      hideNav: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    meta: {
      hideNav: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  // Clear alerts and close navbar
  store.commit('closeAlert');
  store.commit('closeNavbar');

  // Check for guest access
  if (to.matched.some(record => record.meta.guest)) return next();

  // Redirect if not logged in
  if (!(await store.dispatch('isLoggedIn'))) {
    return next({
      name: 'Login',
      query: {
        redirect:
          to.fullPath.substring(1).length > 0
            ? to.fullPath.substring(1)
            : undefined,
      },
    });
  }

  // Set page title
  document.title = 'Nutrispec | ' + to.name;

  // Check for editor
  if (to.matched.some(record => record.meta.isEditor)) {
    if (store.getters.activeUser.clearanceLevel >= 2) return next();
    else return next({ name: 'Dashboard' });
  }

  // Check for admin
  if (to.matched.some(record => record.meta.isAdmin)) {
    if (store.getters.activeUser.clearanceLevel >= 3) return next();
    else return next({ name: 'Dashboard' });
  }

  return next();
});

export default router;
