<template>
  <div class="ui top attached tabular menu">
    <a class="item" :class="{ active: openTab == 0 }" @click="openTab = 0">
      Nutrients
    </a>
    <a class="item" :class="{ active: openTab == 1 }" @click="openTab = 1">
      Components
    </a>
    <a class="item" :class="{ active: openTab == 2 }" @click="openTab = 2">
      Details
    </a>
  </div>

  <div class="ui bottom attached tab segment" :class="{ active: openTab == 0 }">
    <NutrientsTable />
  </div>

  <div class="ui bottom attached tab segment" :class="{ active: openTab == 1 }">
    <ComponentsTable />
  </div>

  <div class="ui bottom attached tab segment" :class="{ active: openTab == 2 }">
    <DetailsPanel />
  </div>
</template>

<script>
import NutrientsTable from '@/components/project/builder/NutrientsTable';
import ComponentsTable from '@/components/project/builder/ComponentsTable';
import DetailsPanel from '@/components/project/builder/us/DetailsPanel';

export default {
  components: {
    NutrientsTable,
    ComponentsTable,
    DetailsPanel,
  },
  data() {
    return {
      openTab: 0,
    };
  },
};
</script>

<style scoped>
.tab.segment {
  height: 80vh;
  overflow: auto;
  scrollbar-width: thin;
}
</style>
