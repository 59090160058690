<template>
  <tr v-for="(component, index) in ingredient.components" :key="component._id">
    <td v-if="index == 0" :rowspan="ingredient.components.length">
      <div>
        <b>{{ ingredient.code }}</b>
        <div>
          <small>
            Last edited by
            <i>{{ ingredient.author ? ingredient.author.fullName.split(' ')[0] : '(Deleted User)' }}</i>
          </small>
        </div>

        <div class="ui mini compact menu">
          <div
            class="ui simple dropdown item"
            :class="{
              disabled: loadingIngredientCode,
              loading: loadingIngredientCode == ingredient.code,
            }"
          >
            Actions
            <i class="dropdown icon"></i>
            <div class="menu">
              <div
                class="item"
                @click="editIngredient({ code: ingredient.code })"
              >
                <i class="pencil alternate icon"></i>
                Edit
              </div>
              <div
                class="item"
                @click="loadIngredient({ code: ingredient.code })"
              >
                <i class="copy icon"></i>
                Duplicate
              </div>
              <div
                class="red item"
                @click="openDeleteModal({ code: ingredient.code })"
              >
                <i class="red close icon"></i>
                <span style="color: red">Delete</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td>{{ component.usEnName }}</td>
    <td>{{ component.caEnName }}</td>
    <td>{{ component.caFrName }}</td>
    <td>{{ component.euEnName }}</td>
    <td>{{ component.euFrName }}</td>
    <td>{{ Math.round(component.qty * 100 * 10000000) / 10000000 }}%</td>
  </tr>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  props: {
    ingredient: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('ingredient', ['loadingIngredientCode']),
  },
  methods: {
    ...mapMutations('ingredient', ['openDeleteModal']),
    ...mapActions('ingredient', ['editIngredient', 'loadIngredient']),
  },
};
</script>

<style scoped>
td>div {
  min-height: 11rem;
}
</style>
