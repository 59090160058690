<template>
  <div class="overflow">
    <div class="ui vertical segment">
      <h3>Understanding Templates and Variables</h3>
    </div>
    <div class="ui header">How it works.</div>
    <p>
      A template tells Nutrispec where to put what data (nutrient quantities,
      allergens, ingredient statement etc.). Once a user exports a finished
      project, Nutrispec will get the template and replace the relevant parts.
      There are 2 types of variables that are being replaced. They are discussed
      below.
    </p>

    <div class="ui header">Data variables</div>
    <p>
      Data variables are variables that will be replaced with data. An example
      would be the variables <code>calories</code>. When Nutrispec renders the
      document, it will replace all <code>{calories}</code> (case sensitive)
      variables with the calories amount found in the Nutrispec project. All
      variables are surrounded by curly braces. A before and after example is
      shown below.
    </p>
    <div class="ui huge image">
      <img
        src="@/assets/templates/variables-example-1.png"
        alt="Document before being rendered"
      />
    </div>

    <div class="ui header">Conditional variables</div>
    <p>
      Conditional variables are variables that will tell Nutrispec if certain
      data should be shown or not. An example would be the variable
      <code>hasGluten</code>. It will tell Nutrispec if it should include
      whatever <code>hasGluten</code> wraps in the spec sheet. Conditional
      variables will always surround a section of the template. They can wrap
      around a variable, a table or text. They wrap as follows:
      <code>{#hasGluten} Text to include is hasGluten is true {/hasGluten}</code
      >. In the example above, <code>hasOmega6</code> was <code>false</code>,
      therefore everything between <code>{#hasOmega6}</code> and
      <code>{/hasOmega6}</code> will not be shown. On the other hand,
      <code>hasServingsPerContainer</code> was <code>true</code>, therefore
      "Servings per container..." is shown on the final spec.
    </p>
  </div>
</template>

<style scoped>
.overflow {
  overflow: auto;
  max-height: 75vh;
  scrollbar-width: thin;
}
</style>
