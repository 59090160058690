<template>
  <table class="ui compact celled fluid structure unstackable table">
    <thead>
      <tr>
        <th colspan="4">
          <div class="ui icon fluid mini input">
            <i class="search icon"></i>
            <input
              v-model.trim="searchQuery"
              type="text"
              placeholder="Search..."
            />
          </div>
        </th>
      </tr>
      <tr>
        <th>Nutrient</th>
        <th>Required on label</th>
        <th>Quantity Rounding</th>
        <th>Daily Value Rounding</th>
      </tr>
    </thead>
    <tbody>
      <RuleItem
        v-for="item in filteredRegionItems"
        :key="item.name"
        :item="item"
      />
    </tbody>
  </table>
  <AddDvRule />
  <AddQtyRule />
  <EditDv />
</template>

<script>
import { mapState } from 'vuex';
import RuleItem from '@/components/rules/RuleItem';
import AddDvRule from '@/components/rules/AddDvRule';
import AddQtyRule from '@/components/rules/AddQtyRule';
import EditDv from '@/components/rules/EditDv';
export default {
  components: {
    RuleItem,
    AddDvRule,
    AddQtyRule,
    EditDv,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapState('rules', ['allRegionRules', 'regionItems']),
    filteredRegionItems() {
      let filtered = [];
      this.regionItems.forEach(nutrient => {
        if (
          nutrient.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          filtered.push(nutrient);
        }
      });
      return filtered;
    },
  },
};
</script>

<style scoped>
table {
  margin-bottom: 1rem !important;
}
</style>
