<template>
  <div class="inline fields">
    <div class="eight wide field" :class="{ disabled: isLoading }">
      <label>Production Origin</label>
      <input
        :value="productionOrigin"
        :disabled="isViewOnly"
        type="text"
        placeholder="English"
        @keypress="isAllowedTextChar($event)"
        @keyup="setProductionOrigin($event.target)"
        @change="!productionOriginFr ? translateToFrench() : ''"
      />
    </div>
    <div class="eight wide field" :class="{ disabled: isLoading }">
      <input
        :value="productionOriginFr"
        :disabled="isViewOnly"
        type="text"
        placeholder="French"
        @keypress="isAllowedTextChar($event)"
        @keyup="setProductionOriginFr($event.target)"
        @change="!productionOrigin ? translateToEnglish() : ''"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('project', ['productionOrigin', 'productionOriginFr']),
    ...mapGetters('project', ['isViewOnly']),
  },
  methods: {
    ...mapMutations('project', [
      'setProductionOrigin',
      'setProductionOriginFr',
    ]),
    isAllowedTextChar(event) {
      if (/^[a-zA-Z0-9À-ÖØ-öø-ÿ ().,-]+$/.test(event.key)) return true;
      else event.preventDefault();
    },

    /**
     * Translate to french
     */
    async translateToFrench() {
      if (!this.productionOrigin) return;

      this.isLoading = true;
      const translation = await this.$store.dispatch('project/translate', {
        to: 'fr',
        from: 'en',
        text: this.productionOrigin,
      });
      this.setProductionOriginFr({ value: translation });
      this.isLoading = false;
    },

    /**
     * Translate to english
     */
    async translateToEnglish() {
      if (!this.productionOriginFr) return;

      this.isLoading = true;
      const translation = await this.$store.dispatch('project/translate', {
        to: 'en',
        from: 'fr',
        text: this.productionOriginFr,
      });
      this.setProductionOrigin({ value: translation });
      this.isLoading = false;
    },
  },
};
</script>
