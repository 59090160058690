<template>
  <BaseModal :show="openModal == 'addComponentModal'" @deny="closeProjectModal">
    <div class="ui vertical segment title">
      <h3>Add Component</h3>
    </div>

    <div class="ui vertical segment">
      <div class="ui form">
        <div class="field" :class="{ error: !isValidName }">
          <label>Component Name</label>
          <input
            ref="componentName"
            :value="selectedComponents[0].name"
            type="text"
            placeholder="Enter component name..."
            maxlength="100"
            @keydown="isValidName = true"
            @keypress="isAllowedChar"
            @keyup.enter="add"
            @change="cleanString($event)"
          />
        </div>
        <div
          v-if="hasSecondLanguage"
          class="field"
          :class="{ error: !isValidNameFr }"
        >
          <label>Component Name (FR)</label>
          <input
            ref="componentNameFr"
            :value="selectedComponents[0].nameFr"
            type="text"
            placeholder="Enter component name..."
            maxlength="100"
            @keydown="isValidNameFr = true"
            @keypress="isAllowedChar"
            @keyup.enter="add"
            @change="cleanString($event)"
          />
        </div>
      </div>
    </div>

    <div class="ui vertical segment actions">
      <button class="ui black mini button" @click="closeProjectModal">
        Cancel
      </button>
      <button class="ui positive mini button" @click="add">Confirm</button>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      isValidName: true,
      isValidNameFr: true,
    };
  },
  computed: {
    ...mapState('project', ['openModal', 'selectedComponents']),
    ...mapGetters('project', ['hasSecondLanguage']),
  },
  methods: {
    ...mapMutations('project', ['clearSelectedComponents']),
    closeProjectModal() {
      this.isValidName = true;
      this.isValidNameFr = true;
      this.$store.commit('project/closeProjectModal');
    },
    isAllowedChar(event) {
      if (!/^[A-Za-z0-9À-ÖØ-öø-ÿ/ &(),.-]+$/.test(event.key))
        event.preventDefault();
    },
    add() {
      // Validation
      if (this.$refs.componentName.value.trim().length == 0)
        return (this.isValidName = false);
      if (
        this.hasSecondLanguage &&
        this.$refs.componentNameFr.value.trim().length == 0
      )
        return (this.isValidNameFr = false);

      this.$store.commit('project/addToIngredientStatement', {
        code: this.selectedComponents[0].code,
        name: this.$refs.componentName.value,
        nameFr: this.hasSecondLanguage
          ? this.$refs.componentNameFr.value
          : null,
        qty: this.selectedComponents[0].qty,
        cid: [this.selectedComponents[0].cid],
      });
      this.closeProjectModal();
      this.clearSelectedComponents();
    },
    cleanString(event) {
      let found = event.target.value.match(/[A-Za-z0-9À-ÖØ-öø-ÿ/ &(),.-]/g);
      if (found != null) event.target.value = found.join('').trim();
      else event.target.value = '';
    },
  },
};
</script>

<style scoped>
input {
  min-width: 30vw;
}
</style>
