<template>
  <h4>Statement of Identity</h4>
  <div class="field">
    <label>Header</label>
    <div
      class="ui fluid icon input mt-half"
      :class="{ 'disabled loading': isLoadingSoiHeaderFr }"
    >
      <input
        type="text"
        placeholder="French Header"
        :value="soiHeaderFr"
        :disabled="isViewOnly"
        @keypress="isAllowedTextChar($event)"
        @keyup="setSoiHeaderFr($event.target)"
        @change="!soiHeader ? translateToHeader() : ''"
      />
      <i class="icon"></i>
    </div>
    <div
      class="ui fluid icon input"
      :class="{ 'disabled loading': isLoadingSoiHeader }"
    >
      <input
        type="text"
        placeholder="English Header"
        :value="soiHeader"
        :disabled="isViewOnly"
        @keypress="isAllowedTextChar($event)"
        @keyup="setSoiHeader($event.target)"
        @change="!soiHeaderFr ? translateToHeaderFr() : ''"
      />
      <i class="icon"></i>
    </div>
  </div>

  <div class="field">
    <label>Content</label>
    <div
      class="ui fluid icon input mt-half"
      :class="{ 'disabled loading': isLoadingSoiContentFr }"
    >
      <input
        type="text"
        placeholder="French Content"
        :value="soiContentFr"
        :disabled="isViewOnly"
        @keypress="isAllowedTextChar($event)"
        @keyup="setSoiContentFr($event.target)"
        @change="!soiContent ? translateToContent() : ''"
      />
      <i class="icon"></i>
    </div>
    <div
      class="ui fluid icon input mt-half"
      :class="{ 'disabled loading': isLoadingSoiContent }"
    >
      <input
        type="text"
        placeholder="English Content"
        :value="soiContent"
        :disabled="isViewOnly"
        @keypress="isAllowedTextChar($event)"
        @keyup="setSoiContent($event.target)"
        @change="!soiContentFr ? translateToContentFr() : ''"
      />
      <i class="icon"></i>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
export default {
  data() {
    return {
      isLoadingSoiHeader: false,
      isLoadingSoiHeaderFr: false,
      isLoadingSoiContent: false,
      isLoadingSoiContentFr: false,
    };
  },
  computed: {
    ...mapState('project', [
      'soiHeader',
      'soiHeaderFr',
      'soiContent',
      'soiContentFr',
    ]),
    ...mapGetters('project', ['isViewOnly']),
  },
  methods: {
    ...mapMutations('project', [
      'setSoiHeader',
      'setSoiContent',
      'setSoiHeaderFr',
      'setSoiContentFr',
    ]),
    isAllowedTextChar(event) {
      if (/^[a-zA-Z0-9À-ÖØ-öø-ÿ ().,-]+$/.test(event.key)) return true;
      else event.preventDefault();
    },

    async translateToHeaderFr() {
      if (!this.soiHeader) return;

      this.isLoadingSoiHeaderFr = true;
      const translation = await this.$store.dispatch('project/translate', {
        to: 'fr',
        from: 'en',
        text: this.soiHeader,
      });
      this.setSoiHeaderFr({ value: translation });
      this.isLoadingSoiHeaderFr = false;
    },

    async translateToHeader() {
      if (!this.soiHeaderFr) return;

      this.isLoadingSoiHeader = true;
      const translation = await this.$store.dispatch('project/translate', {
        to: 'en',
        from: 'fr',
        text: this.soiHeaderFr,
      });
      this.setSoiHeader({ value: translation });
      this.isLoadingSoiHeader = false;
    },

    async translateToContentFr() {
      if (!this.soiContent) return;

      this.isLoadingSoiContentFr = true;
      const translation = await this.$store.dispatch('project/translate', {
        to: 'fr',
        from: 'en',
        text: this.soiContent,
      });
      this.setSoiContentFr({ value: translation });
      this.isLoadingSoiContentFr = false;
    },

    async translateToContent() {
      if (!this.soiContentFr) return;

      this.isLoadingSoiContent = true;
      const translation = await this.$store.dispatch('project/translate', {
        to: 'en',
        from: 'fr',
        text: this.soiContentFr,
      });
      this.setSoiContent({ value: translation });
      this.isLoadingSoiContent = false;
    },
  },
};
</script>
