<template>
  <div class="bg">
    <canvas id="c"></canvas>
    <span class="logo">Nutrispec</span>
    <div class="ui card">
      <div class="content">
        <div class="header">
          <h3>Welcome Back!</h3>
        </div>
      </div>
      <div class="content">
        <form class="ui large form" @submit.prevent="login({ redirect: '/' })">
          <div class="field" :class="{ error: !credentialsValid }">
            <div class="ui left icon input">
              <input
                v-model="username"
                autocomplete="username"
                type="text"
                placeholder="Username or Email"
                @keyup="username = username.toLowerCase()"
              />
              <i class="user icon"></i>
            </div>
          </div>
          <div class="field" :class="{ error: !credentialsValid }">
            <div class="ui left icon input">
              <input
                ref="password"
                type="password"
                placeholder="Password"
                autocomplete="current-password"
              />
              <i class="lock icon"></i>
            </div>
          </div>
          <button
            :class="{
              disabled: !username.length > 0,
              loading: loading,
            }"
            class="ui fluid button"
          >
            Login
          </button>
        </form>
      </div>

      <div>
        <small class="bottom-right corner">V3.19.1</small>
        <i>
          <small class="bottom-left corner">Created by Mark Kuhn</small>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import spawn from '@/components/login/stars';
export default {
  data() {
    return {
      username: '',
      loading: false,
      credentialsValid: true,
    };
  },
  created() {
    document.title = 'Nutrispec';
    if (this.$route.query.username) this.username = this.$route.query.username;
  },
  mounted() {
    spawn();
  },
  methods: {
    async login() {
      this.loading = true;

      try {
        const authenticated = await this.$store.dispatch('authenticate', {
          username: this.username,
          password: this.$refs.password.value,
        });

        if (authenticated) {
          // Close any messages
          this.$store.commit('closeAlert');

          // If redirect exists
          if (this.$route.query.redirect != null)
            this.$router.push('/' + this.$route.query.redirect);
          else this.$router.push('/');
        } else this.credentialsValid = false;
      } catch (err) {
        console.error(err);
        this.$store.commit('showAlert', {
          type: 'error',
          header: 'Network error',
          message: 'Cannot connect to server',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.corner {
  position: fixed;
  color: white;
}
.bottom-right {
  bottom: 4px;
  right: 4px;
}
.bottom-left {
  bottom: 4px;
  left: 4px;
}
.bg {
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
  background-image: linear-gradient(180deg, #38455b 30%, #242d3c 100%);
}
.card {
  width: 30vw;
  min-width: 20rem;
}
.logo {
  font-family: 'sophie-bold';
  font-size: 2.4rem;
  color: white;
  position: absolute;
  margin: 1.5rem;
  top: 0;
  left: 0;
  text-decoration: none;
  outline: none;
}

small {
  font-family: 'sophie-light';
}

button.button {
  background-color: var(--dark-blue);
  color: white;
  transition: all 0.2s ease-in-out;
}

button.button:hover {
  background-color: rgb(25, 31, 41);
  color: white;
}

button.button:active,
button.button:focus {
  background-color: rgb(54, 67, 88);
  color: white;
}
</style>
