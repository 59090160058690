<template>
  <div>
    <TopHeader header-title="Account" show-user-info />
    <CenterContainer>
      <div class="ui fluid blue card">
        <div class="content">
          <div class="header">{{ activeUser.fullName }}</div>
          <div class="meta">Email: {{ activeUser.email }}</div>
          <div class="meta">Username: {{ activeUser.username }}</div>
          <div class="meta">
            Account type: {{ clearanceLevelName(activeUser) }}
          </div>
        </div>
        <div class="extra content">
          <SpinLoader v-if="!organization" :center="false" :size="20" />
          <a v-else>
            <i class="users icon"></i>
            {{ organization.name }}
          </a>
        </div>
      </div>

      <div class="ui segment">
        <h3>Change Password</h3>
        <div class="ui form">
          <div
            class="field"
            :class="{ error: badOldPassword, disabled: isSettingPassword }"
          >
            <label>Old password</label>
            <input
              v-model="oldPw"
              type="password"
              placeholder="Old Password"
              autocomplete="off"
              maxlength="64"
              @keypress.enter="updatePassword"
              @keypress="badOldPassword = false"
            />
          </div>
          <div class="field" :class="{ disabled: isSettingPassword }">
            <label>New Password</label>
            <input
              v-model="newPw"
              type="password"
              placeholder="New Password"
              autocomplete="off"
              maxlength="64"
              @keypress.enter="updatePassword"
              @keyup="checkPassword"
            />
          </div>
          <div
            class="field"
            :class="{ error: notEqual, disabled: isSettingPassword }"
          >
            <input
              v-model="confirmationPw"
              type="password"
              placeholder="Confirm"
              autocomplete="off"
              maxlength="64"
              @keypress.enter="updatePassword"
              @keydown="notEqual = false"
            />
          </div>

          <div class="ui tiny indicating progress">
            <div class="bar"></div>
          </div>
          <div
            v-if="passwordWarning || passwordFeedback.length > 0"
            class="ui mini info message"
          >
            <div class="header">{{ passwordWarning }}</div>
            <ul class="list">
              <li v-for="(item, index) in passwordFeedback" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>

          <button
            class="ui button"
            :class="{
              disabled: passwordScore < 3 || isSettingPassword,
              loading: isSettingPassword,
            }"
            type="submit"
            @click="updatePassword"
          >
            Update
          </button>
        </div>
      </div>
    </CenterContainer>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      organization: null,

      oldPw: '',
      newPw: '',
      confirmationPw: '',

      passwordWarning: '',
      passwordFeedback: [],
      passwordScore: 0,
      notEqual: false,
      badOldPassword: false,
      isSettingPassword: false,
      timeout: '',
    };
  },
  computed: {
    ...mapGetters(['activeUser', 'clearanceLevelName']),
  },
  created() {
    window.$('.progress').progress();
  },
  async mounted() {
    this.organization = await this.$store.dispatch(
      'account/getOrganizationInfo',
    );
  },
  methods: {
    checkPassword() {
      // Set progress bar
      const res = zxcvbn(this.newPw);
      window.$('.progress').progress({ value: res.score, total: 4 });
      this.passwordScore = res.score;
      if (!this.newPw) return false;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.passwordWarning = res.feedback.warning;
        this.passwordFeedback = res.feedback.suggestions;
      }, 1000);
    },
    async updatePassword() {
      if (zxcvbn(this.newPw).score < 3) return;

      // Check confirmation
      if (this.newPw != this.confirmationPw) return (this.notEqual = true);

      // Check old pw length
      if (this.oldPw.length == 0) return (this.badOldPassword = true);

      // Check old password is not same as new password
      if (this.oldPw == this.newPw)
        return this.$store.commit('showAlert', {
          type: 'warning',
          header: 'Try something else',
          message: 'Your new password cannot be identical to your old one',
        });

      this.isSettingPassword = true;
      await this.$store.dispatch('account/updatePassword', {
        oldPassword: this.oldPw,
        newPassword: this.newPw,
      });

      // Reset
      this.oldPw = '';
      this.newPw = '';
      this.confirmationPw = '';
      this.checkPassword();
      this.isSettingPassword = false;
    },
  },
};
</script>
