<template>
  <BaseModal :show="openModal == 'fileInfoModal'" @deny="closeProjectModal">
    <div class="ui vertical segment">
      <h3>Genesis File Info</h3>
    </div>
    <div class="ui vertical segment">
      <div>
        <b>Recipe filename:</b>
        {{ files.recipe ? files.recipe.filename : 'N/A' }}
      </div>
      <div v-if="files.nutrients">
        <b>Nutrients filename:</b>
        {{ files.nutrients ? files.nutrients.filename : 'N/A' }}
      </div>
      <div v-if="files.multicolumn">
        <b>Multicolumn filename:</b>
        {{ files.multicolumn ? files.multicolumn.filename : 'N/A' }}
      </div>
    </div>
    <div class="ui vertical segment actions">
      <div class="ui ok button" @click="closeProjectModal">Ok</div>
    </div>
  </BaseModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  computed: {
    ...mapState('project', ['files', 'openModal']),
  },
  methods: {
    ...mapMutations('project', ['closeProjectModal']),
  },
};
</script>
