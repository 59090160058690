<template>
  <div class="ui segment">
    <div class="head">
      <h3>Projects</h3>

      <div class="field">
        <div class="ui icon mini input fluid">
          <i class="search icon"></i>
          <input
            type="text"
            placeholder="Search..."
            @keyup="projectSearch({ name: $event.target.value })"
            @keydown="isAllowedChar"
          />
        </div>
      </div>

      <div class="field mt-half">
        <div class="ui multiple dropdown">
          <input type="hidden" name="filters" />
          <i class="filter icon"></i>
          <span class="text">Filter Projects</span>
          <div class="menu">
            <div class="header">
              <i class="tags icon"></i>
              Filters
            </div>
            <div class="scrolling menu">
              <div class="item" data-value="personal">
                <div class="ui red empty circular label"></div>
                My Projects
              </div>
              <div class="item" data-value="ca">
                <div class="ui blue empty circular label"></div>
                CA
              </div>
              <div class="item" data-value="us">
                <div class="ui black empty circular label"></div>
                USA
              </div>
              <div class="item" data-value="eu">
                <div class="ui purple empty circular label"></div>
                EU
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="overflow">
      <BarLoader v-if="isLoading" />
      <table
        v-else-if="projects && projects.length > 0"
        class="ui very basic sortable celled small table"
      >
        <thead>
          <tr>
            <th
              class="eight wide"
              :class="sortingClass('name')"
              @click="sortTable('name')"
            >
              Name
            </th>
            <th class="four wide">Last Edit</th>
            <th
              class="one wide"
              :class="sortingClass('country')"
              @click="sortTable('country')"
            >
              Country
            </th>
            <th class="four wide"></th>
          </tr>
        </thead>
        <tbody>
          <ProjectView
            v-for="project in projects"
            :key="project._id"
            :project="project"
          />
        </tbody>
      </table>
      <h3 v-else class="mt-2"><i>No projects</i></h3>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ProjectView from '@/components/dashboard/ProjectView';
export default {
  components: {
    ProjectView,
  },
  data() {
    return {
      isLoading: false,
      filters: [],
      sorting: {
        col: null,
        ascending: true,
      },
    };
  },
  computed: {
    ...mapState('dashboard', ['displayedProjects']),
    ...mapGetters(['activeUser']),
    projects() {
      let filteredProjects = this.displayedProjects;

      // Filter by mine
      if (this.filters.includes('personal')) {
        filteredProjects = filteredProjects.filter(
          e => e.author.username == this.$store.getters['activeUser'].username,
        );
      }

      // Country filters
      if (this.filters.includes('ca'))
        filteredProjects = filteredProjects.filter(e => e.country == 'ca');
      if (this.filters.includes('us'))
        filteredProjects = filteredProjects.filter(e => e.country == 'us');
      if (this.filters.includes('eu'))
        filteredProjects = filteredProjects.filter(e => e.country == 'eu');

      // Sort list
      if (this.sorting.col) {
        filteredProjects.sort((e1, e2) => {
          if (e1[this.sorting.col] < e2[this.sorting.col])
            return this.sorting.ascending ? -1 : 1;
          if (e1[this.sorting.col] > e2[this.sorting.col])
            return this.sorting.ascending ? 1 : -1;
          return 0;
        });
      }

      return filteredProjects;
    },
  },
  created() {
    this.loadDefaultProjects();
  },
  mounted() {
    window.$('.ui.dropdown').dropdown({
      onChange: () => {
        this.updateFilters();
      },
    });
  },
  methods: {
    ...mapActions('dashboard', ['projectSearch']),
    updateFilters() {
      this.filters = window.$('.ui.dropdown').dropdown('get value').split(',');
    },
    isAllowedChar(event) {
      if (!/^[a-zA-Z0-9- $]+$/.test(event.key)) event.preventDefault();
    },
    parsedDate(date) {
      const parsedDate = new Date(date);
      const time = `${parsedDate.getHours()}:${parsedDate.getMinutes()}`;
      const day = `${parsedDate.getDate()}`;
      const month = `${parsedDate.getMonth() + 1}`;
      const year = `${parsedDate.getFullYear()}`;
      return `${day}/${month}/${year} - ${time}`;
    },
    sortTable(col) {
      if (this.sorting.col == col) {
        this.sorting.ascending = !this.sorting.ascending;
      } else {
        this.sorting.col = col;
      }
    },
    sortingClass(col) {
      return {
        sorted: this.sorting.col == col,
        ascending: this.sorting.ascending,
        descending: !this.sorting.ascending,
      };
    },

    async loadDefaultProjects() {
      this.isLoading = true;
      await this.$store.dispatch('dashboard/getPersonalProjects');
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.ui.fields {
  display: flex;
  justify-content: space-between;
}
.overflow {
  max-height: 38rem;
  overflow: auto;
  scrollbar-width: thin;
}
hr {
  border: 1px solid rgba(173, 173, 173, 0.25);
}
</style>
