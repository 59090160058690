<template>
  <div>
    <TopHeader header-title="Dashboard" show-user-info></TopHeader>
    <CenterContainer>
      <ProjectList />
    </CenterContainer>
  </div>
</template>

<script>
import ProjectList from '@/components/dashboard/ProjectList';

export default {
  components: {
    ProjectList,
  },
};
</script>
