<template>
  <div class="ui compact segment">
    <h1>Nutrition Facts</h1>
    <h1>Valeur nutritive</h1>
    <h4>Per {{ numServings }} {{ servingIdentifier }} ({{ servingSize }}g)</h4>
    <h4>
      pour {{ numServings }} {{ servingIdentifierFr }} ({{ servingSize }}g)
    </h4>
    <h4 v-if="servingsPerContainer">
      Servings per container / Portions par contenant :
      {{ servingsPerContainer }}
    </h4>
    <hr />

    <div class="overflow">
      <table>
        <thead>
          <tr>
            <th width="50%"></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="getNutrient('Calories') && getNutrient('Calories').show">
            <td colspan="1" width="50%" class="calories-text">
              Calories {{ getNutrient('Calories').roundedQty }}
              <hr />
            </td>
            <td style="line-height: 1rem" colspan="2" class="dv-text">
              <b style="float: right">% Daily Value*</b>
              <br />
              <b style="float: right">% valeur quotidienne*</b>
            </td>
          </tr>
          <tr v-if="getNutrient('Fat') && getNutrient('Fat').show">
            <td colspan="2">
              <b>Fat / Lipides</b> {{ getNutrient('Fat').roundedQty }}g
            </td>
            <td colspan="1" width="15%" class="dv">
              {{ getNutrient('Fat').roundedPercentDv }}
            </td>
          </tr>
          <tr>
            <td
              v-if="
                getNutrient('Saturated Fat') &&
                getNutrient('Saturated Fat').show
              "
              colspan="2"
              class="indent"
            >
              Saturated / saturés {{ getNutrient('Saturated Fat').roundedQty }}g
              <br />
              + Trans / trans {{ getNutrient('Trans Fatty Acid').roundedQty }}g
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Saturated Fat').roundedPercentDv }}
            </td>
            <p hidden></p>
            <p hidden></p>
          </tr>
          <tr
            v-if="
              getNutrient('Omega 6 Fatty Acid') &&
              getNutrient('Omega 6 Fatty Acid').show
            "
          >
            <td colspan="2" class="indent">
              Omega-6 / oméga-6
              {{ getNutrient('Omega 6 Fatty Acid').roundedQty }}mg
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Omega 3 Fatty Acid') &&
              getNutrient('Omega 3 Fatty Acid').show
            "
          >
            <td colspan="2" class="indent">
              Omega-3 / oméga-3
              {{ getNutrient('Omega 3 Fatty Acid').roundedQty }}mg
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Carbohydrates') && getNutrient('Carbohydrates').show
            "
            class="bordered"
          >
            <td colspan="2">
              <b>Carbohydrate / Glucides </b
              >{{ getNutrient('Carbohydrates').roundedQty }}g
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Carbohydrates').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Total Dietary Fiber') &&
              getNutrient('Total Dietary Fiber').show
            "
          >
            <td colspan="2" class="indent">
              Fibre / Fibres
              {{ getNutrient('Total Dietary Fiber').roundedQty }}g
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Total Dietary Fiber').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Total Sugars') && getNutrient('Total Sugars').show
            "
          >
            <td colspan="2" class="indent">
              Sugars / Sucres {{ getNutrient('Total Sugars').roundedQty }}g
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Total Sugars').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Sugar alcohol (CA)') &&
              getNutrient('Sugar alcohol (CA)').show
            "
          >
            <td colspan="2" class="indent">
              Sugar Alcohol / Polyalcools
              {{ getNutrient('Sugar alcohol (CA)').roundedQty }}g
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Sugar alcohol (CA)').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Protein') && getNutrient('Protein').show"
            class="bordered"
          >
            <td colspan="2">
              <b>Protein / Protéines </b
              >{{ getNutrient('Protein').roundedQty }}g
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Protein').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Cholesterol') && getNutrient('Cholesterol').show"
            class="bordered"
          >
            <td colspan="2">
              <b>Cholesterol / Cholestérol </b>
              {{ getNutrient('Cholesterol').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Cholesterol').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Sodium') && getNutrient('Sodium').show"
            class="fat bordered"
          >
            <td colspan="2">
              <b>Sodium </b>{{ getNutrient('Sodium').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Sodium').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Potassium') && getNutrient('Potassium').show"
            class="bordered"
          >
            <td colspan="2">
              Potassium {{ getNutrient('Potassium').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Potassium').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Calcium') && getNutrient('Calcium').show"
            class="bordered"
          >
            <td colspan="2">
              Calcium {{ getNutrient('Calcium').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Calcium').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Iron') && getNutrient('Iron').show"
            class="bordered"
          >
            <td colspan="2">
              Iron / Fer {{ getNutrient('Iron').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Iron').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Vitamin A - RAE') &&
              getNutrient('Vitamin A - RAE').show
            "
            class="bordered"
          >
            <td colspan="2">
              Vitamin A / Vitamine A
              {{ getNutrient('Vitamin A - RAE').roundedQty }}ug
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin A - RAE').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Vitamin C') && getNutrient('Vitamin C').show"
            class="bordered"
          >
            <td colspan="2">
              Vitamin C / Vitamine C {{ getNutrient('Vitamin C').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin C').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Vitamin D - mcg') &&
              getNutrient('Vitamin D - mcg').show
            "
            class="bordered"
          >
            <td colspan="2">
              Vitamin D / Vitamine D
              {{ getNutrient('Vitamin D - mcg').roundedQty }}ug
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin D - mcg').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Vitamin E - mg') &&
              getNutrient('Vitamin E - mg').show
            "
            class="bordered"
          >
            <td colspan="2">
              Vitamin E / Vitamine E
              {{ getNutrient('Vitamin E - mg').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin E - mg').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Vitamin K') && getNutrient('Vitamin K').show"
            class="bordered"
          >
            <td colspan="2">
              Vitamin K / Vitamine K {{ getNutrient('Vitamin K').roundedQty }}μg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin K').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Vitamin B1 - Thiamin') &&
              getNutrient('Vitamin B1 - Thiamin').show
            "
            class="bordered"
          >
            <td colspan="2">
              Thiamine {{ getNutrient('Vitamin B1 - Thiamin').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin B1 - Thiamin').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Vitamin B2 - Riboflavin') &&
              getNutrient('Vitamin B2 - Riboflavin').show
            "
            class="bordered"
          >
            <td colspan="2">
              Riboflavin / Riboflavine
              {{ getNutrient('Vitamin B2 - Riboflavin').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin B2 - Riboflavin').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Vitamin B3 - Niacin Equiv') &&
              getNutrient('Vitamin B3 - Niacin Equiv').show
            "
            class="bordered"
          >
            <td colspan="2">
              Niacin / Niacine
              {{ getNutrient('Vitamin B3 - Niacin Equiv').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin B3 - Niacin Equiv').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Vitamin B6') && getNutrient('Vitamin B6').show"
            class="bordered"
          >
            <td colspan="2">
              Vitamin B6 / Vitamine B6
              {{ getNutrient('Vitamin B6').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin B6').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Folate, DFE') && getNutrient('Folate, DFE').show"
            class="bordered"
          >
            <td colspan="2">
              Folate {{ getNutrient('Folate, DFE').roundedQty }}ug DFE / ÉFA
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Folate, DFE').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Vitamin B12') && getNutrient('Vitamin B12').show"
            class="bordered"
          >
            <td colspan="2">
              Vitamin B12 / Vitamine B12
              {{ getNutrient('Vitamin B12').roundedQty }}ug
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Vitamin B12').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Biotin') && getNutrient('Biotin').show"
            class="bordered"
          >
            <td colspan="2">
              Biotin / Biotine {{ getNutrient('Biotin').roundedQty }}ug
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Biotin').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="
              getNutrient('Pantothenic Acid') &&
              getNutrient('Pantothenic Acid').show
            "
            class="bordered"
          >
            <td colspan="2">
              Pantothenate / Pantothénate
              {{ getNutrient('Pantothenic Acid').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Pantothenic Acid').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Phosphorus') && getNutrient('Phosphorus').show"
            class="bordered"
          >
            <td colspan="2">
              Phosphorus / Phosphore
              {{ getNutrient('Phosphorus').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Phosphorus').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Iodine') && getNutrient('Iodine').show"
            class="bordered"
          >
            <td colspan="2">
              Iodide / Iode {{ getNutrient('Iodine').roundedQty }}ug
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Iodine').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Magnesium') && getNutrient('Magnesium').show"
            class="bordered"
          >
            <td colspan="2">
              Magnesium / Magnésium {{ getNutrient('Magnesium').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Magnesium').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Zinc') && getNutrient('Zinc').show"
            class="bordered"
          >
            <td colspan="2">Zinc {{ getNutrient('Zinc').roundedQty }}mg</td>
            <td colspan="1" class="dv">45%</td>
          </tr>
          <tr
            v-if="getNutrient('Selenium') && getNutrient('Selenium').show"
            class="bordered"
          >
            <td colspan="2">
              Selenium / Sélénium
              {{ getNutrient('Selenium').roundedPercentDv }}ug
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Selenium').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Copper') && getNutrient('Copper').show"
            class="bordered"
          >
            <td colspan="2">
              Copper / Cuivre {{ getNutrient('Copper').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Copper').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Manganese') && getNutrient('Manganese').show"
            class="bordered"
          >
            <td colspan="2">
              Manganese / Manganèse {{ getNutrient('Manganese').roundedQty }}mg
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Manganese').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Chromium') && getNutrient('Chromium').show"
            class="bordered"
          >
            <td colspan="2">
              Chromium / Chrome {{ getNutrient('Chromium').roundedQty }}ug
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Chromium').roundedPercentDv }}
            </td>
          </tr>
          <tr
            v-if="getNutrient('Molybdenum') && getNutrient('Molybdenum').show"
            class="bordered"
          >
            <td colspan="2">
              Molybdenum / Molybdène
              {{ getNutrient('Molybdenum').roundedQty }}ug
            </td>
            <td colspan="1" class="dv">
              {{ getNutrient('Molybdenum').roundedPercentDv }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="bordered">
            <td colspan="3">
              <p>
                * 5% or less is <b>a little</b>, 15% or more is
                <b>a lot</b>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <p>
                * 5% ou moins c'est <b>peu</b>, 15% ou plus c'est
                <b>beaucoup</b>
              </p>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  computed: {
    ...mapGetters('project', ['getNutrient']),
    ...mapState('project', [
      'numServings',
      'servingSize',
      'servingIdentifier',
      'servingIdentifierFr',
      'servingsPerContainer',
    ]),
  },
};
</script>

<style scoped>
.segment {
  width: 280px;
  padding: 1rem;
}
.segment table,
th,
td {
  border-collapse: collapse;
  table-layout: fixed;
}
.segment h1 {
  font-size: 1.7rem;
  font-family: Helvetica, sans-serif;
  font-weight: 600;
  margin: 0;
}
.segment h4 {
  font-size: 0.9rem;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  margin: 0;
}
.segment tr {
  font-family: Helvetica, sans-serif;
  font-size: 0.8rem;
}
.segment tr.bordered {
  border-top: 1px solid black;
}
.segment tr.fat.bordered {
  border-bottom: 5px solid black;
}
.segment td.indent {
  padding-left: 12px;
  line-height: 1.3rem;
}
.segment .dv {
  text-align: right;
}
.segment table {
  width: 100%;
}
.segment tfoot tr {
  font-size: 6.5pt;
  margin: 0;
}
.segment .dv-text {
  font-size: 6pt;
  text-align: right;
}
.calories-text {
  font-size: 1.25rem;
  font-weight: 600;
}
.calories-text hr {
  margin: 3px 0 0;
  border: 2px solid black;
}
tr td:nth-child(2) {
  width: 10px;
}
.overflow {
  overflow: auto;
  max-height: 71vh;
  scrollbar-width: thin;
}
</style>
