import axios from 'axios';
import store from '@/store';
import { port } from '../../config';

/**
 * Wrapper and error handler for axios requests
 * @param {object} context
 * @param {object} reqOptions Axios request options
 * @returns {JSON} Response
 * @throws Request error
 */
const sendRequest = async (context, reqOptions) => {
  // Allow credentials
  reqOptions.withCredentials = true;

  try {
    // Change host in development
    if (process.env.NODE_ENV === 'development')
      reqOptions.url = `http://localhost:${port}${reqOptions.url}`;

    // Send request
    const response = await axios(reqOptions);

    // Update user data
    if (response.headers['user-data'])
      store.commit('setUserData', JSON.parse(response.headers['user-data']));

    // All 3xx codes
    if (response.status >= 300) {
      context.commit(
        'showAlert',
        {
          type: 'error',
          header: 'Error',
          message: 'Unknown error',
        },
        { root: true },
      );
      throw 'Unknown error';
    }

    return response;
  } catch (err) {
    // 400 - Bad Request
    if (err.response && err.response.status == 400) {
      context.commit(
        'showAlert',
        {
          type: 'error',
          header: 'Error',
          message:
            err.response.data && err.response.data.error
              ? err.response.data.error
              : 'Invalid Request',
        },
        { root: true },
      );
      throw err;
    }

    // 401 - Unauthorized
    if (err.response && err.response.status == 401) {
      context.dispatch('logout', null, { root: true });
      throw err;
    }

    // 403 - Forbidden
    if (err.response && err.response.status == 403) {
      context.commit(
        'showAlert',
        {
          type: 'error',
          header: 'Error',
          message:
            err.response.data && err.response.data.error
              ? err.response.data.error
              : 'You do not have sufficient priviledges to perform this action',
        },
        { root: true },
      );
      throw err;
    }

    // 404 - Not Found
    if (err.response && err.response.status == 404) {
      context.commit(
        'showAlert',
        {
          type: 'error',
          header: 'Error',
          message:
            err.response.data && err.response.data.error
              ? err.response.data.error
              : 'Internal Server Error',
        },
        { root: true },
      );
      throw err;
    }

    // 500 - Internal Server Error
    if (err.response && err.response.status == 500) {
      context.commit(
        'showAlert',
        {
          type: 'error',
          header: 'Error',
          message:
            err.response.data && err.response.data.error
              ? err.response.data.error
              : 'Internal Server Error',
        },
        { root: true },
      );
      throw err;
    }

    // xxx - All other codes
    context.commit(
      'showAlert',
      {
        type: 'error',
        header: 'Error',
        message:
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : 'Unknown error - Check your internet connection',
      },
      { root: true },
    );
    throw err;
  }
};

export default {
  sendRequest,
};
