import { createApp, defineAsyncComponent } from 'vue';
import App from './App';
import router from './router';
import store from './store';

// Global Components
import TopHeader from '@/components/ui/TopHeader';
import CenterContainer from '@/components/ui/CenterContainer';
import BarLoader from '@/components/ui/BarLoader';
import SpinLoader from '@/components/ui/SpinLoader';

// Async Global Components
const ConfirmationModal = defineAsyncComponent(() =>
  import('@/components/ui/ConfirmationModal'),
);
const BaseModal = defineAsyncComponent(() =>
  import('@/components/ui/BaseModal'),
);

createApp(App)
  .use(store)
  .use(router)
  .component('TopHeader', TopHeader)
  .component('CenterContainer', CenterContainer)
  .component('BarLoader', BarLoader)
  .component('ConfirmationModal', ConfirmationModal)
  .component('BaseModal', BaseModal)
  .component('SpinLoader', SpinLoader)
  .mount('#app');
