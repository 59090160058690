module.exports = {
  /* Base address */
  baseAddress: 'https://www.nutrispec.ca',

  /* Use HTTPS */
  useHttps: true, // Enable if valid certificate

  /* MongoDB address */
  dbAddress:
    'mongodb+srv://admin:DLnuQaD8r2svY5Bu@nutrispec-cluster.qdn8a.mongodb.net/nutrispecDB?retryWrites=true&w=majority',

  /* SendGrid Key */
  sgKey:
    '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',

  /* Singup token TTL */
  signupTokenExpiry: 24 * 60 * 60 * 1000, // 24 hours

  /* Sesion TTL */
  sessionExpiry: 4 * 60 * 60 * 1000, // 4 hours

  /* Rate limiter */
  requestsPerMinute: 700,

  /* Login attempts */
  loginAttempts: {
    maxGuesses: 5,
    timeout: 5 * 60 * 1000, // 5 minutes
  },

  /**
   * Set NODE_ENV (development|production)
   * Will set only if environment variable is undefined
   */
  nodeEnv: 'development',

  /**
   * Port number
   * Will use this port number if process.env.PORT is undefined
   */
  port: 4000,
};
