export default {
  /**
   * Open modal
   */
  openModal: (state, payload) => {
    state.openModal = payload.name;
    state.activeNutrient = payload.nutrientName ? payload.nutrientName : null;
    state.activeMealReplacementItem = payload.activeMealReplacementItem
      ? payload.activeMealReplacementItem
      : null;
  },

  /**
   * Close modal
   */
  closeModal: state => {
    state.openModal = null;
    state.activeNutrient = null;
    state.activeMealReplacementItem = null;
  },

  /**
   * Reset state to defaults
   */
  reset: state => {
    state.selectedRegion = 'ca';
    state.regionItems = null;
    state.mealReplacement = null;
    state.openModal = '';
    state.activeNutrient = null;
    state.activeMealReplacementItem = null;
    state.isLoadingRegion = false;
  },
};
