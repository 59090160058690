<template>
  <ConfirmationModal
    :show="show"
    :confirm-loading="isLoading"
    @deny="closeModal"
    @confirm="deleteUser"
  >
    <template #header>Delete User</template>
    <template #default>
      <h3>
        Are you sure you want to delete
        <b>{{ user.fullName }} and <u>ALL THEIR ASSOCIATED PROJECTS</u></b> ?
      </h3>
    </template>
    <template #denyButton>No</template>
    <template #confirm-button>Yes</template>
  </ConfirmationModal>
</template>

<script>
import ConfirmationModal from '@/components/ui/ConfirmationModal';
import { mapMutations } from 'vuex';

export default {
  components: {
    ConfirmationModal,
  },
  props: {
    show: Boolean,
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations('admin', ['closeModal']),
    async deleteUser() {
      this.isLoading = true;
      await this.$store.dispatch('admin/deleteUser', { _id: this.user._id });
      this.$store.commit('admin/closeModal');
      this.isLoading = false;
    },
  },
};
</script>
